import OrderDetailsPage from 'pages/breeding/OrderDetails';
import OrderListPage from 'pages/breeding/OrderList';
import PicklistPage from 'pages/breeding/Picklist';
import BreedingSettingsPage from 'pages/breeding/Settings';
import ConfirmPasswordReset from 'pages/ConfirmPasswordReset';
import HorsePedigree from 'pages/horses/HorsePedigree';
import PublicIntegrationResult from 'pages/PublicIntegrationResult';
import WebshopMaresList from 'pages/webshop/MaresList';
import WebshopOrderListPage from 'pages/webshop/OrderList';
import React, { ReactNode } from 'react';
import { generatePath } from 'react-router-dom';
import {
  ActivitiesSettings,
  ContactDetails,
  ContactList,
  Home,
  HorseList,
  HorseLocationHistory,
  HorseSettings,
  IntegrationsPage,
  Invoice,
  InvoicesList,
  MountListPage,
  UiShowcase,
  VatPercentagesList,
} from './pages';
import Benchmark from './pages/Benchmark';
import ForgotPassword from './pages/ForgotPassword';
import HorseDetailsPage from './pages/horses/Details';
import Login, { LoginType } from './pages/Login';
import ProfilePage from './pages/profile/Profile';
import PublicInvoice from './pages/PublicInvoice';
import PublicInvoicePrint from './pages/PublicInvoicePrint';
import Signup from './pages/Signup';
import { HorseDetailProvider } from 'context/HorseDetailContext';
import VerifyEmail from 'pages/VerifyEmail';
import WebshopOrderDetailsPage from 'pages/webshop/OrderDetails';
import MareDetails from 'pages/webshop/MareDetails';
import StallionMountDetailsPage from 'pages/breeding/StallionMountDetails';
import OrganizationSettings from 'pages/admin/Settings';
import FinancialSetting from 'pages/Financial/Settings';
import PaymentsListPage from 'pages/Financial/PaymentsList';
import LocationDetailPage from 'pages/admin/LocationDetail';
import Users from 'pages/users';
import ConfirmInvitation from 'pages/ConfirmInvitation';
import UserDetailsPage from 'pages/users/Details';
import MicrosoftOidcLoginResponse from 'pages/MicrosoftOidcLoginResponse';
import ManageLocations from 'pages/admin/ManageLocations';
import RvoList from 'pages/horses/RvoList';
import PermissionsPage from 'pages/admin/Permissions';
import HorsePlanning from 'pages/planning/HorsePlanning';
import StaffPlanning from 'pages/planning/StaffPlanning';
import MyPlanning from 'pages/planning/MyPlanning';
import StablePlanning from 'pages/planning/StablePlanning';
import StallionMountInventoryDetailsPage from 'pages/breeding/StallionMountInventoryDetails';
import StallionMountInventoryPage from 'pages/breeding/StallionMountInventory';
import CarePlanning from 'pages/planning/CarePlanning';
import PregnancyCheckPage from 'pages/breeding/PregnancyCheckList';
import PregnancyCheckDetailsPage from 'pages/breeding/PregnancyCheckDetails';

interface RouteConfigInterface {
  path: string;
  name: string;
  component: ReactNode | undefined;
  public?: boolean;
}

export enum AppRouteKeys {
  Home = 'Home',
  Profile = 'Profile',
  Signup = 'Signup',
  Login = 'Login',
  MicrosoftOidcLoginResponse = 'MicrosoftOidcLoginResponse',
  ForgotPassword = 'ForgotPassword',
  Benchmark = 'Benchmark',
  HorsePlanning = 'HorsePlanning',
  StaffPlanning = 'StaffPlanning',
  MyPlanning = 'MyPlanning',
  StablePlanning = 'StablePlanning',
  CarePlanning = 'CarePlanning',
  ActivitiesSettings = 'ActivitiesSettings',
  HorsesList = 'HorsesList',
  HorsesDetails = 'HorsesDetails',
  HorsesPedigree = 'HorsesPedigree',
  HorsesLocationHistory = 'HorsesLocationHistory',
  HorsesRvoList = 'HorsesRvoList',
  HorsesSettings = 'HorsesSettings',
  UiShowcase = 'UiShowcase',
  ContactList = 'ContactList',
  ContactDetails = 'ContactDetails',
  InvoicesList = 'InvoicesList',
  Invoice = 'Invoice',
  PublicInvoice = 'PublicInvoice',
  PublicInvoicePrint = 'PublicInvoicePrint',
  MountList = 'MountList',
  MountDetails = 'MountDetails',
  BreedingInventoryList = 'BreedingInventoryList',
  BreedingInventoryDetails = 'BreedingInventoryDetails',
  BreedingPregnancyCheckList = 'BreedingPregnancyCheckList',
  BreedingPregnancyCheckDetails = 'BreedingPregnancyCheckDetails',
  SemenOrderList = 'SemenOrderList',
  SemenOrderDetails = 'SemenOrderDetails',
  SemenPicklist = 'SemenPicklist',
  BreedingSettings = 'BreedingSettings',
  ConfirmPasswordReset = 'ConfirmPasswordReset',
  VatPercentages = 'VatPercentages',
  VerifyEmail = 'VerifyEmail',
  Integrations = 'Integrations',
  Permissions = 'Permissions',
  OrganizationSettings = 'OrganizationSettings',
  AdminUsersList = 'AdminUsersList',
  AdminUserDetails = 'AdminUserDetails',
  PublicIntegrationSuccess = 'PublicIntegrationSuccess',
  FinancialSettings = 'FinancialSettings',
  PaymentsList = 'PaymentsList',
  ConfirmInvitation = 'ConfirmInvitation',
  ManageLocations = 'ManageLocations',
  LocationDetail = 'LocationDetail',
}

export enum WebshopRouteKeys {
  Signup = 'Signup',
  Login = 'Login',
  ForgotPassword = 'ForgotPassword',
  ConfirmPasswordReset = 'ConfirmPasswordReset',
  Profile = 'Profile',
  OrderList = 'OrderList',
  OrderDetails = 'OrderDetails',
  MaresList = 'MaresList',
  MareDetails = 'MareDetails',
  MarePedigree = 'MarePedigree',
}

type AppRoutesInterface = {
  [key in AppRouteKeys]: RouteConfigInterface;
};

type WebshopRoutesInterface = {
  [key in WebshopRouteKeys]: RouteConfigInterface;
};

export const AppRoutes: AppRoutesInterface = {
  Home: {
    path: '/',
    name: 'Home',
    component: <Home />,
    public: false,
  },
  Signup: {
    path: '/auth/signup',
    name: 'Signup',
    component: <Signup loginType={LoginType.Equinem} />,
    public: true,
  },
  Login: {
    path: '/auth/login',
    name: 'Login',
    component: <Login loginType={LoginType.Equinem} />,
    public: true,
  },
  MicrosoftOidcLoginResponse: {
    path: '/auth/microsoft/response-oidc',
    name: 'MicrosoftOidcLoginResponse',
    component: <MicrosoftOidcLoginResponse />,
    public: true,
  },
  ForgotPassword: {
    path: '/auth/forgot-password',
    name: 'Forgot Password',
    component: <ForgotPassword loginType={LoginType.Equinem} />,
    public: true,
  },
  ConfirmPasswordReset: {
    path: '/confirm-password-reset',
    name: 'Confirm password reset',
    component: <ConfirmPasswordReset />,
    public: true,
  },
  VerifyEmail: {
    path: '/verify-email',
    name: 'Verify email',
    component: <VerifyEmail />,
    public: true,
  },
  ConfirmInvitation: {
    path: '/confirm-invitation',
    name: 'confirm-invitation',
    component: <ConfirmInvitation />,
    public: true,
  },
  Profile: {
    path: '/profile',
    name: 'Profile',
    component: <ProfilePage />,
    public: false,
  },
  Benchmark: {
    path: '/benchmark',
    name: 'Benchmark',
    component: <Benchmark />,
    public: true,
  },
  HorsePlanning: {
    path: '/planning/horses',
    name: 'Horse planning',
    component: <HorsePlanning />,
    public: false,
  },
  StaffPlanning: {
    path: '/planning/staff',
    name: 'Staff planning',
    component: <StaffPlanning />,
    public: false,
  },
  MyPlanning: {
    path: '/planning/my',
    name: 'My planning',
    component: <MyPlanning />,
    public: false,
  },
  StablePlanning: {
    path: '/planning/stable',
    name: 'Stable planning',
    component: <StablePlanning />,
    public: false,
  },
  CarePlanning: {
    path: '/planning/care',
    name: 'Care planning',
    component: <CarePlanning />,
    public: false,
  },
  ActivitiesSettings: {
    path: '/planning/settings',
    name: 'Settings',
    component: <ActivitiesSettings />,
    public: false,
  },
  HorsesList: {
    path: '/horses/list',
    name: 'Horse list',
    component: <HorseList />,
    public: false,
  },
  HorsesDetails: {
    path: '/horses/:uid',
    name: 'Horse details',
    component: (
      <HorseDetailProvider>
        <HorseDetailsPage />
      </HorseDetailProvider>
    ),
    public: false,
  },
  HorsesPedigree: {
    path: '/horses/:uid/pedigree',
    name: 'Horse pedigree',
    component: <HorsePedigree loginType={LoginType.Equinem} />,
    public: false,
  },
  HorsesLocationHistory: {
    path: '/horses/location-history',
    name: 'Horse location history',
    component: <HorseLocationHistory />,
    public: false,
  },
  HorsesRvoList: {
    path: '/horses/location-history/rvo',
    name: 'RVO report list',
    component: <RvoList />,
    public: false,
  },
  HorsesSettings: {
    path: '/horses/settings',
    name: 'Horse settings',
    component: <HorseSettings />,
    public: false,
  },
  UiShowcase: {
    path: '/ui/showcase',
    name: 'UI showcase',
    component: <UiShowcase />,
    public: true,
  },
  ContactList: {
    path: '/contacts/list',
    name: 'Contacts list',
    component: <ContactList />,
    public: false,
  },
  ContactDetails: {
    path: '/contacts/:uid',
    name: 'Contact details',
    component: <ContactDetails />,
    public: false,
  },
  InvoicesList: {
    path: '/financial-administration/invoices',
    name: 'Invoices',
    component: <InvoicesList />,
    public: false,
  },
  PaymentsList: {
    path: '/financial-administration/payments',
    name: 'Payments',
    component: <PaymentsListPage />,
    public: false,
  },
  Invoice: {
    path: '/financial-administration/invoices/:invoiceId',
    name: 'Invoice',
    component: <Invoice />,
    public: false,
  },
  VatPercentages: {
    path: '/financial-administration/vat',
    name: 'Vat',
    component: <VatPercentagesList />,
    public: false,
  },
  FinancialSettings: {
    path: '/financial-administration/settings',
    name: 'Settings',
    component: <FinancialSetting />,
    public: false,
  },
  PublicInvoice: {
    path: '/p/invoice/:publicAccessUuid',
    name: 'Invoice',
    component: <PublicInvoice />,
    public: true,
  },
  PublicInvoicePrint: {
    path: '/p/invoice/:publicAccessUuid/print',
    name: 'Invoice',
    component: <PublicInvoicePrint />,
    public: true,
  },
  MountList: {
    path: '/breeding/stallion-mounts',
    name: 'Mounts',
    component: <MountListPage />,
    public: false,
  },
  MountDetails: {
    path: '/breeding/stallion-mounts/:uid',
    name: 'Mount details',
    component: <StallionMountDetailsPage />,
    public: false,
  },
  BreedingInventoryList: {
    path: '/breeding/inventory',
    name: 'Inventory',
    component: <StallionMountInventoryPage />,
    public: false,
  },
  BreedingInventoryDetails: {
    path: '/breeding/inventory/:uid',
    name: 'Inventory details',
    component: <StallionMountInventoryDetailsPage />,
    public: false,
  },
  BreedingPregnancyCheckList: {
    path: '/breeding/pregnancy-checks',
    name: 'Pregnancy checks',
    component: <PregnancyCheckPage />,
    public: false,
  },
  BreedingPregnancyCheckDetails: {
    path: '/breeding/pregnancy-checks/:uid',
    name: 'Pregnancy check',
    component: <PregnancyCheckDetailsPage />,
    public: false,
  },
  SemenOrderList: {
    path: '/shop/orders',
    name: 'Semen orders',
    component: <OrderListPage />,
    public: false,
  },
  SemenOrderDetails: {
    path: '/shop/orders/:uid',
    name: 'Semen order details',
    component: <OrderDetailsPage />,
    public: false,
  },
  SemenPicklist: {
    path: '/shop/picklist',
    name: 'Semen picklist',
    component: <PicklistPage />,
    public: false,
  },
  BreedingSettings: {
    path: '/shop/settings',
    name: 'Settings',
    component: <BreedingSettingsPage />,
    public: false,
  },
  OrganizationSettings: {
    path: '/admin/settings',
    name: 'Settings',
    component: <OrganizationSettings />,
    public: false,
  },
  Integrations: {
    path: '/admin/integrations',
    name: 'Integrations',
    component: <IntegrationsPage />,
    public: false,
  },
  Permissions: {
    path: '/admin/permissions',
    name: 'Permissions',
    component: <PermissionsPage />,
    public: false,
  },
  AdminUsersList: {
    path: '/admin/users',
    name: 'Users',
    component: <Users />,
    public: false,
  },
  AdminUserDetails: {
    path: '/admin/users/:uid',
    name: 'Users details',
    component: <UserDetailsPage />,
    public: false,
  },
  ManageLocations: {
    path: '/admin/locations',
    name: 'Manage locations',
    component: <ManageLocations />,
    public: false,
  },
  LocationDetail: {
    path: '/admin/locations/:contactUid',
    name: 'Stable detail',
    component: <LocationDetailPage />,
    public: false,
  },
  PublicIntegrationSuccess: {
    path: '/p/oauth2-callback-result',
    name: 'PublicIntegrationResult',
    component: <PublicIntegrationResult />,
    public: true,
  },
};

// The top level route of all webshop sub routes.
export const WebshopRoute = '/shop/:publicAccessUuid';

// A helper class to generate a full path for a webshop url.
export const generateWebshopPath = (path: string, publicAccessUuid: string): string => {
  return generatePath(`${WebshopRoute}/${path}`, { publicAccessUuid });
};

export const WebshopRoutes: WebshopRoutesInterface = {
  Signup: {
    path: 'auth/signup',
    name: 'Signup',
    component: <Signup loginType={LoginType.Webshop} />,
    public: true,
  },
  Login: {
    path: 'auth/login',
    name: 'Login',
    component: <Login loginType={LoginType.Webshop} />,
    public: true,
  },
  ForgotPassword: {
    path: 'auth/forgot-password',
    name: 'Forgot Password',
    component: <ForgotPassword loginType={LoginType.Webshop} />,
    public: true,
  },
  ConfirmPasswordReset: {
    path: 'confirm-password-reset',
    name: 'Confirm password reset',
    component: <ConfirmPasswordReset />,
    public: true,
  },
  OrderList: {
    path: 'orders',
    name: 'Orders',
    component: <WebshopOrderListPage />,
    public: false,
  },
  OrderDetails: {
    path: 'orders/:uid',
    name: 'Order details',
    component: <WebshopOrderDetailsPage />,
    public: false,
  },
  MaresList: {
    path: 'mares',
    name: 'Mares',
    component: <WebshopMaresList />,
    public: false,
  },
  MareDetails: {
    path: 'mares/:uid',
    name: 'Mare Details',
    component: (
      <HorseDetailProvider>
        <MareDetails />
      </HorseDetailProvider>
    ),
    public: false,
  },
  MarePedigree: {
    path: 'mares/:uid/pedigree',
    name: 'Mare pedigree',
    component: <HorsePedigree loginType={LoginType.Webshop} />,
    public: false,
  },
  Profile: {
    path: 'profile',
    name: 'Profile',
    component: <ProfilePage />,
    public: false,
  },
};
