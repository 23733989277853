import { ArrowSquareOut, DotsThreeVertical, Horse as HorseIcon, Plus, Truck } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import useApiPromises from 'api/hooks/useApiPromises';
import classNames from 'classnames';
import { useAccount } from 'context/AccountContext';
import { useConfig } from 'context/ConfigContext';
import { useOrganization } from 'context/OrganizationContext';
import { CategoriesService, Category, Horse, HorsesService, Product, ProductsService, SexEnum, ShippingServiceTypeEnum } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from 'ui/Badge';
import { ButtonVariant } from 'ui/Button';
import { table, tableTbody, tableTbodyTrNoClick } from 'ui/Const';
import DropdownMenu from 'ui/DropdownMenu';
import { Tile } from 'ui/Layout/Tile';
import { ApiPromises } from 'utilities/ApiPromises';
import { AllColors } from 'utilities/colors';
import SaveProductModal from '../SaveProductModal';
import { ActionModal } from 'ui/Modals';
import { ErrorSection } from 'ui/Error';
import { TFunction } from 'i18next';
import { getBreedingProducts } from '../Helpers';

/**
 * The status of a stallion in the catalogue. This basically tells us that the
 * stallion has an active breeding product or not.
 */
const enum CatalogueStatus {
  Inactive,
  Active,
  Future,
  ActiveAndFuture,
}

const catalogueStatusForProduct = (product: Product): CatalogueStatus => {
  if (product.current_price) {
    if (product.future_price) {
      return CatalogueStatus.ActiveAndFuture;
    } else {
      return CatalogueStatus.Active;
    }
  } else {
    if (product.future_price) {
      return CatalogueStatus.Future;
    } else {
      return CatalogueStatus.Inactive;
    }
  }
};
interface BreedingPriceModel {
  productCategory: Category;
  edit?: Product;
  modalOpen: boolean;
}

const shippingServiceTypeName = (t: TFunction, type?: ShippingServiceTypeEnum): string => {
  if (!type) {
    return '';
  }
  switch (type) {
    case ShippingServiceTypeEnum.REGULAR:
      return t('shipping-type-regular', 'Regular');
    case ShippingServiceTypeEnum.PICK_UP:
      return t('shipping-type-pick-up', 'Pick up');
    case ShippingServiceTypeEnum.SAME_DAY_DELIVERY:
      return t('shipping-type-same-day-delivery', 'Same day delivery');
    case ShippingServiceTypeEnum.NEXT_DAY_DELIVERY:
      return t('shipping-type-next-day-delivery', 'Next day delivery');
    case ShippingServiceTypeEnum.SUNDAY_HOLIDAY_DELIVERY:
      return t('shipping-type-sunday-holiday-delivery', 'Sunday and holiday delivery');
  }
};

function StallionAndShippingProductsBreedingTile(): JSX.Element {
  const [priceModalConf, setPriceModalConf] = useState<BreedingPriceModel | undefined>();
  const [horses, setHorses] = useState<Horse[]>();
  const [products, setProducts] = useState<Product[]>();
  const [financialCategories, setFinancialCategories] = useState<Category[]>();
  const [showRemoveProductDialog, setShowRemoveProductDialog] = useState<Product | undefined>();
  const [removeProductDialogError, setRemoveProductDialogError] = useState<ApiErrorParser<void> | undefined>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  const { loading } = useApiPromises({ apiPromises });
  const { formatMoney } = useAccount();
  const { selectedOrganizationUid, selectedOrganizationDetails, generateCacheKey, selectedOrganization } = useOrganization();
  const { config } = useConfig();
  const { t } = useTranslation();

  const formatPrice = useCallback(
    (product: Product): string => {
      const amount = Number(product.current_price);
      if (isNaN(amount) || !product.current_price_currency) {
        return '-';
      }
      return formatMoney(amount, product.current_price_currency);
    },
    [formatMoney],
  );

  const myStallions = useMemo((): Horse[] => {
    if (!horses) return [];
    return horses.filter(horse => horse.sex === SexEnum._1);
  }, [horses]);

  const breedingProductCategory = useMemo((): Category | undefined => {
    return financialCategories?.find(cat => cat.default === 'BREEDING');
  }, [financialCategories]);

  const shippingProductCategory = useMemo((): Category | undefined => {
    return financialCategories?.find(cat => cat.default === 'SHIPPING');
  }, [financialCategories]);

  const breedingProducts = useMemo(() => {
    return getBreedingProducts(financialCategories, horses, products);
  }, [financialCategories, horses, products]);

  const shippingProducts = useMemo((): Product[] | undefined => {
    if (!products) return undefined;
    if (!shippingProductCategory) {
      console.error('Shipping product category not found');
      return [];
    }
    return products.filter(product => product.category === shippingProductCategory.uid && product.hidden === false);
  }, [products, shippingProductCategory]);

  const webshopUrl = useMemo((): string => {
    if (!selectedOrganizationDetails) {
      return '';
    }
    return config?.getWebshopUrl(selectedOrganizationDetails.public_access_uuid) ?? '';
  }, [selectedOrganizationDetails, config]);

  const myStallionsWithoutProduct = useMemo((): Horse[] => {
    return myStallions.filter(stallion => breedingProducts.findIndex(prod => prod.stallion === stallion.uid) === -1);
  }, [myStallions, breedingProducts]);

  const catalogueStatusToText = useCallback(
    (status: CatalogueStatus) => {
      // status.
      switch (status) {
        case CatalogueStatus.Inactive:
          return t('status-inactive', 'Inactive');
        case CatalogueStatus.Active:
        case CatalogueStatus.ActiveAndFuture:
          return t('status-active', 'Active');
        case CatalogueStatus.Future:
          return t('status-future', 'Not yet active');
        default:
          console.error('Unknown catalogue status to string');
          return status;
      }
    },
    [t],
  );

  /**
   * Load data from the api/cache
   */
  const loadApiData = useCallback(
    (options: { loadProducts: boolean } | undefined): ApiPromises => {
      const promises = new ApiPromises();

      if (!selectedOrganizationUid) {
        return promises;
      }

      if (!options || options.loadProducts) {
        promises.appendList<Product>(
          'products',
          () =>
            ProductsService.productsList({
              organisationUid: selectedOrganizationUid,
            }),
          setProducts,
        );
      }

      if (!options) {
        promises.appendList<Category>(
          'financialCategories',
          () =>
            CategoriesService.categoriesList({
              organisationUid: selectedOrganizationUid,
              o: 'p_s_type,name',
            }),
          setFinancialCategories,
        );

        promises.appendList<Horse>(
          'webshop-horses',
          () => {
            return HorsesService.horsesList({
              organisationUid: selectedOrganizationUid ?? '',
              onUnknownLocation: false,
            });
          },
          setHorses,
          generateCacheKey('webshop-horses'),
        );
      }

      setApiPromises(promises);

      return promises;
    },
    [generateCacheKey, selectedOrganizationUid],
  );

  /**
   * Load from the api
   */
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData(undefined);
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  const onAddedProduct = useCallback(
    (product: Product) => {
      if (products) {
        setProducts([...products, product]);
      } else {
        setProducts([product]);
      }
    },
    [products],
  );

  return (
    <>
      <Tile
        loading={loading}
        title={t('breeding-stallions', 'Breeding stallions')}
        actions={[
          {
            onClick: () => {
              if (!breedingProductCategory) {
                console.error('Breeding product category not found');
                return;
              }
              setPriceModalConf({ productCategory: breedingProductCategory, edit: undefined, modalOpen: true });
            },
            buttonVariant: ButtonVariant.Default,
            icon: <Plus />,
            text: t('add-stallion', 'Add stallion'),
          },
        ]}
      >
        <p>
          {t('breeding-price-desc-1', 'Active stallions in this list are displayed to your customers through the private')}{' '}
          <a className='text-blue-500 inline-flex items-center gap-1 mt-1' href={webshopUrl} rel='noreferrer' target='_blank'>
            {t('webshop', 'Webshop')} <ArrowSquareOut />
          </a>
          {` ${t('the-webshop-of', 'of')} ${selectedOrganizationDetails?.name ?? '---'}.`}
        </p>
        <table className={table}>
          <tbody className={tableTbody}>
            {(breedingProducts ?? []).map(product => {
              const stallion = myStallions.find(horse => horse.uid === product.stallion);
              const status = catalogueStatusForProduct(product);
              return (
                <tr className={tableTbodyTrNoClick} key={product.uid}>
                  <td className='text-center w-10'>
                    <HorseIcon size={22} weight='light' className='inline' />
                  </td>
                  <td>
                    {stallion?.name}{' '}
                    <Badge
                      color={
                        status === CatalogueStatus.Active || status === CatalogueStatus.ActiveAndFuture
                          ? AllColors.Green
                          : status === CatalogueStatus.Future
                            ? AllColors.Yellow
                            : AllColors.Red
                      }
                    >
                      {catalogueStatusToText(status)}
                    </Badge>
                  </td>
                  <td className={classNames({ 'line-through text-gray-500': status === CatalogueStatus.Inactive })}>
                    {formatPrice(product)}
                  </td>
                  <td className='w-14 pr-2'>
                    <DropdownMenu
                      menuPlacement='bottom-end'
                      menuItems={[
                        [
                          {
                            element: t('edit', 'Edit'),
                            onClick: () => {
                              if (!breedingProductCategory) {
                                console.error('Breeding product category not found');
                                return;
                              }
                              setPriceModalConf({ productCategory: breedingProductCategory, edit: product, modalOpen: true });
                            },
                          },
                          {
                            element: t('remove', 'Remove'),
                            onClick: () => setShowRemoveProductDialog(product),
                            className: 'text-red-600',
                          },
                        ],
                      ]}
                    >
                      <div className='w-full h-full text-center cursor-pointer pt-2 pb-1'>
                        <DotsThreeVertical size={24} weight='bold' className='inline' />
                      </div>
                    </DropdownMenu>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Tile>

      <Tile
        loading={loading}
        title={t('shipping', 'Shipping')}
        actions={[
          {
            onClick: () => {
              if (!shippingProductCategory) {
                console.error('Shipping product category not found');
                return;
              }
              setPriceModalConf({ productCategory: shippingProductCategory, edit: undefined, modalOpen: true });
            },
            buttonVariant: ButtonVariant.Default,
            icon: <Plus />,
            text: t('add', 'Add'),
          },
        ]}
      >
        <p>{t('shipping-info', 'Please configure here what kind of delivery (or pickup) services you offer.')}</p>
        <table className={table}>
          <tbody className={tableTbody}>
            {(shippingProducts ?? []).map(product => (
              <tr className={tableTbodyTrNoClick} key={product.uid}>
                <td className='text-center w-10'>
                  <Truck size={22} weight='light' className='inline' />
                </td>
                <td>{product.shipping_provider_name}</td>
                <td>{shippingServiceTypeName(t, product.shipping_service_type)}</td>
                <td>{formatPrice(product)}</td>
                <td className='w-14 pr-2'>
                  <DropdownMenu
                    menuPlacement='bottom-end'
                    menuItems={[
                      [
                        {
                          element: t('edit', 'Edit'),
                          onClick: () => {
                            if (!shippingProductCategory) {
                              console.error('Shipping product category not found');
                              return;
                            }
                            setPriceModalConf({ productCategory: shippingProductCategory, edit: product, modalOpen: true });
                          },
                        },
                        { element: t('remove', 'Remove'), onClick: () => setShowRemoveProductDialog(product), className: 'text-red-600' },
                      ],
                    ]}
                  >
                    <div className='w-full h-full text-center cursor-pointer pt-2 pb-1'>
                      <DotsThreeVertical size={24} weight='bold' className='inline' />
                    </div>
                  </DropdownMenu>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Tile>

      <SaveProductModal
        onSaved={onAddedProduct}
        stallions={myStallionsWithoutProduct}
        visible={priceModalConf?.modalOpen === true}
        category={priceModalConf?.productCategory}
        existingProduct={priceModalConf?.edit}
        closeModal={() => {
          if (!breedingProductCategory) {
            console.error('Breeding product category not found');
            return;
          }
          setPriceModalConf({ productCategory: breedingProductCategory, modalOpen: false, edit: priceModalConf?.edit });
          loadApiData({ loadProducts: true });
        }}
      />

      <ActionModal
        open={showRemoveProductDialog !== undefined}
        actions={[
          {
            text: t('cancel', 'Cancel'),
            variant: ButtonVariant.Default,
            onClick: () => setShowRemoveProductDialog(undefined),
          },
          {
            text: t('remove', 'Remove'),
            variant: ButtonVariant.PrimaryDanger,
            onClick: async () => {
              const promise = ProductsService.productsDestroy({
                organisationUid: selectedOrganizationUid ?? '',
                uid: showRemoveProductDialog?.uid ?? '',
              });

              try {
                await promise;

                // reload the products so it reflects the changes
                loadApiData({ loadProducts: true });
                setShowRemoveProductDialog(undefined);
              } catch (e) {
                setRemoveProductDialogError(new ApiErrorParser(e));
              }
            },
          },
        ]}
        title={t('remove-product-confirm-title', 'Remove product')}
      >
        <>
          <ErrorSection errors={removeProductDialogError} />
          <p>{t('remove-product-confirm-text', 'Are you sure you want to remove this product?')}</p>
        </>
      </ActionModal>
    </>
  );
}

export default StallionAndShippingProductsBreedingTile;
