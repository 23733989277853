import { TFunction, t } from 'i18next';
import { CategoryEnum, Horse, HorseDetail, LocationEnum, Organisation, PregnancyCheckTermEnum, SoftnessEnum, UterusEnum } from 'openapi';
import { SexEnum } from 'openapi/models/SexEnum';
import { AppliedListFilter } from '../components/Common/ListFilter';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';

interface GenderItem {
  label: string;
  value: SexEnum;
}

export interface FilterHorse {
  horse: Horse;

  // Set to true if the textual search result is found outside of the filter.
  // I.e. we can search for inactive horses even though the filter hides them.
  foundOutsideFilter: boolean;
}

export const genderList = (t: TFunction): GenderItem[] => {
  return [
    {
      label: t('stallion', 'Stallion'),
      value: SexEnum._1,
    },
    {
      label: t('mare', 'Mare'),
      value: SexEnum._2,
    },
    {
      label: t('gelding', 'Gelding'),
      value: SexEnum._3,
    },
  ];
};

export const gender = (genderId: SexEnum, t: TFunction): string => {
  const genders = genderList(t);
  const gender = genders.find(g => g.value === genderId);
  return gender ? gender.label : t('gender-unknown', 'Unknown');
};

// Calculate the age of the horse. It is that the calculation only should take
// into account the YEAR of birth. The day and month are irrelevant.
//
// Horses age calculations are a bit weird as ALL horses age at 1-1 of the year.
// (we don't take the day and month into consideration). Except on the lower
// half of the continent, there all horses age at 1-6 (I believe), but we are
// not taking into account this exception for now.
export const age = (horse: Horse | HorseDetail): number | undefined => {
  if (!horse.date_of_birth) {
    return undefined;
  }
  const today = new Date();
  const birthDate = new Date(Date.parse(horse.date_of_birth));
  return today.getFullYear() - birthDate.getFullYear();
};

// Filter by Applied List Filters
export const listFilter = (horses: Horse[], appliedListFilter: AppliedListFilter[]): Horse[] => {
  const gender = (horse: Horse): string => {
    switch (horse.sex) {
      case 1:
        return 'stallion';
      case 2:
        return 'mare';
      case 3:
        return 'gelding';
      default:
        return 'unknown';
    }
  };

  const birthYear = (horse: Horse): string => {
    if (horse.date_of_birth) {
      const date = new Date(Date.parse(horse.date_of_birth));
      return date.getFullYear().toString();
    } else {
      return 'unknown';
    }
  };

  const showInactive = appliedListFilter.find(alf => alf.type.id === 'passive' && alf.options.find(o => o.id === 'inactive')) !== undefined;
  const showNoStable =
    appliedListFilter.find(alf => alf.type.id === 'passive' && alf.options.find(o => o.id === 'no-stable')) !== undefined;

  return horses.filter(horse => {
    const by = birthYear(horse);
    const horseAge = age(horse)?.toString() ?? 'unknown';
    for (const alf of appliedListFilter) {
      if (alf.type.id === 'gender') {
        if (
          !alf.options.find(o => {
            return o.id === gender(horse);
          })
        ) {
          return false;
        }
      }

      if (alf.type.id === 'birthyear') {
        if (
          !alf.options.find(o => {
            return o.id === by;
          })
        ) {
          return false;
        }
      }

      if (alf.type.id === 'age') {
        if (
          !alf.options.find(o => {
            return o.id === horseAge;
          })
        ) {
          return false;
        }
      }

      // filter on the owner of the horse
      if (alf.type.id === 'owned') {
        if (
          !alf.options.find(o => {
            return horse.owner_uids.includes(o.id);
          })
        ) {
          return false;
        }
      }

      if (alf.type.id === 'stable') {
        const found = alf.options.find(o => o.id === horse.stable_uid);
        const noStable = !horse.stable_uid && showNoStable;
        if (!found && !noStable) {
          return false;
        }
      }

      if (alf.type.id === 'location') {
        const found = alf.options.find(o => o.id === horse.current_location.uid);
        if (!found) {
          return false;
        }
      }

      if (alf.type.id === 'group') {
        const found = alf.options.find(o => {
          return o.id === horse.group_uid;
        });
        if (!found) {
          return false;
        }
      }

      if (alf.type.id === 'category') {
        const found = alf.options.find(o => {
          switch (o.id) {
            case CategoryEnum.CARE:
              return horse.use_in_care;
            case CategoryEnum.BREEDING:
              return horse.use_in_breeding;
            case CategoryEnum.SPORT:
              return horse.use_in_sport;
          }
        });
        if (!found) {
          return false;
        }
      }
    }

    if (!showInactive && horse.hidden) {
      return false;
    }

    if (!showNoStable && !horse.stable_uid) {
      return false;
    }

    return true;
  });
};

// Filter by Name, UELN or Chipnr
export const textFilter = (horses: Horse[], textFilter: string): Horse[] => {
  if (!textFilter || textFilter === '') {
    return horses;
  }
  return horses.filter(horse => {
    if (textFilter) {
      const search = textFilter.toLowerCase();
      if (horse.name && horse.name.toLowerCase().includes(search)) {
        return true;
      }
      if (horse.UELN && horse.UELN.toLowerCase().includes(search)) {
        return true;
      }
      if (horse.chip_nr && horse.chip_nr.toLowerCase().includes(search)) {
        return true;
      }
    }
    return false;
  });
};

/**
 * Build a list for the departure reasons when moving a horse
 * Based on the enum DepartureReasonEnum
 *
 * There is a exception for the "Bereden Politie - organisation.is_bp", we do not allow the "other" option
 */
export const getHorseDepartueReasonsList = (organisation: Organisation | undefined): OptionItemInterface[] => {
  const options = [
    {
      id: 1,
      name: t('sold-to-dealer', 'Sold to dealer'),
    },
    {
      id: 2,
      name: t('sold-to-private-individual', 'Sold to private individual'),
    },
    {
      id: 3,
      name: t('retirement', 'Retirement'),
    },
    {
      id: 4,
      name: t('euthanasia', 'Euthanasia'),
    },
    {
      id: 5,
      name: t('show', 'Show'),
    },
  ];

  // for Bereden Politie we do not allow the other options
  if (!organisation?.is_bp) {
    options.push({
      id: 6,
      name: t('other', 'Other'),
    });
  }

  return options;
};

export const uterusEnumToString = (uterus: UterusEnum): string => {
  switch (uterus) {
    case UterusEnum.NO_EDEMA:
      return t('no-edema', 'No edema');
    case UterusEnum.SMALL_EDEMA:
      return t('small-edema', 'Small edema');
    case UterusEnum.MEDIUM_EDEMA:
      return t('medium-edema', 'Medium edema');
    case UterusEnum.LARGE_EDEMA:
      return t('large-edema', 'Large edema');
  }
};

export const softnessEnumToString = (softness: SoftnessEnum): string => {
  switch (softness) {
    case SoftnessEnum.SOFT:
      return t('cervix-soft', 'Soft');
    case SoftnessEnum.QUITE_SOFT:
      return t('cervix-quite-soft', 'Quite soft');

    case SoftnessEnum.QUITE_HARD:
      return t('cervix-quite-hard', 'Quite hard');
    case SoftnessEnum.HARD:
      return t('cervix-hard', 'Hard');
  }
};

export const pregnancyCheckTermEnumToString = (pregnancyCheckTerm: PregnancyCheckTermEnum): string => {
  switch (pregnancyCheckTerm) {
    case PregnancyCheckTermEnum.BEFORE:
      return t('pregnancy-check-term-before', 'Before');
    case PregnancyCheckTermEnum.FIRST:
      return t('pregnancy-check-term-first', 'First');
    case PregnancyCheckTermEnum.SECOND:
      return t('pregnancy-check-term-second', 'Second');
    case PregnancyCheckTermEnum.THIRD:
      return t('pregnancy-check-term-third', 'Third');
  }
};

export const eggLocationEnumToString = (eggLocation: LocationEnum): string => {
  switch (eggLocation) {
    case LocationEnum.LEFT:
      return t('egg-location-left', 'Left');
    case LocationEnum.RIGHT:
      return t('egg-location-right', 'Right');
  }
};
