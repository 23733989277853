import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { Contact, ContactsService, PatchedContactDetail, ProviderEnum } from 'openapi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';

interface Props {
  isVisible: boolean;
  onRequestClose: () => void;
  onUnlinked: (updatedContact: Contact) => void;
  contact?: Contact;
  unlinkIntegration: ProviderEnum.EXACTNL | ProviderEnum.MONEYBIRD;
}

function UnlinkBookkeepingIntegrationModal({ isVisible, onRequestClose, contact, onUnlinked, unlinkIntegration }: Props): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiErrorParser<Contact> | undefined>();

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  const integrationName = unlinkIntegration === ProviderEnum.EXACTNL ? 'Exact Online' : 'Moneybird';

  const unlink = async () => {
    if (!selectedOrganization || !contact) return;
    setSubmitting(true);

    try {
      let requestBody: PatchedContactDetail | undefined = undefined;

      // determine the request body based on the integration to unlink
      if (unlinkIntegration === ProviderEnum.EXACTNL) {
        requestBody = {
          exactnl_contact_id: '',
        };
      } else if (unlinkIntegration === ProviderEnum.MONEYBIRD) {
        requestBody = {
          moneybird_contact_id: null,
        };
      }

      const promise = ContactsService.contactsPartialUpdate({
        organisationUid: selectedOrganization.uid,
        uid: contact.uid,
        requestBody,
      });

      const updatedContact = await promise;

      // close the modal
      onRequestClose();

      // fire the onUnlinked callback
      onUnlinked(updatedContact);
    } catch (error) {
      setApiError(new ApiErrorParser<Contact>(error));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ActionModal
      open={isVisible}
      onClose={onRequestClose}
      actions={[
        {
          text: t('cancel', 'Cancel'),
          variant: ButtonVariant.Default,
          onClick: onRequestClose,
        },
        {
          text: t('unlink', 'Unlink'),
          loading: submitting,
          variant: ButtonVariant.PrimaryDanger,
          onClick: unlink,
        },
      ]}
      title={t('unlink-integration', 'Unlink {{integrationName}} integration', { integrationName })}
    >
      {apiError && <ErrorSection errors={apiError} />}
      <p className='mt-2  w-full'>
        {contact?.user_uid
          ? t('unlink-bookkeeping-user-desc', 'Are you sure you want to unlink this user from {{integrationName}}?', { integrationName })
          : t('unlink-bookkeeping-contact-desc', 'Are you sure you want to unlink this contact from {{integrationName}}?', {
              integrationName,
            })}
      </p>
    </ActionModal>
  );
}

export default UnlinkBookkeepingIntegrationModal;
