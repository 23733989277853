import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { Contact, ContactDetail, ContactsService } from 'openapi';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorSection } from 'ui/Error';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { ButtonVariant } from '../../ui/Button';
import { SelectInput, TextAreaInput, TextInput } from 'ui/Inputs';
import { Title, titleToString } from 'utilities/string.utility';
import { schemas } from 'openapi/zod-schemas';
import useFormError from 'api/hooks/useFormError';
import { transformEmptyToUndefined, zodInputIsRequired } from 'utilities/zod';
import RadioButtonGroup, { RadioButtonGroupOption } from 'ui/Inputs/RadioGroupInput';
import { z } from 'zod';
import Fieldset from 'ui/Fieldset';
import classNames from 'classnames';
import UseCountries from 'hooks/UseCountries';

enum ContactType {
  Personal,
  Business,
}

interface Props {
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onSaved?: (contact: Contact) => void;
  // Set the default name.
  // This value is prefilled into the name field
  defaultName?: string;
  // flag that indicate that this is an own external location
  isMyExternalLocation?: boolean;
}

interface NewContact extends ContactDetail {
  type_of_contact: ContactType;
}

function CreateContactModal({ isVisible, onRequestCloseModal, onSaved, defaultName, isMyExternalLocation = false }: Props): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>();
  const [typeOfContactFormValue, setTypeOfContactFormValue] = useState<ContactType>();

  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();
  const { countries } = UseCountries();

  // render a list of contact types
  const typeOfContact = useMemo((): RadioButtonGroupOption[] => {
    return [
      { id: ContactType.Personal, name: t('personal', 'Personal') },
      { id: ContactType.Business, name: t('business', 'Business') },
    ];
  }, [t]);

  // Form validation
  const schema = useMemo(() => {
    let fieldsToOmit: { [k in keyof Contact]?: true } = {};

    if (typeOfContactFormValue === ContactType.Business) {
      fieldsToOmit = {
        roles: true,
        business_type: true,
        color: true,
        initials: true,
        date_of_birth: true,
        title: true,
        first_name: true,
        last_name: true,
        uid: true,
        user_uid: true,
        stables: true,
        hidden: true,
        daily_activity_types: true,
        customer_id: true,
        moneybird_contact_id: true,
        exactnl_contact_id: true,
        created_on: true,
        last_modified_on: true,
        show_in_daily: true,
        show_in_work_schedule: true,
        stable_location_uid: true,
        invoice_language: true,
        lookup: true,
        manage_horse_location_history: true,
        machtiging_rvo_gegeven: true,
        machtiging_rvo_gegeven_by_uid: true,
        external_location: true,
        invitation: true,
        create_user_invitation: true,
        parent_stable: true,
      };
    } else {
      fieldsToOmit = {
        roles: true,
        business_type: true,
        color: true,
        initials: true,
        date_of_birth: true,
        vat_number: true,
        UBN: true,
        uid: true,
        user_uid: true,
        stables: true,
        hidden: true,
        daily_activity_types: true,
        customer_id: true,
        moneybird_contact_id: true,
        exactnl_contact_id: true,
        created_on: true,
        last_modified_on: true,
        show_in_daily: true,
        show_in_work_schedule: true,
        stable_location_uid: true,
        invoice_language: true,
        lookup: true,
        manage_horse_location_history: true,
        machtiging_rvo_gegeven: true,
        machtiging_rvo_gegeven_by_uid: true,
        external_location: true,
        invitation: true,
        create_user_invitation: true,
        parent_stable: true,
      };
    }

    // We omit the UBN when we are not creating an external location
    if (!isMyExternalLocation) {
      fieldsToOmit.UBN = true;
    }

    if (typeOfContactFormValue === ContactType.Business) {
      return schemas.Contact.omit(fieldsToOmit).extend({
        type_of_contact: z.union([z.literal(ContactType.Personal), z.literal(ContactType.Business)]),
        // make the business_name required for a company contact
        business_name: z.string().max(255),
      });
    }

    return schemas.Contact.omit(fieldsToOmit).extend({
      type_of_contact: z.union([z.literal(ContactType.Personal), z.literal(ContactType.Business)]),
      // make the first and last name required for a personal contact
      first_name: z.string().max(255),
      last_name: z.string().max(255),
    });
  }, [isMyExternalLocation, typeOfContactFormValue]);

  const defaultValues = {
    type_of_contact: ContactType.Personal,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
    clearErrors,
    reset,
  } = useForm<NewContact>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Close modal action
   */
  const close = () => {
    onRequestCloseModal();
    setApiError(undefined);
    clearErrors();
    reset();
  };

  /**
   * Submit handler
   */
  const onSubmit = async (data: ContactDetail) => {
    if (!selectedOrganization) return console.error('selectedOrganization is not defined');
    setSubmitting(true);

    try {
      const promiseCreate = ContactsService.contactsCreate({
        organisationUid: selectedOrganization.uid,
        requestBody: { ...data, external_location: isMyExternalLocation },
      });
      const savedContact = await promiseCreate;

      close();
      onSaved?.(savedContact);
    } catch (error) {
      setApiError(new ApiErrorParser<ContactDetail>(error));
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Watch the type_of_contact field and set the form value
   */
  useEffect(() => {
    const subscription = watch(({ type_of_contact }, { name }) => {
      if (name === 'type_of_contact') {
        setTypeOfContactFormValue(type_of_contact);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  /**
   * Set the type of contact
   * When isMyExternalLocation is set, set the type of the contact to business
   */
  useEffect(() => {
    if (isMyExternalLocation) {
      setValue('type_of_contact', ContactType.Business);
    } else {
      setValue('type_of_contact', ContactType.Personal);
    }
  }, [isMyExternalLocation, isVisible, setValue]);

  /**
   * Reset partially the form when the type of contact changes
   */
  useEffect(() => {
    if (typeOfContactFormValue === ContactType.Personal) {
      setValue('first_name', defaultName);
      setValue('business_name', '');
    } else {
      setValue('first_name', '');
      setValue('business_name', defaultName);
    }
    setValue('last_name', '');
    setValue('title', undefined);
    setValue('UBN', '');
    setValue('vat_number', '');
  }, [defaultName, setValue, typeOfContactFormValue]);

  return (
    <>
      <PageModal
        open={isVisible}
        parentElement='form'
        parentProps={{ id: 'SaveContactForm', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      >
        <PageModalTitle title={t('new-contact', 'New contact')} onClose={() => close()} />
        <PageModalContent>
          <ErrorSection errors={nonFieldErrors} />

          <div className='py-3'>
            <RadioButtonGroup
              className={classNames('mb-4', {
                // We can hide the type of contact field when we are creating an external location
                hidden: isMyExternalLocation,
              })}
              name='type_of_contact'
              error={fieldError('type_of_contact')}
              required={true}
              control={control}
              options={typeOfContact}
              label={t('type-of-contact', 'Type of contact')}
            />

            <div className='grid md:grid-cols-2 gap-3 grid-cols-1 mb-4'>
              {typeOfContactFormValue === ContactType.Personal && (
                <>
                  <TextInput
                    error={fieldError('first_name')}
                    required={zodInputIsRequired<Contact>(schema, 'first_name')}
                    label={t('firstName', 'First name')}
                    {...register('first_name', { setValueAs: transformEmptyToUndefined() })}
                  />
                  <TextInput
                    error={fieldError('last_name')}
                    required={zodInputIsRequired<Contact>(schema, 'last_name')}
                    label={t('lastName', 'Last name')}
                    {...register('last_name', { setValueAs: transformEmptyToUndefined() })}
                  />
                  <SelectInput
                    error={fieldError('title')}
                    required={zodInputIsRequired<Contact>(schema, 'title')}
                    options={Object.keys(Title).map(key => ({ id: key, name: titleToString(key as Title, t) }))}
                    nullable={true}
                    nullableValue=''
                    label={t('title', 'Title')}
                    {...register('title', { setValueAs: transformEmptyToUndefined() })}
                  />
                  <TextInput
                    type='email'
                    error={fieldError('email')}
                    required={zodInputIsRequired<Contact>(schema, 'email')}
                    label={t('email', 'Email')}
                    {...register('email', { setValueAs: transformEmptyToUndefined() })}
                  />
                  <TextInput
                    error={fieldError('business_name')}
                    required={zodInputIsRequired<Contact>(schema, 'business_name')}
                    label={t('businessName', 'Business name')}
                    {...register('business_name', { setValueAs: transformEmptyToUndefined() })}
                  />
                </>
              )}

              {typeOfContactFormValue === ContactType.Business && (
                <>
                  <TextInput
                    error={fieldError('business_name')}
                    required={zodInputIsRequired<Contact>(schema, 'business_name')}
                    label={t('name', 'Name')}
                    {...register('business_name', { setValueAs: transformEmptyToUndefined() })}
                  />
                  <TextInput
                    type='email'
                    error={fieldError('email')}
                    required={zodInputIsRequired<Contact>(schema, 'email')}
                    label={t('email', 'Email')}
                    {...register('email', { setValueAs: transformEmptyToUndefined() })}
                  />
                  <TextInput
                    error={fieldError('vat_number')}
                    required={zodInputIsRequired<Contact>(schema, 'vat_number')}
                    label={t('vat_number', 'VAT number')}
                    {...register('vat_number', { setValueAs: transformEmptyToUndefined() })}
                  />

                  {isMyExternalLocation && (
                    <TextInput
                      error={fieldError('UBN')}
                      required={zodInputIsRequired<Contact>(schema, 'UBN')}
                      label={t('UBN', 'UBN')}
                      {...register('UBN', { setValueAs: transformEmptyToUndefined() })}
                    />
                  )}
                </>
              )}

              <TextInput
                error={fieldError('phone_number')}
                required={zodInputIsRequired<Contact>(schema, 'phone_number')}
                type='tel'
                label={t('phone_number', 'Phone number')}
                {...register('phone_number', { setValueAs: transformEmptyToUndefined() })}
              />
            </div>
            <TextAreaInput
              rows={2}
              error={fieldError('note')}
              required={zodInputIsRequired<Contact>(schemas.Contact, 'note')}
              label={t('note', 'Note')}
              {...register('note')}
            />

            <Fieldset legend={t('address', 'Address')} className='grid gap-3 grid-cols-1 md:grid-cols-2 mb-4'>
              <TextInput
                error={fieldError('address_line1')}
                required={zodInputIsRequired<Contact>(schemas.Contact, 'address_line1')}
                label={t('street', 'Street')}
                {...register('address_line1', { setValueAs: transformEmptyToUndefined() })}
              />
              <TextInput
                error={fieldError('address_line3')}
                required={zodInputIsRequired<Contact>(schemas.Contact, 'address_line3')}
                label={t('house-number', 'House number')}
                {...register('address_line3', { setValueAs: transformEmptyToUndefined() })}
              />
              <TextInput
                error={fieldError('address_line2')}
                required={zodInputIsRequired<Contact>(schemas.Contact, 'address_line2')}
                label={t('address-line2', 'Address line 2')}
                {...register('address_line2', { setValueAs: transformEmptyToUndefined() })}
              />
              <TextInput
                error={fieldError('postcode')}
                required={zodInputIsRequired<Contact>(schemas.Contact, 'postcode')}
                label={t('postcode', 'Postcode')}
                {...register('postcode', { setValueAs: transformEmptyToUndefined() })}
              />
              <TextInput
                error={fieldError('city')}
                required={zodInputIsRequired<Contact>(schemas.Contact, 'city')}
                label={t('city', 'City')}
                {...register('city')}
              />
              <TextInput
                error={fieldError('state')}
                required={zodInputIsRequired<Contact>(schemas.Contact, 'state')}
                label={t('stateOrCounty', 'State or province')}
                {...register('state')}
              />
              <SelectInput
                error={fieldError('country')}
                required={zodInputIsRequired<Contact>(schemas.Contact, 'country')}
                options={countries}
                nullable={true}
                nullableValue=''
                label={t('country', 'Country')}
                {...register('country', { setValueAs: transformEmptyToUndefined() })}
              />
            </Fieldset>
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              loading: submitting,
              variant: ButtonVariant.Primary,
              text: t('save', 'Save'),
              type: 'submit',
              formId: 'SaveContactForm',
            },
          ]}
        />
      </PageModal>
    </>
  );
}

export default CreateContactModal;
