import {
  CloudArrowUp,
  DotsThreeVertical,
  File,
  FileAudio,
  FilePdf,
  FileVideo,
  FileXls,
  GridNine,
  IconContext,
  ListBullets,
  MicrosoftWordLogo,
  YoutubeLogo,
} from '@phosphor-icons/react';
import { HorseMedia, PrivateEnum } from 'openapi';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownMenu, { DropdownMenuItemArray } from 'ui/DropdownMenu';
import OptimizedImage from 'ui/OptimizedImage';
import { QueuedFileUpload, UseHorseHookType } from 'api/hooks/useHorseFiles';
import { ActionModal, PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import Button, { ButtonVariant } from 'ui/Button';
import { tableTbodyTr } from 'ui/Const';
import useModal from 'ui/Modals/UseModal';
import ApiErrorParser from 'api/ApiErrorParser';
import { ErrorSection } from 'ui/Error';
import RenameHorseFileModal from './RenameHorseFileModal';
import classNames from 'classnames';
import { Spinner } from 'ui/Loading';
import { SpinnerSize } from 'ui/Loading/Spinner';
import { IS_MOBILE_APP } from 'const';
import { openFileForMobile } from 'utilities/DownloadFile';
import useFullPageLoader from 'hooks/UseFullPageLoader';

export enum View {
  Tiles,
  List,
}

interface Props {
  displayTypes: PrivateEnum[];
  uploadType: PrivateEnum;
  useHorseHookType: UseHorseHookType;
  canUpload: boolean;
}

// The the icon that belongs to a file.
const fileIcon = (file: HorseMedia): JSX.Element => {
  if (file.content_type === 'application/pdf') {
    return <FilePdf />;
  }
  if (
    file.content_type === 'application/msword' ||
    file.content_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ) {
    return <MicrosoftWordLogo />;
  }
  if (
    file.content_type === 'application/vnd.ms-excel' ||
    file.content_type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ) {
    return <FileXls />;
  }
  if (file.content_type?.startsWith('video/') && <FileVideo />) {
    return <FileVideo />;
  }
  if (file.content_type?.startsWith('audio/')) {
    return <FileAudio />;
  }
  if (file.YTVim) {
    return <YoutubeLogo />;
  }
  return <File />;
};

// Thumbnail element for tile view
function TileThumb({ file }: { file: HorseMedia }): JSX.Element {
  const [previewLoading, setPreviewLoading] = useState<boolean>(true);

  if (file.file && file.content_type?.startsWith('image/')) {
    return (
      <div className='w-full h-full bg-gray-300 relative'>
        <OptimizedImage className='w-full h-full object-cover' src={file.file} width={256} onLoaded={() => setPreviewLoading(false)} />
        {previewLoading && (
          <div className='absolute bottom-2 left-2'>
            <Spinner size={SpinnerSize.Small} />
          </div>
        )}
      </div>
    );
  } else {
    return (
      <IconContext.Provider
        value={{
          size: 48,
          weight: 'light',
        }}
      >
        <div className='w-full h-full border flex flex-col gap-2 items-center justify-center'>
          {fileIcon(file)}
          <p className='text-xs w-full line-clamp-2 break-all px-1 text-center'>{file.filename}</p>
        </div>
      </IconContext.Provider>
    );
  }
}

// A component that shows files for horse. These can be the media files or the medical files.
export default function HorseFiles({ displayTypes, uploadType, useHorseHookType, canUpload }: Props): JSX.Element {
  const { t } = useTranslation();
  const { files, deleteFile, refresh, upload, error, uploading, selectAndUploadFiles, downloadFile } = useHorseHookType;
  const [selectedFile, setSelectedFile] = useState<HorseMedia | undefined>();
  const { modalIsVisible: deleteModalIsVisible, closeModal: closeDeleteModal, showModal: showDeleteModal } = useModal();
  const { modalIsVisible: previewModalIsVisible, closeModal: closePreviewModal, showModal: showPreviewModal } = useModal();
  const { modalIsVisible: renameModalIsVisible, closeModal: closeRenameModal, showModal: showRenameModal } = useModal();
  const [apiError, setApiError] = useState<ApiErrorParser<void> | undefined>();
  const [dragDropHover, setDragDropHover] = useState<boolean>(false);
  const [previewIframeLoading, setPreviewIframeLoading] = useState<boolean>(false);
  const [fileView, setFileView] = useState<View>(View.Tiles);

  const { loadingElement: loadingElementMobile, setLoading: setLoadingForMobile } = useFullPageLoader({
    loadingText: t('loading-file', 'Loading file'),
  });

  const visibleFiles = useMemo(() => {
    return files?.filter(file => file.private && displayTypes.includes(file.private));
  }, [files, displayTypes]);

  // Returns true if the given file is an image
  const fileIsImage = (file: HorseMedia) => {
    return file.content_type?.startsWith('image/');
  };

  // Returns true if the given file is a video
  const fileIsVideo = (file: HorseMedia) => {
    return file.content_type?.startsWith('video/');
  };

  // Returns true if the selected file is an image
  const selectedFileIsImage = useMemo(() => {
    return selectedFile ? fileIsImage(selectedFile) : false;
  }, [selectedFile]);

  // Returns true if the selected file is a video
  const selectedFileIsVideo = useMemo(() => {
    return selectedFile ? fileIsVideo(selectedFile) : false;
  }, [selectedFile]);

  const downloadOpenedFile = () => {
    if (!selectedFile) {
      console.error('Cannot download file if no file is selected');
      return;
    }
    downloadFile(selectedFile);
  };

  const menuItems = useCallback(
    (file: HorseMedia): DropdownMenuItemArray[] => {
      return [
        [
          {
            element: t('rename', 'Rename'),
            onClick: () => {
              setSelectedFile(file);
              showRenameModal();
            },
          },
        ],
        [
          {
            element: t('remove', 'Remove'),
            className: 'text-red-600',
            onClick: () => {
              setSelectedFile(file);
              showDeleteModal();
            },
          },
        ],
      ];
    },
    [t, showDeleteModal, showRenameModal],
  );

  /**
   * Convert all files from a Drag N Drop event into a fileList of File[]
   */
  const fromDragDropEvent = async (items: DataTransferItemList): Promise<QueuedFileUpload[]> => {
    const processedFiles: QueuedFileUpload[] = [];
    const queuedEntries: FileSystemEntry[] = [];

    const add = async (entry: FileSystemEntry | null): Promise<QueuedFileUpload> => {
      // return custom promise as the .file() resolve the file in the callback
      return new Promise<QueuedFileUpload>((resolve, reject) => {
        if (entry && entry.isFile) {
          const correctEntryType = entry as FileSystemFileEntry;
          correctEntryType.file(
            file => {
              resolve({ bytes: file.arrayBuffer(), name: entry.name, contentType: file.type, privacy: uploadType });
            },
            error => reject(error),
          );
        }
      });
    };

    // When calling .file() or .createReader() on the result of webkitGetAsEntry() the next item (and others)
    // will return null from calling webkitGetAsEntry()
    // there for we need to fetch first all results from webkitGetAsEntry() to avoid this issue
    // see https://stackoverflow.com/questions/12105900/fileentry-file-method-makes-datatransferitemlist-empty
    if (items) {
      for (const item of items) {
        const entry = item.webkitGetAsEntry();
        if (entry) {
          queuedEntries.push(entry);
        }
      }
    }

    for (const entry of queuedEntries) {
      if (entry && entry.isFile) {
        // single file
        processedFiles.push(await add(entry as FileSystemFileEntry));
      } else if (entry && entry.isDirectory) {
        // We don't do folders.
        console.error('We do not support uploading folders yet.');
      }
    }
    return processedFiles;
  };

  const openFile = async (file: HorseMedia) => {
    // for mobile we are using a native way of opening files
    if (IS_MOBILE_APP) {
      setLoadingForMobile(true);
      try {
        await openFileForMobile(file.file_download_url);
      } catch (e) {
        console.error('Failed to open file for mobile', e);
      } finally {
        setLoadingForMobile(false);
      }
    } else {
      setPreviewIframeLoading(true);
      setSelectedFile(file);
      showPreviewModal();
    }
  };

  return (
    <div className='space-y-2'>
      <ErrorSection errors={error} />
      {canUpload && visibleFiles && visibleFiles.length === 0 && (
        <div
          className={classNames(
            'w-full rounded-xl border-2 border-blue-300 border-dashed flex flex-col gap-4 items-center justify-center py-12',
            { 'bg-blue-50': dragDropHover },
            { 'bg-blue-50/40': !dragDropHover },
          )}
          onDragOver={event => event.preventDefault()} // enables it to receive drop events
          onDragEnter={() => setDragDropHover(true)}
          onDragLeave={() => setDragDropHover(false)}
          onDrop={async event => {
            event.preventDefault();
            await upload(await fromDragDropEvent(event.dataTransfer.items));
            setDragDropHover(false);
          }}
        >
          <CloudArrowUp size={52} weight='thin' className='text-blue-300' />
          <Button onClick={selectAndUploadFiles} variant={ButtonVariant.Primary}>
            {t('add-file', 'Add file')}
          </Button>
        </div>
      )}

      {!canUpload && (
        <p className='text-gray-500 italic'>
          {t('no-files-uploaded-and-no-permission-to-upload', 'No files uploaded and you have no permission to upload files.')}
        </p>
      )}
      {visibleFiles && visibleFiles.length > 0 && (
        <div>
          <div className='flex gap-x-1 justify-start mb-2'>
            <button
              onClick={() => setFileView(View.Tiles)}
              className={classNames('p-1 rounded', {
                'bg-gray-100': fileView === View.Tiles,
              })}
            >
              <GridNine />
            </button>
            <button
              onClick={() => setFileView(View.List)}
              className={classNames('p-1 rounded', {
                'bg-gray-100': fileView === View.List,
              })}
            >
              <ListBullets />
            </button>
          </div>
          {fileView === View.Tiles && (
            <div className='flex gap-1 flex-wrap'>
              {visibleFiles?.map(file => (
                <div className='w-32 h-32 group relative cursor-pointer' key={file.uid}>
                  <TileThumb file={file} />
                  <div
                    onClick={() => openFile(file)}
                    className='absolute top-0 bg-gradient-to-br from-transparent from-50% md:opacity-0 md:group-hover:opacity-80 to-white w-full h-full transition-opacity'
                  />
                  <DropdownMenu menuPlacement={'right-end'} menuItems={menuItems(file)}>
                    <button className='pl-2 pt-2 pr-1 pb-1 absolute bottom-1 right-0 md:opacity-0 md:group-hover:opacity-100 group-hover:scale-125 transition-all'>
                      <DotsThreeVertical weight='bold' size={20} />
                    </button>
                  </DropdownMenu>
                </div>
              ))}
            </div>
          )}
          {fileView === View.List && (
            <>
              <table className='w-full'>
                <tbody>
                  {visibleFiles?.map(file => (
                    <tr className={tableTbodyTr} key={file.uid}>
                      <td onClick={() => openFile(file)} className='w-10'>
                        <IconContext.Provider
                          value={{
                            size: 22,
                            weight: 'light',
                          }}
                        >
                          {fileIcon(file)}
                        </IconContext.Provider>
                      </td>
                      <td className='break-all' onClick={() => openFile(file)}>
                        {file.filename}
                      </td>
                      <td>
                        <DropdownMenu menuItems={menuItems(file)}>
                          <button className='pl-2 py-2'>
                            <DotsThreeVertical weight='bold' size={22} />
                          </button>
                        </DropdownMenu>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      )}
      {uploading && (
        <div className='flex gap-1 items-center text-gray-700'>
          <Spinner size={SpinnerSize.XSmall} />
          <span>{t('uploading', 'Uploading')}...</span>
        </div>
      )}
      <ActionModal
        open={deleteModalIsVisible}
        onClose={closeDeleteModal}
        actions={[
          {
            text: t('cancel', 'Cancel'),
            variant: ButtonVariant.Default,
            onClick: () => {
              setApiError(undefined);
              setSelectedFile(undefined);
              closeDeleteModal();
            },
          },
          {
            text: t('delete', 'Delete'),
            variant: ButtonVariant.PrimaryDanger,
            onClick: () => {
              if (selectedFile) {
                const promise = deleteFile(selectedFile);
                promise
                  .then(() => {
                    setSelectedFile(undefined);
                    setApiError(undefined);
                    closeDeleteModal();
                    refresh();
                  })
                  .catch(e => {
                    setApiError(new ApiErrorParser<void>(e));
                  });
                return promise;
              }
            },
          },
        ]}
        title={t('delete-file', 'Delete file')}
      >
        {apiError && <ErrorSection errors={apiError} />}
        <p className='mt-2  w-full'>
          {t('delete-file-desc', 'Are you sure you want to delete "{{filename}}"?', {
            filename: selectedFile?.filename ?? 'file',
          })}
        </p>
      </ActionModal>
      <RenameHorseFileModal
        file={selectedFile}
        visible={renameModalIsVisible}
        onSaved={refresh}
        requestClose={closeRenameModal}
        onClosed={() => setSelectedFile(undefined)}
      />
      <PageModal width={PageModalWidth.Lg} open={previewModalIsVisible}>
        <PageModalTitle title={selectedFile?.filename ?? 'File'} onClose={closePreviewModal} />
        <PageModalContent>
          <div className='h-full relative max-h-[80vh] bg-neutral-100'>
            {selectedFileIsImage ? (
              <img
                src={selectedFile?.file ?? ''}
                onLoad={() => setPreviewIframeLoading(false)}
                className='w-full h-full object-contain bg-neutral-100'
              />
            ) : selectedFileIsVideo ? (
              <video
                controls={true}
                autoPlay={true}
                src={selectedFile?.file ?? ''}
                onLoadedData={() => setPreviewIframeLoading(false)}
                className='w-full max-h-[80vh] object-contain bg-neutral-100'
              />
            ) : (
              <iframe className='w-full h-[70vh]' onLoad={() => setPreviewIframeLoading(false)} src={selectedFile?.file ?? ''} />
            )}
            {previewIframeLoading && (
              <div className='absolute top-1/2 left-1/2 p-2 border rounded-full bg-white'>
                <Spinner size={SpinnerSize.Normal} />
              </div>
            )}
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              onClick: downloadOpenedFile,
              variant: ButtonVariant.Default,
              type: 'button',
              text: t('download', 'Download'),
            },
          ]}
        />
      </PageModal>
      {loadingElementMobile}
    </div>
  );
}
