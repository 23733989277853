import React, { useEffect, useMemo } from 'react';
import { FixedPage } from 'ui/Layout/PageFixed';
import { PageAction } from 'context/PageContext';
import { useTranslation } from 'react-i18next';
import { Plus } from '@phosphor-icons/react';
import { ButtonVariant } from 'ui/Button';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import {
  BluePrintState,
  CalendarActivity,
  CalendarActivityType,
  CalendarView,
  DragDropType,
  GroupBy,
  listFilterActivityTypes,
  stableListFilterTypes,
  TimeScale,
  ViewType,
} from 'utilities/Planning';
import { PlanningProvider } from 'context/PlanningContext';
import { usePlanning } from 'hooks/UsePlanning';
import { FilterOption, ListFilterType } from 'components/Common/ListFilter';
import { age, gender, listFilter } from 'utilities/Horse';
import { CategoryEnum, Horse, SexEnum } from 'openapi';
import Calendar, { GroupByApplied } from 'context/Calendar';
import useListFilter from 'components/Common/ListFilter/useListFilter';
import { useOrganization } from 'context/OrganizationContext';
import useRefreshingNow from 'hooks/UseRefreshingNow';
import { PlanningToolbar } from 'components/Activities/PlanningToolbar';
import { MultiDayBody, MultiDayHeader, MultiDayHeaderDailyNotes } from 'components/Activities/MultiDayView';
import { DayBody, DayViewGroupByHeader } from 'components/Activities/DayView';
import SaveActivityModal from 'components/Activities/SaveActivityModal';
import { listFilterStable } from 'utilities/Contact';
import { useAccount } from 'context/AccountContext';
import PlanningErrorModal from 'components/Activities/PlanningErrorModal';
import usePermissions from 'hooks/UsePermissions';

/**
 * We need this separate component to be able to use the PlanningDataProvider.
 */
function HorsePlanningContent(): JSX.Element {
  const { t } = useTranslation();
  const {
    horses,
    stables,
    groups,
    viewType,
    groupBy,
    activityTypes,
    loadApiData,
    loadActivityApiData,
    offset,
    previousOffset,
    activities,
    requestBluePrint,
    clearSelectedActivity,
    getDayPartForTime,
    unsetBluePrint,
  } = usePlanning();
  const { width: screenWidth } = useScreenSize();
  const { selectedOrganizationUid } = useOrganization();
  const { now } = useRefreshingNow();
  const { accountDetails } = useAccount();
  const { permissions } = usePermissions();

  // A list of types we can filter by. Like Gender and DateOfBirth.
  const filterTypes = useMemo((): ListFilterType[] | undefined => {
    if (!horses) {
      return undefined;
    }
    let genderUnknownCount = 0;
    let genderStallionCount = 0;
    let genderMareCount = 0;
    let genderGeldingCount = 0;
    let inactiveCount = 0;
    let noStableCount = 0;

    const showCounts = true;

    const birthYears = new Map<number, number>();
    const ageCount = new Map<number, number>();
    let hasNotSetDate = 0; // True if we have a date that is undefined in the list.
    horses.forEach(h => {
      if (h.date_of_birth) {
        const date = new Date(Date.parse(h.date_of_birth));
        birthYears.set(date.getFullYear(), birthYears.get(date.getFullYear()) ?? 1);
        const horseAge = age(h);
        if (horseAge !== undefined && horseAge !== null) {
          // We already known the horseAge is not undefined (but TS wants this if-condition)
          ageCount.set(horseAge, ageCount.get(horseAge) ?? 1);
        }
      } else {
        hasNotSetDate++;
      }

      if (h.sex === 1) {
        genderStallionCount++;
      } else if (h.sex === 2) {
        genderMareCount++;
      } else if (h.sex === 3) {
        genderGeldingCount++;
      } else {
        genderUnknownCount++;
      }

      if (h.hidden) {
        inactiveCount++;
      }
      if (!h.stable_uid) {
        noStableCount++;
      }
    });

    const ageFilterOptions: FilterOption[] = [];
    if (hasNotSetDate > 0) {
      ageFilterOptions.push({ id: 'unknown', name: t('unknown', 'Unknown'), count: showCounts ? hasNotSetDate : undefined });
    }

    const sortedAges = Array.from(ageCount);
    sortedAges.sort();
    sortedAges.forEach(value => {
      ageFilterOptions.push({ id: `${value[0]}`, name: `${value[0]}`, count: showCounts ? value[1] : undefined });
    });

    const ageFilter: ListFilterType = {
      id: 'age',
      name: t('age', 'Age'),
      options: ageFilterOptions,
    };

    const genderFilter: ListFilterType = {
      id: 'gender',
      name: t('gender', 'Gender'),
      options: [
        { id: 'unknown', name: t('gender-unknown', 'Unknown'), count: showCounts ? genderUnknownCount : undefined },
        { id: 'stallion', name: gender(SexEnum._1, t), count: showCounts ? genderStallionCount : undefined },
        { id: 'mare', name: gender(SexEnum._2, t), count: showCounts ? genderMareCount : undefined },
        { id: 'gelding', name: gender(SexEnum._3, t), count: showCounts ? genderGeldingCount : undefined },
      ],
    };

    const passiveFilter: ListFilterType = {
      id: 'passive',
      name: t('passive', 'Passive'),
      options: [
        { id: 'inactive', name: t('inactive', 'Inactive'), count: showCounts ? inactiveCount : undefined },
        { id: 'no-stable', name: t('no-stable', 'No stable'), count: showCounts ? noStableCount : undefined },
      ],
    };

    const groupFilter: ListFilterType = {
      id: 'group',
      name: t('group', 'Group'),
      options: (groups ?? []).map(group => {
        return { id: group.uid, name: group.name ?? t('unnamed-group', 'Unnamed group') };
      }),
    };

    const categoryFilter: ListFilterType = {
      id: 'category',
      name: t('horse-usage-category', 'Horse category'),
      options: [
        { id: CategoryEnum.CARE, name: t('activity-type-category-care', 'Care') },
        { id: CategoryEnum.SPORT, name: t('activity-type-category-sport', 'Sport') },
        { id: CategoryEnum.BREEDING, name: t('activity-type-category-breeding', 'Breeding') },
      ],
    };

    const activityTypeFilter: ListFilterType = {
      id: 'activity-type',
      name: t('activityType', 'Activity type'),
      options: (activityTypes ?? []).map(type => {
        return { id: type.uid, name: type.name ?? t('unnamed-type', 'Unnamed type') };
      }),
    };

    return [categoryFilter, groupFilter, genderFilter, ageFilter, activityTypeFilter, passiveFilter];
  }, [horses, t, groups, activityTypes]);

  const stableFilterTypes = useMemo((): ListFilterType[] | undefined => {
    if ((stables?.length ?? 0) <= 1) {
      // No need to show the stable filter when we only have one stable
      return undefined;
    }
    return [stableListFilterTypes(t, stables ?? [], accountDetails?.preferred_stable ?? undefined)];
  }, [t, stables, accountDetails]);

  const pageActions = useMemo((): PageAction[] => {
    return [
      {
        text: t('add', 'Add'),
        isMobileAddAction: true,
        icon: <Plus />,
        buttonVariant: ButtonVariant.Primary,
        onClick: () => {
          clearSelectedActivity();
          requestBluePrint({
            state: BluePrintState.EditFull,
            dayPart: getDayPartForTime(now.getHours(), now.getMinutes()),
            day: now,
            duration: 0,
            startPeriodOffset: 0,
            preferredType: CalendarActivityType.Activity,
          });
        },
      },
    ];
  }, [t, requestBluePrint, clearSelectedActivity, getDayPartForTime, now]);

  const { filters } = useListFilter(filterTypes ?? []);
  const { filters: locationFilters } = useListFilter(stableFilterTypes ?? []);

  const filteredHorses = useMemo((): Horse[] | undefined => {
    return listFilter(horses ?? [], filters.concat(locationFilters));
  }, [horses, filters, locationFilters]);

  const filteredActivities = useMemo((): CalendarActivity[] | undefined => {
    return listFilterActivityTypes(activities ?? [], filters);
  }, [activities, filters]);

  const filteredStables = useMemo((): string[] | undefined => {
    return listFilterStable(stables ?? [], locationFilters).map(stable => stable.uid);
  }, [stables, locationFilters]);

  const calendarClusters = useMemo((): GroupByApplied[] | undefined => {
    if (!filteredHorses) {
      return undefined;
    }
    return (
      filteredHorses.map(horse => {
        return { groupBy: GroupBy.Horse, subject: horse };
      }) ?? []
    );
  }, [filteredHorses]);

  // Calculate the column header width based on arbitrary values.
  const colHeaderWidth = useMemo(() => {
    if (viewType === ViewType.Week) {
      return groupBy ? 200 : 77;
    } else if (viewType === ViewType.Day) {
      if (groupBy && screenWidth <= ScreenSize.md) {
        return 150;
      } else {
        return 77;
      }
    } else {
      throw Error('Unimplemented');
    }
  }, [viewType, groupBy, screenWidth]);

  // This indicates if the contacts/horses should be on the x or y axis.
  // When a screen get smaller, the contacts/horses are forced on the y axis.
  const groupByAsHeader = screenWidth > ScreenSize.md;

  // Initially load all from the api
  useEffect(() => {
    // Next to the org uid we also require the permissions to be set in order to correctly load the api data.
    if (selectedOrganizationUid && permissions) {
      const promise = loadApiData(true);
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid, viewType]); //eslint-disable-line

  // Load activities from the api
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadActivityApiData();
      return () => promise.cancel();
    }
  }, [offset, now]); //eslint-disable-line

  const calendarView = useMemo((): CalendarView | undefined => {
    if (!filteredActivities || !calendarClusters) {
      return undefined;
    }
    return {
      offset: offset,
      previousOffset: previousOffset,
      current: new Calendar(now, viewType, offset, calendarClusters, filteredActivities),
      left: new Calendar(now, viewType, offset - 1, calendarClusters, []),
      right: new Calendar(now, viewType, offset + 1, calendarClusters, []),
    };
  }, [filteredActivities, now, viewType, offset, previousOffset, calendarClusters]);

  return (
    <FixedPage actions={pageActions}>
      <div className='w-full h-full flex flex-col'>
        <div className='shadow-planningheader z-10 bg-primary md:bg-neutral-50'>
          {calendarView && (
            <>
              <div className='md:p-2 mt-safe'>
                <PlanningToolbar
                  calendarView={calendarView}
                  stableFilterTypes={stableFilterTypes}
                  filterTypes={filterTypes}
                  showDay={false}
                  pageActions={pageActions}
                />
              </div>
              {(viewType === ViewType.Week || (viewType === ViewType.Day && !groupByAsHeader)) && (
                <MultiDayHeader calendarView={calendarView} columnHeaderWidth={colHeaderWidth} />
              )}
              <MultiDayHeaderDailyNotes
                calendarView={calendarView}
                columnHeaderWidth={colHeaderWidth}
                selectedStableUids={filteredStables}
              />
              {viewType === ViewType.Day && groupByAsHeader && (
                <DayViewGroupByHeader calendarView={calendarView} columnHeaderWidth={colHeaderWidth} />
              )}
            </>
          )}
        </div>
        <div
          className='overflow-y-scroll no-scrollbar flex-1'
          onClick={() => {
            // Clear the selection when we click outside.
            clearSelectedActivity();
            unsetBluePrint();
          }}
        >
          {calendarView && (
            <>
              {(viewType === ViewType.Week || !groupByAsHeader) && (
                <MultiDayBody calendarView={calendarView} columnHeaderWidth={colHeaderWidth} dragDropType={DragDropType.DayPart} />
              )}
              {viewType === ViewType.Day && groupByAsHeader && <DayBody calendarView={calendarView} columnHeaderWidth={colHeaderWidth} />}
            </>
          )}
        </div>
        <SaveActivityModal />
        <PlanningErrorModal />
      </div>
    </FixedPage>
  );
}

/**
 * Planning page for horses
 */
export default function HorsePlanning(): JSX.Element {
  const { width } = useScreenSize();

  return (
    <PlanningProvider
      viewType={width > ScreenSize.md ? ViewType.Week : ViewType.Day}
      groupBy={GroupBy.Horse}
      timeScale={TimeScale.DayParts}
    >
      <HorsePlanningContent />
    </PlanningProvider>
  );
}
