import React, { useEffect, useMemo } from 'react';
import { FixedPage } from 'ui/Layout/PageFixed';
import { PageAction } from 'context/PageContext';
import { useTranslation } from 'react-i18next';
import { Plus } from '@phosphor-icons/react';
import { ButtonVariant } from 'ui/Button';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import {
  BluePrintState,
  CalendarActivity,
  CalendarActivityType,
  CalendarView,
  DragDropType,
  GroupBy,
  listFilterActivityTypes,
  stableListFilterTypes,
  TimeScale,
  ViewType,
} from 'utilities/Planning';
import { PlanningProvider } from 'context/PlanningContext';
import { usePlanning } from 'hooks/UsePlanning';
import { ListFilterType } from 'components/Common/ListFilter';
import Calendar, { GroupByApplied } from 'context/Calendar';
import { Contact } from 'openapi';
import { listFilter, listFilterStable } from 'utilities/Contact';
import useListFilter from 'components/Common/ListFilter/useListFilter';
import { useOrganization } from 'context/OrganizationContext';
import useRefreshingNow from 'hooks/UseRefreshingNow';
import { PlanningToolbar } from 'components/Activities/PlanningToolbar';
import { MultiDayBody, MultiDayHeader, MultiDayHeaderDailyNotes } from 'components/Activities/MultiDayView';
import { DayBody, DayViewGroupByHeader } from 'components/Activities/DayView';
import SaveActivityModal from 'components/Activities/SaveActivityModal';
import { useAccount } from 'context/AccountContext';
import PlanningErrorModal from 'components/Activities/PlanningErrorModal';
import usePermissions from 'hooks/UsePermissions';

/**
 * We need this separate component to be able to use the PlanningDataProvider.
 */
function StaffPlanningContent(): JSX.Element {
  const { t } = useTranslation();
  const { width: screenWidth } = useScreenSize();
  const { selectedOrganizationUid } = useOrganization();
  const { accountDetails } = useAccount();

  const {
    stables,
    contacts,
    viewType,
    groupBy,
    loadApiData,
    loadActivityApiData,
    offset,
    previousOffset,
    activities,
    activityTypes,
    requestBluePrint,
    clearSelectedActivity,
    getDayPartForTime,
    unsetBluePrint,
  } = usePlanning();
  const { now } = useRefreshingNow();
  const { permissions } = usePermissions();

  const stableFilterTypes = useMemo((): ListFilterType[] | undefined => {
    if ((stables?.length ?? 0) <= 1) {
      // No need to show the stable filter when we only have one stable
      return undefined;
    }
    return [stableListFilterTypes(t, stables ?? [], accountDetails?.preferred_stable ?? undefined)];
  }, [t, stables, accountDetails]);

  // A list of types we can filter by.
  const filterTypes = useMemo((): ListFilterType[] | undefined => {
    const activityTypeFilter: ListFilterType = {
      id: 'activity-type',
      name: t('activityType', 'Activity type'),
      options: (activityTypes ?? []).map(type => {
        return { id: type.uid, name: type.name ?? t('unnamed-type', 'Unnamed type') };
      }),
    };

    return [activityTypeFilter];
  }, [t, activityTypes]);

  const pageActions = useMemo((): PageAction[] => {
    return [
      {
        text: t('add', 'Add'),
        isMobileAddAction: true,
        icon: <Plus />,
        buttonVariant: ButtonVariant.Primary,
        onClick: () => {
          clearSelectedActivity();
          requestBluePrint({
            state: BluePrintState.EditFull,
            dayPart: getDayPartForTime(now.getHours(), now.getMinutes()),
            day: now,
            duration: 0,
            startPeriodOffset: 0,
            preferredType: CalendarActivityType.Activity,
          });
        },
      },
    ];
  }, [t, requestBluePrint, clearSelectedActivity, getDayPartForTime, now]);

  const { filters: stableFilters } = useListFilter(stableFilterTypes ?? []);
  const { filters } = useListFilter(filterTypes ?? []);

  const filteredContacts = useMemo((): Contact[] | undefined => {
    // Filter based on the url query params.
    // And only include contacts that are user and/or contacts with the show_in_daily boolean set.
    return listFilter(
      (contacts ?? []).filter(contact => contact.user_uid || contact.show_in_daily),
      stableFilters,
    );
  }, [contacts, stableFilters]);

  const calendarClusters = useMemo((): GroupByApplied[] | undefined => {
    if (!filteredContacts) {
      return undefined;
    }
    let result: GroupByApplied[] = [];
    result.push({ groupBy: GroupBy.StaffCatchAll, subject: undefined });
    result = result.concat(
      filteredContacts.map(contact => {
        return { groupBy: GroupBy.Staff, subject: contact };
      }) ?? [],
    );
    return result;
  }, [filteredContacts]);

  const filteredStables = useMemo((): string[] | undefined => {
    return listFilterStable(stables ?? [], stableFilters).map(stable => stable.uid);
  }, [stables, stableFilters]);

  const filteredActivities = useMemo((): CalendarActivity[] | undefined => {
    return listFilterActivityTypes(activities ?? [], filters);
  }, [activities, filters]);

  // Calculate the column header width based on arbitrary values.
  const colHeaderWidth = useMemo(() => {
    if (viewType === ViewType.Week) {
      return groupBy ? 200 : 77;
    } else if (viewType === ViewType.Day) {
      if (groupBy && screenWidth <= ScreenSize.md) {
        return 150;
      } else {
        return 77;
      }
    } else {
      throw Error('Unimplemented');
    }
  }, [viewType, groupBy, screenWidth]);

  // This indicates if the contacts/horses should be on the x or y axis.
  // When a screen get smaller, the contacts/horses are forced on the y axis.
  const groupByAsHeader = screenWidth > ScreenSize.md;

  // Initially load all from the api
  useEffect(() => {
    // Next to the org uid we also require the permissions to be set in order to correctly load the api data.
    if (selectedOrganizationUid && permissions) {
      const promise = loadApiData(true);
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid, viewType, permissions]); //eslint-disable-line

  // Load activities from the api
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadActivityApiData();
      return () => promise.cancel();
    }
  }, [offset, now]); //eslint-disable-line

  const calendarView = useMemo((): CalendarView | undefined => {
    if (!filteredActivities || !calendarClusters) {
      return undefined;
    }
    return {
      offset: offset,
      previousOffset: previousOffset,
      current: new Calendar(now, viewType, offset, calendarClusters, filteredActivities),
      left: new Calendar(now, viewType, offset - 1, calendarClusters, []),
      right: new Calendar(now, viewType, offset + 1, calendarClusters, []),
    };
  }, [filteredActivities, now, viewType, offset, previousOffset, calendarClusters]);

  return (
    <FixedPage actions={pageActions}>
      <div className='w-full h-full flex flex-col'>
        <div className='shadow-planningheader z-10 bg-primary md:bg-neutral-50'>
          {calendarView && (
            <>
              <div className='md:p-2 mt-safe'>
                <PlanningToolbar
                  calendarView={calendarView}
                  filterTypes={filterTypes}
                  stableFilterTypes={stableFilterTypes}
                  showDay={false}
                  pageActions={pageActions}
                />
              </div>
              {(viewType === ViewType.Week || (viewType === ViewType.Day && !groupByAsHeader)) && (
                <MultiDayHeader calendarView={calendarView} columnHeaderWidth={colHeaderWidth} />
              )}
              <MultiDayHeaderDailyNotes
                calendarView={calendarView}
                columnHeaderWidth={colHeaderWidth}
                selectedStableUids={filteredStables}
              />
              {viewType === ViewType.Day && groupByAsHeader && (
                <DayViewGroupByHeader calendarView={calendarView} columnHeaderWidth={colHeaderWidth} />
              )}
            </>
          )}
        </div>
        <div
          className='overflow-y-scroll no-scrollbar flex-1'
          onClick={() => {
            // Clear the selection when we click outside.
            clearSelectedActivity();
            unsetBluePrint();
          }}
        >
          {calendarView && (
            <>
              {(viewType === ViewType.Week || !groupByAsHeader) && (
                <MultiDayBody dragDropType={DragDropType.ClusterPlus} calendarView={calendarView} columnHeaderWidth={colHeaderWidth} />
              )}
              {viewType === ViewType.Day && groupByAsHeader && <DayBody calendarView={calendarView} columnHeaderWidth={colHeaderWidth} />}
            </>
          )}
        </div>
        <SaveActivityModal />
        <PlanningErrorModal />
      </div>
    </FixedPage>
  );
}

/**
 * Planning page for contacts
 */
export default function StaffPlanning(): JSX.Element {
  const { width } = useScreenSize();

  return (
    <PlanningProvider
      viewType={width > ScreenSize.md ? ViewType.Week : ViewType.Day}
      groupBy={GroupBy.Staff}
      timeScale={TimeScale.DayParts}
    >
      <StaffPlanningContent />
    </PlanningProvider>
  );
}
