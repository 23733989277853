import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { CancelablePromise, Horse, NIFAStrawColorEnum, StallionMount, StallionmountsService } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { DateInput, SelectInput, TextInput } from 'ui/Inputs';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { transformEmptyNumber, transformEmptyToUndefined } from 'utilities/zod';

interface Props {
  stallions: Horse[];
  selectedStallion?: Horse;
  existingMount?: StallionMount;
  open: boolean;
  onRequestClose: (reload: boolean, mount?: StallionMount) => void;
}

// Return date of today in YYYY-MM-DD format.
const today = (): string => {
  return new Date().toISOString().substr(0, 10);
};

function SaveStallionMount({ stallions, selectedStallion, existingMount, open, onRequestClose }: Props): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();
  const [hasStrawsFrozen, setHasStrawsFrozen] = useState<boolean>(false);

  const schema = useMemo(() => {
    return schemas.StallionMount.omit({
      uid: true,
      portions_fresh_available: true,
      portions_fresh_destroyed_amount: true,
      portions_fresh_destroyed_on: true,
      straws_frozen_available: true,
      straws_frozen_destroyed_amount: true,
      straws_frozen_destroyed_on: true,
      storage_container: !hasStrawsFrozen ? true : undefined,
      storage_canister: !hasStrawsFrozen ? true : undefined,
      NIFA_straw_color: !hasStrawsFrozen ? true : undefined,
      activity_uid: true,
      created_on: true,
      created_by: true,
      last_modified_on: true,
      last_modified_by: true,
    })
      .refine(data => data.portions_fresh !== undefined, {
        path: ['portions_fresh'],
        message: t('err-zero-or-more-portions', 'Provide 0 or more portions'),
      })
      .refine(data => data.straws_frozen !== undefined, {
        path: ['straws_frozen'],
        message: t('err-zero-or-more-straws', 'Provide 0 or more straws'),
      })
      .refine(data => data.storage_container !== undefined || !hasStrawsFrozen, {
        path: ['storage_container'],
        message: t('field-required', 'This field is required'),
      })
      .refine(data => data.storage_canister !== undefined || !hasStrawsFrozen, {
        path: ['storage_canister'],
        message: t('field-required', 'This field is required'),
      })
      .refine(data => data.NIFA_straw_color !== undefined || !hasStrawsFrozen, {
        path: ['NIFA_straw_color'],
        message: t('field-required', 'This field is required'),
      });
  }, [hasStrawsFrozen, t]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    control,
    reset,
  } = useForm<StallionMount>({
    reValidateMode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: existingMount ?? {
      stallion: selectedStallion?.uid,
      collection_date: today(),
    },
  });

  // We show and hide the storage fields based upon straws_frozen > 0.
  const watchStrawsFrozen = watch('straws_frozen');

  useEffect(() => {
    setHasStrawsFrozen(watchStrawsFrozen !== undefined && watchStrawsFrozen > 0);
  }, [watchStrawsFrozen]);

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const onSubmit = async (data: StallionMount) => {
    if (!selectedOrganization) {
      console.error('Cannot load invoice without a selected organization');
      return;
    }

    let promise: CancelablePromise<StallionMount>;
    if (existingMount) {
      promise = StallionmountsService.stallionmountsPartialUpdate({
        stallionOrganisationUid: selectedOrganization.uid,
        uid: existingMount.uid,
        requestBody: data,
      });
    } else {
      promise = StallionmountsService.stallionmountsCreate({
        stallionOrganisationUid: selectedOrganization.uid,
        requestBody: data,
      });
    }

    try {
      onRequestClose(true, await promise);
    } catch (e) {
      setApiError(new ApiErrorParser<StallionMount>(e));
    }
  };

  // Event that will be fired after the modal has been closed
  const resetForm = () => {
    setApiError(undefined);
    setHasStrawsFrozen(false);
    reset(
      existingMount ?? {
        stallion: selectedStallion?.uid,
        collection_date: today(),
      },
    );
  };

  // Get the niwa straw color translated.
  const nifaStrawColorOptions = useMemo(() => {
    const options: OptionItemInterface[] = [];
    Object.keys(NIFAStrawColorEnum).forEach(key => {
      options.push({ id: key, name: key.toString().replaceAll('_', ' ') });
    });
    return options;
  }, []);

  const stallionOptions = useMemo(() => {
    // Add the non hidden stallions. But also when we're editing a mount, then
    // add the stallion of that mount to.
    return stallions
      .filter(horse => !horse.hidden || horse.uid === existingMount?.stallion)
      .map(horse => {
        return { id: horse.uid, name: horse.name };
      });
  }, [stallions, existingMount]);

  return (
    <PageModal
      open={open}
      onClosed={resetForm}
      parentElement='form'
      parentProps={{ id: 'addStallionMount', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
    >
      <PageModalTitle
        title={t('add-mount', 'New semen collection')}
        onClose={() => {
          onRequestClose(false);
        }}
      />

      <PageModalContent>
        <ErrorSection className='mb-4' errors={nonFieldErrors} />

        <div className='flex flex-col md:flex-row gap-4 mb-4'>
          <div className='flex flex-col gap-4 grow'>
            <SelectInput
              label={t('stallion', 'Stallion')}
              nullable={true}
              required={true}
              options={stallionOptions}
              error={fieldError('stallion')}
              {...register('stallion', { setValueAs: (val: unknown): unknown => (val === '----' ? undefined : val) })}
            />
            <DateInput
              control={control}
              required={true}
              label={t('semen-collection-date', 'Collection date')}
              name='collection_date'
              error={fieldError('collection_date')}
            />
            <TextInput
              label={t('semen-volume-ml', 'Volume (ml)')}
              {...register('volume', { setValueAs: transformEmptyNumber(undefined) })}
              postText={t('ml', 'ml')}
              error={fieldError('volume')}
            />
            <TextInput
              label={t('semen-concentration-million-per-ml', 'Concentration (million/ml)')}
              {...register('concentration', { setValueAs: transformEmptyNumber(undefined) })}
              postText={t('million-per-ml', 'million/ml')}
              error={fieldError('concentration')}
            />
            <TextInput
              label={t('moving-cells-percentage', 'Moving cells percentage')}
              {...register('moving_cells_percentage', { setValueAs: transformEmptyNumber(undefined) })}
              postText={'%'}
              error={fieldError('moving_cells_percentage')}
            />
          </div>
          <div className='flex flex-col gap-4 grow'>
            <TextInput
              required={true}
              label={t('semen-portions-fresh', 'Portions fresh')}
              postText={'portions'}
              {...register('portions_fresh', { setValueAs: transformEmptyNumber(undefined) })}
              error={fieldError('portions_fresh')}
            />
            <TextInput
              required={true}
              label={t('semen-straws-frozen', 'Straws frozen')}
              postText={'straws'}
              {...register('straws_frozen', { setValueAs: transformEmptyNumber(undefined) })}
              error={fieldError('straws_frozen')}
            />
            {hasStrawsFrozen && (
              <>
                <TextInput
                  required={true}
                  label={t('semen-storage-container', 'Storage container')}
                  {...register('storage_container', { setValueAs: transformEmptyToUndefined() })}
                  error={fieldError('storage_container')}
                />
                <TextInput
                  required={true}
                  label={t('semen-storage-canister', 'Storage canister')}
                  {...register('storage_canister', { setValueAs: transformEmptyToUndefined() })}
                  error={fieldError('storage_canister')}
                />
                <SelectInput
                  required={true}
                  nullable={true}
                  options={nifaStrawColorOptions}
                  error={fieldError('NIFA_straw_color')}
                  label={t('semen-niva-straw-color', 'NIFA straw color')}
                  {...register('NIFA_straw_color', { setValueAs: (val: unknown): unknown => (val === '----' ? undefined : val) })}
                />
              </>
            )}
          </div>
        </div>
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            variant: ButtonVariant.Primary,
            text: t('save', 'Save'),
            type: 'submit',
            formId: 'addStallionMount',
            loading: isSubmitting,
          },
        ]}
      />
    </PageModal>
  );
}
export default SaveStallionMount;
