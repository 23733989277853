import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import {
  ActivitiesService,
  ActivityContactRole,
  CategoryEnum,
  Contact,
  DailyNote,
  DailynoteService,
  DefaultEnum,
  HorsesService,
  PatchedDailyNote,
  PatchedRealActivities,
  PregnancyCheckTermEnum,
  RealActivities,
  Role,
} from 'openapi';
import { DatetimeOrDateField } from 'openapi/models/DatetimeOrDateField';
import { usePlanning } from 'hooks/UsePlanning';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { get, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorSection } from 'ui/Error';
import { DateInput, TextAreaInput, TextInput } from 'ui/Inputs';
import RadioButtonGroup, { RadioButtonGroupOption } from 'ui/Inputs/RadioGroupInput';
import SelectInput, { OptionItemInterface } from 'ui/Inputs/SelectInput';
import { dateToHHMM, formatDate } from 'utilities/date.utilities';
import { transformEmptyToUndefined } from 'utilities/zod';
import { z } from 'zod';
import ContactInputSelect from 'components/Contacts/ContactInputSelect';
import classNames from 'classnames';
import { useAccount } from 'context/AccountContext';
import { Pencil, Warning } from '@phosphor-icons/react';
import {
  CalendarActivityType,
  datePartToDateTime,
  datePartToTime,
  DayParts,
  getActivityTypeCategory,
  GroupBy,
  SelectedActivityState,
  stableListFilterTypes,
  ViewType,
} from 'utilities/Planning';
import { addDays, isSameDay } from 'date-fns';
import useListFilter from 'components/Common/ListFilter/useListFilter';
import { contactName, listFilterStable } from 'utilities/Contact';
import { ListFilterType } from 'components/Common/ListFilter';
import { WrappedComboboxProps } from 'ui/Inputs/SelectList';
import { BreedingBadge, CareBadge, HorseUsageBadges, SportBadge } from 'components/Horses/HorseUsageBadges';
import { BadgeSize } from 'ui/Badge/Badge';
import HorseInputSelect from 'components/Horses/HorseInputSelect';
import { pregnancyCheckTermEnumToString } from 'utilities/Horse';

// Schema for activity contact roles. We marked the contact_uid optional because it's not required to pass them in.
const ActivityContactRoleSchema = z.object({ role_uid: z.string(), contact_uid: z.string().optional(), primary: z.boolean().optional() });

const schema = z.object({
  calendarActivityType: z.string().optional(),
  date: z.string(),

  // Activity:
  horse_uid: z.string().optional(),
  start: z.string().optional(),
  end: z.string().optional(),
  activity_type_uid: z.string().optional(),
  daypart: z.string().optional(),
  activitycontactrole_set: z.array(ActivityContactRoleSchema).optional(),
  extra_info: z.string().optional(),

  // DailyNote:
  title: z.string().optional(),
  text: z.string().optional(),
  end_date: z.string().optional(),
  color: z.string().optional(),
  stable_uid: z.string().optional(),

  // For pregnancy check activity types
  pregnancy_check_term: z.string().optional(),
});

type SchemaType = z.infer<typeof schema>;

interface Props {
  compact: boolean;
  submitting?: (isSubmitting: boolean) => void;
  onSaved: () => void;
}

export default function SaveActivityForm({ compact, submitting, onSaved }: Props): JSX.Element {
  const { selectedOrganizationUid } = useOrganization();
  const { t } = useTranslation();
  const { formatTime: formatLocalTime, formatDateIntl } = useAccount();
  const {
    stables,
    activityTypes,
    roles,
    dayParts,
    calendar,
    viewType,
    horses,
    contacts,
    bluePrint,
    selectedActivity,
    requestBluePrint,
    unsetBluePrint,
    setLastUsedActivityType,
    setSelectedActivity,
    updateHorseInCache,
  } = usePlanning();

  // True when all data fields are visible. And false when we show a summary button that expands the fields.
  const [dateExpanded, setDateExpanded] = useState<boolean>(false);

  const filterTypes = useMemo((): ListFilterType[] | undefined => {
    return [stableListFilterTypes(t, stables ?? [])];
  }, [t, stables]);

  const formSchema = useMemo(() => {
    return schema
      .refine(data => data.daypart !== '' || data.start !== undefined, {
        path: ['start'],
        message: t('start-time-required', 'Start time is required'),
      })
      .refine(data => data.daypart !== '' || data.end !== undefined, {
        path: ['end'],
        message: t('end-time-required', 'End time is required'),
      });
  }, [t]);

  /**
   * Transform the list of contacts into 2 groups based on the role we have set. The
   * first group are the contacts with the role we're looking for. The other group is
   * the rest.
   */
  const contactListForRole = useCallback(
    (role: Role): WrappedComboboxProps<Contact>[] => {
      const result: WrappedComboboxProps<Contact>[] = [];
      result.push({ items: contacts?.filter(contact => contact.roles?.includes(role.uid)) ?? [], heading: role.name });
      result.push({ items: contacts?.filter(contact => !contact.roles?.includes(role.uid)) ?? [], heading: t('others', 'Others') });
      return result;
    },
    [contacts, t],
  );

  const { filters } = useListFilter(filterTypes ?? []);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
    watch,
  } = useForm<SchemaType>({
    resolver: zodResolver(formSchema),
    reValidateMode: 'onChange',
    defaultValues: {
      horse_uid: bluePrint?.horseUid,
      activity_type_uid: bluePrint?.activityTypeUid,
    },
  });
  const selectedCalendarActivityType = watch('calendarActivityType');
  const selectedHorseUid = watch('horse_uid');
  const selectedActivityTypeUid = watch('activity_type_uid');

  const selectedHorse = useMemo(() => {
    const horseUid = selectedHorseUid ?? bluePrint?.horseUid;
    return horses?.find(horse => horse.uid === horseUid);
  }, [selectedHorseUid, horses, bluePrint]);

  const calendarActivityType = useMemo((): CalendarActivityType | undefined => {
    if (selectedCalendarActivityType !== undefined) {
      return Number(selectedCalendarActivityType) as CalendarActivityType;
    }
    return selectedActivity?.activity.type ?? bluePrint?.type;
  }, [selectedActivity, bluePrint, selectedCalendarActivityType]);

  const showCalendarActivityTypeRadio = useMemo(() => {
    return bluePrint?.type === undefined && !selectedActivity;
  }, [bluePrint, selectedActivity]);

  const selectedActivityType = useMemo(() => {
    if (selectedActivityTypeUid) {
      return activityTypes?.find(activityType => activityType.uid === selectedActivityTypeUid);
    }
  }, [selectedActivityTypeUid, activityTypes]);

  const horseActivityTypeMismatch = useMemo((): boolean => {
    if (!selectedHorse || !selectedActivityType) {
      return false;
    }
    const category = selectedActivityType.category;
    switch (category) {
      case CategoryEnum.BREEDING:
        return !selectedHorse.use_in_breeding ?? false;
      case CategoryEnum.CARE:
        return !selectedHorse.use_in_care ?? false;
      case CategoryEnum.SPORT:
        return !selectedHorse.use_in_sport ?? false;
    }
    return false;
  }, [selectedHorse, selectedActivityType]);

  /**
   * Inform the parent component that we're submitting or not.
   */
  useEffect(() => {
    if (submitting) {
      submitting(isSubmitting);
    }
  }, [isSubmitting, submitting]);

  const watchDayPart = watch('daypart');
  const watchActivityTypeUid = watch('activity_type_uid');

  const rolesForActivity = useMemo((): Role[] => {
    let result: Role[] = [];
    if (watchActivityTypeUid && activityTypes && roles) {
      const activity = activityTypes.find(activity => activity.uid === watchActivityTypeUid);
      result = roles.filter(role => activity?.possible_roles?.includes(role.uid));
    }
    return result;
  }, [watchActivityTypeUid, roles, activityTypes]);

  // Get a nice human readable date and time string to display in the collapsed date button.
  const dateLabel = useMemo((): { date: string; time?: string } => {
    let startDate = new Date();
    let endDate: Date | undefined = undefined;
    let time = '';
    if (selectedActivity) {
      startDate = new Date(selectedActivity.activity.startTime);
      if (selectedActivity.activity.type === CalendarActivityType.Message || selectedActivity.activity.type === CalendarActivityType.Task) {
        if (!isSameDay(selectedActivity.activity.startTime, selectedActivity.activity.endTime)) {
          // Display two dates when it's a multiday event.
          endDate = selectedActivity.activity.endTime;
        }
      } else if (!selectedActivity.activity.isAllDayEvent) {
        // Time notation
        time = `${formatLocalTime(selectedActivity.activity.startTime)} - ${formatLocalTime(selectedActivity.activity.endTime)}`;
      } else if (selectedActivity.activity.dayPart !== undefined) {
        // Day parts notation
        const dayPart = dayParts?.[selectedActivity.activity.dayPart];
        time = dayPart?.name ?? 'Unknown day part';
      }
    } else if (bluePrint) {
      startDate = bluePrint.day ?? new Date();
      if (calendarActivityType === CalendarActivityType.Activity) {
        if (bluePrint.dayPart !== undefined) {
          // Day part notation
          const dayPart = dayParts?.[bluePrint.dayPart];
          time = dayPart?.name ?? 'Unknown day part';
        } else {
          // Time notation
          const times = datePartToDateTime(startDate, DayParts.HalfHour, bluePrint.startPeriodOffset ?? 0, bluePrint.duration ?? 1);
          time = `${formatLocalTime(times.start)} - ${formatLocalTime(times.end)}`;
        }
      }
    }
    if (endDate) {
      // Mutiday event
      return {
        date: `${formatDateIntl(startDate, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          weekday: 'long',
        })} - ${formatDateIntl(endDate, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          weekday: 'long',
        })}`,
        time: time,
      };
    } else {
      // Single day event
      return {
        date: formatDateIntl(startDate, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          weekday: 'long',
        }),
        time: time,
      };
    }
  }, [selectedActivity, bluePrint, formatLocalTime, formatDateIntl, dayParts, calendarActivityType]);

  const { fieldError, nonFieldErrors, setApiError } = useFormError(formSchema, errors);

  const submitActivity = async (data: SchemaType) => {
    if (!selectedOrganizationUid) return console.error('selectedOrganization is not defined');

    try {
      // First we check if we need to add an extra category to the horse. For instance, when
      // a Sport-activity is added to a horse this is not used for Sport then add the horse
      // to the category.
      if (horseActivityTypeMismatch) {
        const patchedHorse = {
          use_in_breeding: selectedActivityType?.category === CategoryEnum.BREEDING ? true : undefined,
          use_in_sport: selectedActivityType?.category === CategoryEnum.SPORT ? true : undefined,
          use_in_care: selectedActivityType?.category === CategoryEnum.CARE ? true : undefined,
        };
        await HorsesService.horsesPartialUpdate({
          organisationUid: selectedOrganizationUid,
          uid: data.horse_uid ?? '',
          requestBody: patchedHorse,
        });
        // Patch the horse in our local cache so it gets updated in the view.
        const foundHorse = horses?.find(horse => horse.uid === data.horse_uid);
        if (foundHorse) {
          if (patchedHorse.use_in_breeding) {
            foundHorse.use_in_breeding = patchedHorse.use_in_breeding;
          }
          if (patchedHorse.use_in_sport) {
            foundHorse.use_in_sport = patchedHorse.use_in_sport;
          }
          if (patchedHorse.use_in_care) {
            foundHorse.use_in_care = patchedHorse.use_in_care;
          }
          updateHorseInCache(foundHorse);
        }
      }

      const startDate = new Date(data.date);
      const activity: Partial<RealActivities> = {};
      activity.horse_uid = data.horse_uid;
      activity.activity_type_uid = data.activity_type_uid;
      if (data.daypart) {
        let daypartNumber = dayParts?.findIndex(dp => dp.uid === data.daypart) ?? 0;
        activity.start = { date: data.date } as DatetimeOrDateField;
        // End date should be one day later for full day events.
        activity.end = { date: formatDate(addDays(startDate, 1)) } as DatetimeOrDateField;
        // Add one because we don't work with offsets in the api
        daypartNumber = daypartNumber + 1;
        activity.daypart = daypartNumber;
        activity.all_day_event = true;
      } else {
        activity.all_day_event = false;
        activity.start = { datetime: `${formatDate(startDate)}T${data.start}` } as DatetimeOrDateField;
        if (data.end === '00:00') {
          // A special case where the enddate is at midnight. When we support multiday events then this should be changed.
          const endDate = addDays(startDate, 1);
          activity.end = { datetime: `${formatDate(endDate)}T${data.end}` } as DatetimeOrDateField;
        } else {
          activity.end = { datetime: `${formatDate(startDate)}T${data.end}` } as DatetimeOrDateField;
        }
      }

      // We do the typecast because the contact_uid is optional from the form. We filter the items without a contact out here.
      activity.activitycontactrole_set = data.activitycontactrole_set?.filter(item => item.contact_uid) as ActivityContactRole[];
      activity.extra_info = data.extra_info;

      if (selectedActivity?.activity) {
        await ActivitiesService.activitiesPartialUpdate({
          organisationUid: selectedOrganizationUid,
          uid: selectedActivity.activity.uid,
          requestBody: activity as PatchedRealActivities,
        });
      } else {
        await ActivitiesService.activitiesCreate({
          organisationUid: selectedOrganizationUid,
          requestBody: activity as RealActivities,
        });
      }

      if (data.activity_type_uid) {
        const activityType = activityTypes?.find(type => type.uid === data.activity_type_uid);
        if (activityType) {
          setLastUsedActivityType(activityType);
        }
      }
      close();
      if (onSaved) {
        onSaved();
      }
    } catch (error) {
      setApiError(new ApiErrorParser<RealActivities>(error));
    }
  };

  const submitDailyNote = async (data: SchemaType) => {
    if (!selectedOrganizationUid) return console.error('selectedOrganization is not defined');
    try {
      const dailyNote: Partial<DailyNote> = {};
      const endDateString = data.end_date ?? data.date;
      let endDate = new Date(endDateString);
      endDate = addDays(endDate, 1);

      dailyNote.start = { date: data.date } as DatetimeOrDateField;
      dailyNote.end = { date: formatDate(endDate) } as DatetimeOrDateField;
      dailyNote.title = data.title;
      dailyNote.text = data.text;
      dailyNote.all_day_event = true;
      dailyNote.executable = Number(data.calendarActivityType) === CalendarActivityType.Task;
      dailyNote.color = data.color;

      // A daily note can be connected to a stable.
      const groupByUid = data.stable_uid ?? selectedActivity?.groupByUid ?? bluePrint?.appliedGroupBy?.subject?.uid;
      dailyNote.stable_uid = groupByUid;

      if (selectedActivity) {
        await DailynoteService.dailynotePartialUpdate({
          organisationUid: selectedOrganizationUid,
          uid: selectedActivity.activity.uid,
          requestBody: dailyNote as PatchedDailyNote,
        });
      } else {
        await DailynoteService.dailynoteCreate({ organisationUid: selectedOrganizationUid, requestBody: dailyNote as DailyNote });
      }
      close();
      if (onSaved) {
        onSaved();
      }
    } catch (error) {
      setApiError(new ApiErrorParser<DailyNote>(error));
    }
  };

  const onSubmit = async (data: SchemaType) => {
    if (calendarActivityType === CalendarActivityType.Activity) {
      return submitActivity(data);
    } else {
      return submitDailyNote(data);
    }
  };

  /**
   * Close modal action
   */
  const close = () => {
    if (selectedActivity) {
      setSelectedActivity(selectedActivity.activity, SelectedActivityState.Selected, selectedActivity.groupByUid);
    }
    unsetBluePrint(0);
  };

  const dailyNoteColorsTypeOptions = useMemo((): OptionItemInterface[] => {
    return [
      { id: '#ef4444', name: t('color-red', 'Red') },
      { id: '#f97316', name: t('color-orange', 'Orange') },
      { id: '#f59e0b', name: t('color-amber', 'Amber') },
      { id: '#facc15', name: t('color-yellow', 'Yellow') },
      { id: '#84cc16', name: t('color-lime', 'Lime') },
      { id: '#22c55e', name: t('color-green', 'Green') },
      { id: '#10b981', name: t('color-emerald', 'Emerald') },
      { id: '#14b8a6', name: t('color-teal', 'Teal') },
      { id: '#06b6d4', name: t('color-cyan', 'Cyan') },
      { id: '#0ea5e9', name: t('color-sky', 'Sky') },
      { id: '#3b82f6', name: t('color-blue', 'Blue') },
      { id: '#6366f1', name: t('color-indigo', 'Indigo') },
      { id: '#8b5cf6', name: t('color-violet', 'Violet') },
      { id: '#a855f7', name: t('color-purple', 'Purple') },
      { id: '#d946ef', name: t('color-fuchsia', 'Fuchsia') },
      { id: '#ec4899', name: t('color-pink', 'Pink') },
      { id: '#f43f5e', name: t('color-rose', 'Rose') },
    ];
  }, [t]);

  const activityTypeOptions = useMemo((): RadioButtonGroupOption[] => {
    if (!activityTypes) {
      return [];
    }
    return activityTypes
      .filter(activityType => !activityType.hidden)
      .map(type => ({
        id: type.uid,
        name: type.name ?? '',
        icon:
          type.category === CategoryEnum.CARE ? (
            <CareBadge iconOnly={true} size={BadgeSize.Small} />
          ) : type.category === CategoryEnum.SPORT ? (
            <SportBadge iconOnly={true} size={BadgeSize.Small} />
          ) : type.category === CategoryEnum.BREEDING ? (
            <BreedingBadge iconOnly={true} size={BadgeSize.Small} />
          ) : undefined,
      }));
  }, [activityTypes]);

  const dayPartsOptions = useMemo((): OptionItemInterface[] => {
    if (!dayParts) {
      return [];
    }
    const options = dayParts.map((dayPart, index, array) => {
      const compactTimeStr = (time: string) => {
        const items = time.split(':');
        return `${items[0]}:${items[1]}`;
      };
      let endTime = '23:59';
      if (index < array.length - 1) {
        endTime = array[index + 1].start_time;
      }
      const timeRange = `${compactTimeStr(dayPart.start_time)} - ${compactTimeStr(endTime)}`;
      const name = dayPart.name ? `${dayPart.name} (${timeRange})` : timeRange;
      return { id: dayPart.uid, name };
    });
    options.push({ id: '', name: t('custom-activity-date', 'Custom') });
    return options;
  }, [dayParts, t]);

  const calendarActivityTypeOptions = useMemo((): OptionItemInterface[] => {
    if (bluePrint?.preferredType === CalendarActivityType.Message || bluePrint?.preferredType === CalendarActivityType.Task) {
      return [
        { id: CalendarActivityType.Task.toString(), name: t('task', 'Task') },
        { id: CalendarActivityType.Message.toString(), name: t('message', 'Message') },
      ];
    } else {
      return [
        { id: CalendarActivityType.Activity.toString(), name: t('activity', 'Activity') },
        { id: CalendarActivityType.Task.toString(), name: t('task', 'Task') },
        { id: CalendarActivityType.Message.toString(), name: t('message', 'Message') },
      ];
    }
  }, [t, bluePrint]);

  const horseOptions = useMemo(() => {
    let stableUids: string[] = [];
    if (bluePrint?.stableUid) {
      stableUids.push(bluePrint?.stableUid);
    } else {
      stableUids = listFilterStable(stables ?? [], filters).map(stable => stable.uid);
    }

    return horses?.filter(horse => {
      if (stableUids.length > 0) {
        // When we have a stable filter, then only allow to select horses from the stable.
        return horse.stable_uid && stableUids.includes(horse.stable_uid);
      } else {
        return true;
      }
    });
  }, [horses, filters, stables, bluePrint]);

  const stableOptions = useMemo(() => {
    return (
      stables?.map(stable => {
        return { id: stable.uid, name: contactName(stable.location) ?? '' };
      }) ?? []
    );
  }, [stables]);

  const pregnancyCheckTermEnumOptions = useMemo(() => {
    const options: OptionItemInterface[] = [];
    Object.values(PregnancyCheckTermEnum).forEach(value => {
      options.push({ id: value, name: pregnancyCheckTermEnumToString(value) });
    });
    return options;
  }, []);

  // Build a list of ActivityContactRole for the used and unused role assignees for the existing activity.
  // Roles that have not been assigned are also returned. This is important to display them correctly and not
  // get missing indexes. This method will automatically set the selected groupBy contact for the appropriate
  // role.
  // @todo Set default_rider etc when in groupByType === Horse
  const activityContactRolesBluePrint = useMemo((): Partial<ActivityContactRole>[] => {
    if (!bluePrint || bluePrint.type !== CalendarActivityType.Activity || bluePrint.appliedGroupBy?.groupBy !== GroupBy.Staff) {
      return [];
    }
    const activityType = activityTypes?.find(type => type.uid === bluePrint.activityTypeUid);
    const contact = contacts?.find(contact => contact.uid === bluePrint.appliedGroupBy?.subject?.uid);
    if (bluePrint.appliedGroupBy?.groupBy === GroupBy.Staff && activityType && contact) {
      const availableRoles = roles?.filter(role => activityType.possible_roles?.includes(role.uid)) ?? [];
      let preferedRole = availableRoles.find(roleUid => contact.roles?.includes(roleUid.uid));
      if (!preferedRole && availableRoles.length > 0) {
        preferedRole = availableRoles[0];
      }
      return availableRoles.map((role): Partial<ActivityContactRole> => {
        if (role.uid === preferedRole?.uid) {
          return { contact_uid: contact.uid, role_uid: role.uid, primary: true };
        } else {
          return { role_uid: role.uid };
        }
      });
    } else {
      return [];
    }
  }, [activityTypes, contacts, bluePrint, roles]);

  // Returns a list of errors where the contact roles mismatches the activity role.
  const roleMismatchErrors = useMemo(() => {
    if (!bluePrint || bluePrint.type !== CalendarActivityType.Activity || bluePrint.appliedGroupBy?.groupBy !== GroupBy.Staff) {
      return [];
    }
    const activityType = activityTypes?.find(type => type.uid === bluePrint.activityTypeUid);
    const contact = contacts?.find(contact => contact.uid === bluePrint.appliedGroupBy?.subject?.uid);
    const mismatches: string[] = [];
    if (bluePrint.appliedGroupBy?.groupBy === GroupBy.Staff && activityType && contact) {
      activityContactRolesBluePrint.forEach(item => {
        if (item.role_uid && item.contact_uid === contact.uid && !contact.roles?.includes(item.role_uid)) {
          const roleName = roles?.find(role => role.uid === item.role_uid)?.name ?? item.role_uid;
          mismatches.push(
            t('activity-role-mismatch-error', "{{contactname}} doesn't have {{role}} role.", {
              contactname: contactName(contact),
              role: roleName,
            }),
          );
        }
      });
      return mismatches;
    } else {
      return [];
    }
  }, [activityContactRolesBluePrint, activityTypes, bluePrint, contacts, t, roles]);

  // Set the correct initial values for the form.
  useEffect(() => {
    if (selectedActivity) {
      let start: string | undefined;
      let end: string | undefined;
      let dayPartUid = '';
      if (selectedActivity.activity.isAllDayEvent) {
        dayPartUid = dayParts?.[selectedActivity.activity.dayPart].uid ?? '';
      } else {
        start = dateToHHMM(selectedActivity.activity.startTime);
        end = dateToHHMM(selectedActivity.activity.endTime);
      }

      // Build a list of ActivityContactRole for the used and unused role assignees for the existing activity.
      // Roles that have not been assigned are also returned. This is important to display them correctly and not
      // get missing indexes.
      const activityContactRoles = (): Partial<ActivityContactRole>[] => {
        return (roles?.filter(role => selectedActivity.activity.activityType?.possible_roles?.includes(role.uid)) ?? []).map(
          (role): Partial<ActivityContactRole> => {
            const foundAssignee = selectedActivity.activity.assignedTo.find(assignee => assignee.role?.uid === role.uid);
            return { role_uid: role.uid, contact_uid: foundAssignee?.contact?.uid, primary: foundAssignee?.primary };
          },
        );
      };

      reset({
        calendarActivityType: calendarActivityType?.toString(),
        horse_uid: selectedActivity?.activity.horseUid,
        daypart: dayPartUid,
        date: formatDate(selectedActivity.activity.startTime),
        end_date: formatDate(selectedActivity.activity.endTime),
        activity_type_uid: selectedActivity.activity.activityType?.uid,
        activitycontactrole_set: activityContactRoles(),
        start,
        end,
        title: selectedActivity.activity.title,
        text: selectedActivity.activity.text,
        extra_info: selectedActivity.activity.extraInfo,
        color: selectedActivity.activity.primaryColor,
      });
      setDateExpanded(false);
    } else if (bluePrint) {
      let start: string | undefined;
      let end: string | undefined;
      let dayPartUid = '';
      if (bluePrint.dayPart !== undefined && calendarActivityType === CalendarActivityType.Activity) {
        dayPartUid = dayParts?.[bluePrint.dayPart].uid ?? '';
      } else {
        start = datePartToTime(DayParts.HalfHour, bluePrint.startPeriodOffset ?? 0);
        end = datePartToTime(DayParts.HalfHour, (bluePrint.startPeriodOffset ?? 0) + (bluePrint.duration ?? 1));
      }
      setDateExpanded(!((start !== undefined && end !== undefined) || dayPartUid !== ''));

      reset({
        calendarActivityType: calendarActivityType?.toString() ?? bluePrint.preferredType?.toString(),
        horse_uid: bluePrint.horseUid,
        daypart: dayPartUid,
        start: start,
        end: end,
        date: bluePrint.day ? formatDate(bluePrint.day) : '',
        end_date: bluePrint.day && calendarActivityType !== CalendarActivityType.Activity ? formatDate(bluePrint.day) : '',
        activity_type_uid: bluePrint.activityTypeUid,
        activitycontactrole_set: activityContactRolesBluePrint,
        stable_uid: bluePrint.stableUid,
        text: bluePrint.taskOrMessage,
        color: '#facc15', // Yellow from dailyNoteColorsTypeOptions
      });
    } else {
      let dateStr: string | undefined;
      if (viewType === ViewType.Day) {
        const day = calendar?.current.days[0] ?? new Date();
        dateStr = formatDate(day);
      }
      reset({ date: dateStr });
    }
  }, [bluePrint, selectedActivity, reset, dayParts, calendar, viewType, roles, activityContactRolesBluePrint, calendarActivityType]);

  /**
   * Detect changes in the (compact) modal when in BluePrint mode. Copy the changes into the blue print.
   * Now when a user sets i.e. the activity type to 'hoefsmit' in the compact modal, the activity type
   * will also be set in the large modal when the user clicks the 'More options' button.
   */
  useEffect(() => {
    const subscription = watch(data => {
      if (!bluePrint || !compact) {
        return;
      }
      const copyBluePrint = { ...bluePrint };
      let changed = false;

      // Activity type update
      if (data.activity_type_uid && copyBluePrint.activityTypeUid !== data.activity_type_uid) {
        copyBluePrint.activityTypeUid = data.activity_type_uid;
        changed = true;
      }
      // Task or message
      if (data.text && copyBluePrint.taskOrMessage !== data.text) {
        copyBluePrint.taskOrMessage = data.text;
        changed = true;
      }
      // Horse
      if (data.horse_uid && copyBluePrint.horseUid !== data.horse_uid) {
        copyBluePrint.horseUid = data.horse_uid;
        changed = true;
      }
      // Stable
      if (copyBluePrint.stableUid !== data.stable_uid) {
        copyBluePrint.stableUid = data.stable_uid;
        changed = true;
      }

      if (changed) {
        requestBluePrint(copyBluePrint);
      }
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, bluePrint, requestBluePrint, compact]);

  const { date: dateLabelString, time: timeLabelString } = dateLabel;

  return (
    <form noValidate={true} id='SaveActivityForm' onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-4'>
      <ErrorSection errors={nonFieldErrors} />
      {showCalendarActivityTypeRadio && (
        <RadioButtonGroup<SchemaType>
          name='calendarActivityType'
          required={true}
          control={control}
          options={calendarActivityTypeOptions}
          error={fieldError('calendarActivityType')}
          label={t('type', 'Type')}
          flexType='row'
        />
      )}
      {calendarActivityType !== undefined && !dateExpanded && (
        <div>
          {!compact && <p className='text-sm font-medium leading-4 text-gray-600 mb-1'>{t('date', 'Date')} *</p>}
          <div
            onClick={() => {
              // We disable expanding the date in compact view.
              if (!compact) {
                setDateExpanded(true);
              }
            }}
            className={classNames('flex', {
              'py-0.5 pr-2 hover:bg-neutral-100 rounded-lg hover:cursor-pointer group items-center transition-all': !compact,
              'border px-1 rounded-lg': !compact,
            })}
          >
            <div className='flex flex-col grow'>
              <p>{dateLabelString}</p>
              {timeLabelString && <p>{timeLabelString}</p>}
            </div>
            <div className={classNames({ hidden: compact })}>
              <Pencil />
            </div>
          </div>
        </div>
      )}
      {calendarActivityType === CalendarActivityType.Activity && (bluePrint?.appliedGroupBy?.groupBy !== GroupBy.Horse || !compact) && (
        <div className='space-y-1'>
          <HorseInputSelect
            name='horse_uid'
            label={t('horse', 'Horse')}
            required={true}
            control={control}
            horses={horseOptions ?? []}
            error={fieldError('horse_uid')}
          />
          {selectedHorse && (
            <div className='flex flex-row gap-1 items-end'>
              {!compact && (
                <span className='text-sm text-gray-500'>
                  {t('horse-is-registered-for', '{{horse_name}} is registered for', { horse_name: selectedHorse.name })}
                </span>
              )}
              <HorseUsageBadges size={BadgeSize.Small} horse={selectedHorse} />
            </div>
          )}
        </div>
      )}
      {dateExpanded && calendarActivityType === CalendarActivityType.Activity && (
        <>
          <DateInput control={control} required={true} label={t('date', 'Date')} name='date' error={fieldError('date')} />
          <RadioButtonGroup<SchemaType>
            name='daypart'
            required={true}
            control={control}
            options={dayPartsOptions}
            error={fieldError('daypart')}
            label={t('time', 'Time')}
            flexType='row'
          />
          {watchDayPart === '' && (
            <div className='flex flex-row gap-4'>
              <TextInput
                className='grow'
                label={t('start-time', 'Start time')}
                type='time'
                {...register('start', { setValueAs: transformEmptyToUndefined() })}
                error={fieldError('start')}
              />
              <TextInput
                className='grow'
                label={t('end-time', 'End time')}
                type='time'
                {...register('end', { setValueAs: transformEmptyToUndefined() })}
                error={fieldError('end')}
              />
            </div>
          )}
        </>
      )}
      {dateExpanded && calendarActivityType !== CalendarActivityType.Activity && (
        <div className='flex flex-row gap-4'>
          <DateInput required={true} control={control} label={t('start-date', 'Start date')} name='date' error={fieldError('date')} />
          <DateInput control={control} label={t('end-date', 'End date')} name='end_date' error={fieldError('end_date')} />
        </div>
      )}
      {calendarActivityType === CalendarActivityType.Activity && compact && (
        <RadioButtonGroup<SchemaType>
          name='activity_type_uid'
          required={true}
          control={control}
          options={activityTypeOptions}
          error={fieldError('activity_type_uid')}
          flexType='row'
          compact={true}
        />
      )}
      {calendarActivityType === CalendarActivityType.Activity && !compact && (
        <SelectInput
          label={t('activity', 'Activity')}
          nullable={true}
          autoFocus={true}
          required={true}
          options={activityTypeOptions}
          error={fieldError('activity_type_uid')}
          nullableValue=''
          {...register('activity_type_uid', { setValueAs: transformEmptyToUndefined() })}
        />
      )}
      {horseActivityTypeMismatch && (
        <p className='text-xs p-2 rounded bg-blue-50 text-blue-800 mr-2'>
          {t(
            'horse-activity-category-mismatch',
            '{{activity_name}} is a {{activity_category}} activity. {{horse_name}} is not registered for {{activity_category}}. When you continue, the horse will be added to {{activity_category}}.',
            {
              activity_name: selectedActivityType?.name,
              activity_category: selectedActivityType?.category ? getActivityTypeCategory(t, selectedActivityType.category) : '',
              horse_name: selectedHorse?.name,
            },
          )}
        </p>
      )}
      {selectedActivityType?.default === DefaultEnum.PREGNANCY_CHECK && (
        <SelectInput
          required={true}
          nullable={true}
          options={pregnancyCheckTermEnumOptions}
          error={fieldError('pregnancy_check_term')}
          label={t('pregnancy-check-term', 'Pregnancy check term')}
          {...register('pregnancy_check_term', { setValueAs: (val: unknown): unknown => (val === '----' ? undefined : val) })}
        />
      )}
      {calendarActivityType === CalendarActivityType.Activity && !compact && (
        <>
          {rolesForActivity.map((role, index) => (
            <div key={role.uid}>
              <input {...register(`activitycontactrole_set.${index}.role_uid`)} value={role.uid} type='hidden' />
              {get(errors, `activitycontactrole_set.[${index}].role_uid`)?.message}
              {get(errors, `activitycontactrole_set.[${index}].primary`)?.message}
              <ContactInputSelect
                name={`activitycontactrole_set.${index}.contact_uid`}
                control={control}
                contacts={contactListForRole(role)}
                label={role?.name}
                error={get(errors, `activitycontactrole_set.[${index}].contact_uid`)?.message}
              />
            </div>
          ))}
        </>
      )}
      {roleMismatchErrors.map(mismatchString => (
        <p key={mismatchString} className='text-yellow-500 text-xs'>
          <Warning className='inline' /> {mismatchString}
        </p>
      ))}
      {calendarActivityType === CalendarActivityType.Activity && !compact && (
        <TextAreaInput
          label={t('note', 'Note')}
          {...register('extra_info', { setValueAs: transformEmptyToUndefined() })}
          error={fieldError('extra_info')}
        />
      )}
      {calendarActivityType !== CalendarActivityType.Activity && (
        <>
          <SelectInput
            label={t('target', 'Target')}
            nullableLabel={t('all-staff', 'All staff')}
            nullableValue='----'
            nullable={calendarActivityType === CalendarActivityType.Message}
            required={true}
            options={stableOptions}
            error={fieldError('stable_uid')}
            {...register('stable_uid', { setValueAs: (val: unknown): unknown => (val === '----' ? undefined : val) })}
          />
          {!compact && (
            <TextInput
              label={t('title', 'Title')}
              {...register('title', { setValueAs: transformEmptyToUndefined() })}
              error={fieldError('title')}
            />
          )}
          <TextAreaInput
            label={calendarActivityType === CalendarActivityType.Message ? t('message', 'Message') : t('task', 'Task')}
            {...register('text', { setValueAs: transformEmptyToUndefined() })}
            required={true}
            error={fieldError('text')}
          />
          {!compact && (
            <SelectInput
              label={t('color', 'Color')}
              options={dailyNoteColorsTypeOptions}
              error={fieldError('color')}
              nullableValue=''
              {...register('color', { setValueAs: transformEmptyToUndefined() })}
            />
          )}
        </>
      )}
    </form>
  );
}
