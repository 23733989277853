import { Horse as HorseIcon } from '@phosphor-icons/react';
import { Horse } from 'openapi';
import { SexEnum } from 'openapi/models/SexEnum';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FilterSelectButton from 'components/Common/ListFilter/FilterSelectButton';

interface Props {
  horses: Horse[];
  horseSelected: (horse?: Horse) => void;
  selectedHorse?: Horse;
  stallionsOnly?: boolean;
  loading?: boolean;
  // this flag make sure that the text is not too long on mobile
  reduceSpaceOnMobile?: boolean;
}

// A button that show a dropdown (or modal) of the HorseSelect.
export default function HorseSelectButton({
  horses,
  horseSelected,
  stallionsOnly,
  selectedHorse,
  loading,
  reduceSpaceOnMobile,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const filtered = useMemo(() => {
    let res = horses;
    if (stallionsOnly) {
      res = horses.filter(horse => horse.sex === SexEnum._1);
    }
    return res;
  }, [horses, stallionsOnly]);

  const title = useMemo(() => {
    return stallionsOnly ? t('select-stallion', 'Select a stallion') : t('select-horse', 'Select a horse');
  }, [stallionsOnly, t]);

  const buttonText = useMemo(() => {
    return stallionsOnly ? t('choose-stallion', 'Choose a stallion') : t('choose-horse', 'Choose a horse');
  }, [stallionsOnly, t]);

  return (
    <FilterSelectButton<Horse>
      options={filtered}
      display={horse => horse.name}
      idField='uid'
      onFilter={(current, searchValue) => !!current.name.includes(searchValue)}
      onSelected={selected => horseSelected(selected)}
      selectedOption={selectedHorse}
      title={title}
      buttonText={buttonText}
      icon={<HorseIcon />}
      loading={loading}
      reduceSpaceOnMobile={reduceSpaceOnMobile}
    />
  );
}
