import { CaretRight, Horse as HorseIcon } from '@phosphor-icons/react';
import { AppRoutes } from 'AppRoutes';
import classNames from 'classnames';
import { useAccount } from 'context/AccountContext';
import { ColorEnum, Contact, HorseGroup } from 'openapi';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import Badge from 'ui/Badge';
import ColorWithName from 'ui/ColorWithName';
import {
  table,
  tableHiddenColumnLg,
  tableHiddenColumnMd,
  tableHiddenColumnSm,
  tableHiddenHeaderSm,
  tableTbody,
  tableTbodyTr,
  tableThead,
  tableTheadTdSticky,
  tableTheadTd,
} from 'ui/Const';
import { AllColors } from 'utilities/colors';
import { age, FilterHorse, gender } from 'utilities/Horse';
import { HorseUsageBadges } from './HorseUsageBadges';
import { BadgeSize } from 'ui/Badge/Badge';
import OptimizedImage from 'ui/OptimizedImage';

interface Props {
  horses: FilterHorse[];
  horseGroups?: HorseGroup[];
  contacts?: Contact[];
}

function HorseList({ horses, horseGroups, contacts }: Props): JSX.Element {
  const { t } = useTranslation();
  const { formatDate } = useAccount();

  // filter the stables from the contact list
  const stables = useMemo(() => {
    return contacts?.filter(contact => contact.stable_location_uid);
  }, [contacts]);

  const navigate = useNavigate();
  const showStableCol = useMemo((): boolean => {
    if (!stables) {
      return false;
    }
    return stables.length > 0;
  }, [stables]);

  const horseGroupName = useCallback(
    (horseGroupUid?: string) => {
      if (!horseGroupUid) return '-';
      if (!horseGroups) return '';
      return horseGroups.find(group => group.uid === horseGroupUid)?.name ?? '<unknown>';
    },
    [horseGroups],
  );

  const stableName = useCallback(
    (stableUid?: string) => {
      if (!stableUid) return '-';
      if (!stables) return '';
      return stables.find((stable: Contact) => stable.stable_location_uid === stableUid)?.business_name ?? '<unknown>';
    },
    [stables],
  );

  // Returns the index of the first item that is a search result outside the
  // filter. This can be used to display a splitter in the table of the search
  // results.
  const firstIndexOutsideFilter = useMemo((): number | undefined => {
    const index = horses.findIndex(item => item.foundOutsideFilter);
    if (index === -1) {
      return undefined;
    }
    return index;
  }, [horses]);

  return (
    <table className={table}>
      <thead className={tableThead}>
        <tr className={tableHiddenHeaderSm}>
          <td className={classNames('w-12', tableTheadTdSticky)} />
          <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('name', 'Name')}</td>
          <td className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnSm)}>{t('gender', 'Gender')}</td>
          <td className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnMd)}>{t('age', 'Age')}</td>
          <td className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnLg)}>{t('current-location', 'Current location')}</td>
          <td className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnMd)}>{t('date-of-arrival', 'Date of arrival')}</td>
          {showStableCol && <td className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnLg)}>{t('stable', 'Stable')}</td>}
          <td className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnMd)}>{t('color', 'Color')}</td>
          <td className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnLg)}>{t('UELN', 'UELN')}</td>
          <td className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnLg)}>{t('group', 'Group')}</td>
          <td className='w-10 md:hidden' />
        </tr>
      </thead>
      <tbody className={tableTbody}>
        {horses.map(({ horse }, index) => (
          <Fragment key={horse.uid}>
            {index === firstIndexOutsideFilter && (
              <tr>
                <td colSpan={3} className='text-lg md:text-xl font-medium pt-8 pb-4 px-2'>
                  {t('search-results-outside-filter', 'Found outside filter')}
                </td>
              </tr>
            )}
            <tr className={tableTbodyTr} onClick={() => navigate(generatePath(AppRoutes.HorsesDetails.path, { uid: horse.uid }))}>
              <td
                className={classNames('text-center w-12', {
                  'pl-2': horse.avatar_file,
                })}
              >
                {!horse.avatar_file && <HorseIcon size={22} weight='light' className='inline' />}
                {horse.avatar_file && (
                  <OptimizedImage className='object-cover rounded-full p-0.5 md:p-1' src={horse.avatar_file} width={64} />
                )}
              </td>
              <td>
                <div className='flex flex-col items-start'>
                  <span>{horse.name}</span> {!horse.hidden && <HorseUsageBadges size={BadgeSize.Small} iconOnly={true} horse={horse} />}{' '}
                  {horse.hidden && <Badge color={AllColors.Rose}>{t('inactive', 'Inactive')}</Badge>}
                </div>
              </td>
              <td className={tableHiddenColumnSm}>{horse.sex ? gender(horse.sex, t) : '-'}</td>
              <td className={tableHiddenColumnSm}>
                {horse.date_of_birth && (
                  <>
                    {age(horse)}
                    <span className='ml-2 text-gray-400'>
                      <span>{'('}</span>
                      {new Date(Date.parse(horse.date_of_birth)).getFullYear()}
                      <span className='ml-0.5'>{')'}</span>
                    </span>
                  </>
                )}
                {!horse.date_of_birth && '-'}
              </td>
              <td className={tableHiddenColumnLg}>{horse.current_location.name ?? '-'}</td>
              <td className={tableHiddenColumnMd}>
                {horse.current_location.arrival_date ? formatDate(new Date(Date.parse(horse.current_location.arrival_date))) : '-'}
              </td>
              {showStableCol && <td className={tableHiddenColumnLg}>{horse.stable_uid ? stableName(horse.stable_uid) : '-'}</td>}
              <td className={tableHiddenColumnMd}>{horse.color ? <ColorWithName colorId={horse.color as ColorEnum} /> : '-'}</td>
              <td className={tableHiddenColumnLg}>{horse.UELN || '-'}</td>
              <td className={tableHiddenColumnLg}>{horseGroupName(horse.group_uid)}</td>
              <td className={classNames('w-10 text-center md:hidden')}>
                <CaretRight size={22} weight='light' className='inline' />
              </td>
            </tr>
          </Fragment>
        ))}
      </tbody>
    </table>
  );
}
export default HorseList;
