import ApiErrorParser from 'api/ApiErrorParser';
import UploadFile from 'api/UploadFile';
import { useConfig } from 'context/ConfigContext';
import { useOrganization } from 'context/OrganizationContext';
import useFileSelect, { SelectionType } from 'hooks/UseFileSelect';
import { ApiError, HorseMedia, HorsemediaService, PrivateEnum } from 'openapi';
import { useCallback, useEffect, useState } from 'react';
import { ApiPromises } from 'utilities/ApiPromises';
import useApiPromises from './useApiPromises';
import mime from 'mime';

export interface QueuedFileUpload {
  bytes: Promise<ArrayBuffer>;
  name: string;
  contentType: string;
  privacy: PrivateEnum;
}
export interface UseHorseHookType {
  loading: boolean;
  uploading: boolean;
  apiPromises?: ApiPromises;
  files?: HorseMedia[];
  refresh: () => void;
  error?: string | ApiErrorParser<HorseMedia>;
  selectAndUploadFiles: () => void;
  deleteFile: (file: HorseMedia) => Promise<void>;
  upload: (file: QueuedFileUpload[]) => Promise<void>;
  downloadFile: (file: HorseMedia) => void;
}

interface Props {
  horseUid?: string;
  uploadFilePrivacy: PrivateEnum;
}

// Helper for horse files
const useHorseFiles = ({ horseUid, uploadFilePrivacy }: Props): UseHorseHookType => {
  const { selectedOrganization } = useOrganization();
  const [files, setFiles] = useState<HorseMedia[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const { loading } = useApiPromises({ apiPromises });
  const [uploading, setUploading] = useState<boolean>(false);
  const [error, setError] = useState<string | ApiErrorParser<HorseMedia> | undefined>();
  const { config } = useConfig();

  // Load data from the api
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();
    if (!horseUid || !selectedOrganization) {
      return promises;
    }
    promises.appendList<HorseMedia>(
      'files',
      () =>
        HorsemediaService.horsemediaList({
          horseOrganisationUid: selectedOrganization?.uid ?? '',
          horseUid,
        }),
      setFiles,
    );
    setApiPromises(promises);
    return promises;
  }, [horseUid, selectedOrganization]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganization && horseUid) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization, horseUid]); //eslint-disable-line

  const upload = useCallback(
    async (files: QueuedFileUpload[]) => {
      setUploading(true);
      setError(undefined);
      try {
        if (!horseUid) throw Error('No horse selected');
        if (!selectedOrganization) throw Error('No organization selected');
        // Required because we use vercel to upload the image.
        if (!config?.hasServerlessFunctions) throw Error('Serverless functions are not enabled.');
        for (const file of files) {
          if (!file.contentType) {
            const contentTypeFromName = mime.getType(file.name);
            if (!contentTypeFromName) {
              throw Error('Failed to get content type from filename');
            }
            file.contentType = contentTypeFromName;
          }
          // Upload the pdf to s3 via Vercel serverless function.
          const url = await UploadFile.uploadHorseFile(
            config.serverlessFunctionsUrl,
            await file.bytes,
            horseUid,
            selectedOrganization.uid,
            file.contentType,
          );

          await HorsemediaService.horsemediaCreate({
            horseOrganisationUid: selectedOrganization.uid,
            requestBody: {
              uid: '', // TODO: Remove this (https://gitlab.qubis.nl/equinem/equinemcore/-/issues/349)
              created_on: '', // TODO: Remove this (https://gitlab.qubis.nl/equinem/equinemcore/-/issues/349)
              file_download_url: '', // TODO: Remove this (https://gitlab.qubis.nl/equinem/equinemcore/-/issues/349)
              horse_uid: horseUid,
              file: url.pathname,
              filename: file.name,
              content_type: file.contentType,
              private: file.privacy,
            },
          });

          loadApiData();
          setError(undefined);
        }
      } catch (error) {
        if (error instanceof ApiError) {
          setError(new ApiErrorParser<HorseMedia>(error));
        } else {
          setError((error as Error).message);
        }
      }
      setUploading(false);
    },
    [config, horseUid, loadApiData, selectedOrganization],
  );

  const { openDialog } = useFileSelect({
    onFilesSelected: (files: File[]) => {
      upload(
        files.map(file => {
          return { bytes: file.arrayBuffer(), name: file.name, contentType: file.type, privacy: uploadFilePrivacy };
        }),
      );
    },
  });

  // Opens a file select dialog and uploads the file after select.
  const selectAndUploadFiles = () => {
    // We allow uploading a single file of any type.
    openDialog(SelectionType.Files, false, ['*/*']);
  };

  // Remove a horse media file
  const deleteFile = async (horseMedia: HorseMedia): Promise<void> => {
    if (!selectedOrganization) {
      console.error('Failed to remove file. No organization selected');
      return;
    }
    return HorsemediaService.horsemediaDestroy({ horseOrganisationUid: selectedOrganization.uid, uid: horseMedia.uid });
  };

  const downloadFile = (file: HorseMedia) => {
    // <a href="path_to_file" download="proposed_file_name">Download</a>
    // In order to start a file download we need to pull a little trick.
    // We have to create an 'a' html element with the file content attached.
    // Inspired by: https://stackoverflow.com/a/42274086
    const a = document.createElement('a');
    a.href = file.file_download_url ?? '';

    // a.download = filename(t, selectedFile);
    // We need to append the element to the dom -> otherwise it will not
    // work in firefox.
    document.body.appendChild(a);
    a.click();
    // afterwards we remove the element again.
    a.remove();
  };

  return {
    files,
    uploading,
    loading,
    error,
    apiPromises,
    refresh: loadApiData,
    selectAndUploadFiles,
    deleteFile,
    upload,
    downloadFile,
  };
};

export default useHorseFiles;
