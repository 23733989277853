import { ArrowFatLineRight, Horse } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import { AppRoutes } from 'AppRoutes';
import CareHorseForm from 'components/Horses/DetailTiles/CareHorseForm';
import GeneralHorseForm from 'components/Horses/DetailTiles/GeneralHorseForm';
import HorseAvatarForm from 'components/Horses/DetailTiles/HorseAvatarForm';
import IdentificationHorseFrom from 'components/Horses/DetailTiles/IdentificationHorseForm';
import LocationHistoryTile from 'components/Horses/DetailTiles/LocationHistoryTitle';
import MoveHorseModal from 'components/Horses/DetailTiles/LocationHistoryTitle/MoveHorseModal';
import OwnershipTile from 'components/Horses/OwnershipTile';
import PedigreeTile from 'components/Horses/DetailTiles/PedigreeTile';
import { useOrganization } from 'context/OrganizationContext';
import { PageAction, usePage } from 'context/PageContext';
import useHorseDetail from 'hooks/UseHorseDetail';
import { HorsesService, ModulePermissionsEnum } from 'openapi';
import { HorseDetail } from 'openapi/models/HorseDetail';
import React, { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import { ButtonVariant } from 'ui/Button';
import Page from 'ui/Layout/Page';
import useModal from 'ui/Modals/UseModal';
import ActivateHorseModal from 'components/Horses/ActivateHorseModal';
import IdentityHeader from 'components/Common/IdentityHeader';
import { age, gender } from 'utilities/Horse';
import { getColorFromId } from 'utilities/string.utility';
import { useConfig } from 'context/ConfigContext';
import UploadFile from 'api/UploadFile';
import FilesTile from 'components/Horses/FilesTile';
import { FileListType } from 'components/Horses/FilesTile';
import HorseTeamFormTile from 'components/Horses/DetailTiles/HorseTeamFormTile';
import usePermissions from 'hooks/UsePermissions';

function HorseDetailsPage(): JSX.Element {
  const { loadHorse, horse, setHorse, loadContacts, contacts } = useHorseDetail();
  const { config } = useConfig();
  const { selectedOrganization } = useOrganization();
  const { uid } = useParams();
  const { setApiError } = usePage();
  const { showModal: showMoveModal, closeModal: closeMoveModal, modalIsVisible: moveModalIsVisible } = useModal();
  const { showModal: showActiveModal, closeModal: closeActiveModal, modalIsVisible: activeModalIsVisible } = useModal();
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();

  /**
   * Load the horses
   */
  useEffect(() => {
    if (selectedOrganization && uid && !horse) {
      const promise = loadHorse(selectedOrganization.uid, uid);

      promise
        .then(() => setApiError(undefined))
        .catch(e => {
          if (!promise.isCancelled) {
            setApiError(new ApiErrorParser<HorseDetail>(e));
          }
        });
      return () => promise.cancel();
    }
  }, [selectedOrganization, uid]); //eslint-disable-line

  /**
   * Load the Contacts
   */
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadContacts(selectedOrganization.uid);

      promise
        .then(() => setApiError(undefined))
        .catch(() => {
          // We ignore this error as in some cases the loadHorse endpoint can return a 404 when the user has no permissions
          // but the contacts endpoint will return a 403. It could be that sometimes we show the error for the loadHorse endpoint and sometimes for the loadContacts endpoint.
          // To be consistent we ignore the error here and we show the error in the loadHorse endpoint.
        });
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  const breadCrumbs = useMemo(() => [AppRoutes.HorsesList], []);
  const actions = useMemo(
    () => {
      const actions: PageAction[] = [];

      // only manage permissions can move and reactivate horses
      if (hasPermission(ModulePermissionsEnum.MANAGE_HORSES)) {
        // Move horse button is only allowed if the horse is active
        if (!horse?.hidden) {
          actions.push({
            icon: <ArrowFatLineRight />,
            text: t('move-horse', 'Move horse'),
            buttonVariant: ButtonVariant.Primary,
            onClick: showMoveModal,
          });
        }

        // show reactive button if horse is hidden
        if (horse?.hidden) {
          actions.push({
            text: t('reactivate-horse', 'Reactivate'),
            buttonVariant: ButtonVariant.Primary,
            onClick: showActiveModal,
          });
        }
      }

      return actions;
    },
    [horse], //eslint-disable-line
  );

  const subTitle = useMemo(() => {
    if (horse?.dam && horse?.sire) {
      return `${horse.dam} × ${horse.sire}`;
    }
    if (horse?.sex) {
      return gender(horse.sex, t);
    }
    if (horse?.color) {
      return getColorFromId(horse.color, t);
    }
    if (horse?.date_of_birth) {
      return t('years-old', '{{years}} years old', { years: age(horse) });
    }
    return '';
  }, [horse, t]);

  const onAvatarSave = async (imageData: ArrayBuffer, mimeType: string) => {
    if (!horse) throw Error('No horse is set.');
    if (!selectedOrganization) throw Error('No organization is set.');

    // Required because we use vercel to upload the image.
    if (!config?.hasServerlessFunctions) throw Error('Serverless functions are not enabled.');

    // Upload the image to s3 via Vercel serverless function.
    const url = await UploadFile.uploadHorseAvatar(config.serverlessFunctionsUrl, imageData, horse.uid, selectedOrganization.uid, mimeType);

    // Patch the horse with the avatar image s3 url.
    const updateHorsePromise = HorsesService.horsesPartialUpdate({
      organisationUid: selectedOrganization.uid,
      uid: horse.uid,
      requestBody: { avatar_file: url.toString() },
    });

    setHorse(await updateHorsePromise);
  };

  return (
    <Page
      title={horse?.name ?? ''}
      breadCrumbs={breadCrumbs}
      actions={actions}
      mobileHeaderComponent={
        <IdentityHeader
          title={horse?.name ?? ''}
          subTitle={subTitle}
          avatarUrl={horse?.avatar_file ?? undefined}
          icon={<Horse />}
          onAvatarEditRequest={onAvatarSave}
        />
      }
    >
      <div className='space-y-4 pt-2 md:pt-2'>
        <div className='flex flex-col-reverse lg:flex-row gap-4'>
          <GeneralHorseForm />
          <HorseAvatarForm className='lg:w-[400px] hidden md:block' onAvatarEditRequest={onAvatarSave} />
        </div>
        <div className='flex gap-4 flex-col md:flex-row'>
          <div className='space-y-4 w-full md:w-1/2'>
            <IdentificationHorseFrom />
            <PedigreeTile pedigreePageUrl={generatePath(AppRoutes.HorsesPedigree.path, { uid: uid })} />
            <LocationHistoryTile />
            <OwnershipTile />
          </div>
          <div className='space-y-4 w-full md:w-1/2'>
            <FilesTile horseUid={uid} fileListType={FileListType.General} />
            <CareHorseForm />
            <FilesTile horseUid={uid} fileListType={FileListType.Medical} />
            <HorseTeamFormTile />
          </div>
        </div>
      </div>

      {selectedOrganization && horse && (
        <>
          <MoveHorseModal
            horse={horse}
            onRequestCloseModal={closeMoveModal}
            isVisible={moveModalIsVisible}
            contacts={contacts}
            onMoved={() => loadHorse(selectedOrganization.uid, horse.uid)}
            onContactSaved={() => loadContacts(selectedOrganization.uid)}
          />

          <ActivateHorseModal
            onRequestCloseModal={closeActiveModal}
            isVisible={activeModalIsVisible}
            horseUid={horse.uid}
            onActivated={() => loadHorse(selectedOrganization.uid, horse.uid)}
          />
        </>
      )}
    </Page>
  );
}

export default memo(HorseDetailsPage);
