import { zodResolver } from '@hookform/resolvers/zod';
import { Horse as HorseIcon, MapPinArea, X } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import classNames from 'classnames';
import { useOrganization } from 'context/OrganizationContext';
import {
  Contact,
  HorseGroup,
  HorseLocationMove,
  HorsegroupsService,
  HorsesService,
  ModulePermissionsEnum,
  Stable,
  StablesService,
} from 'openapi';
import { HorseDetail } from 'openapi/models/HorseDetail';
import { schemas } from 'openapi/zod-schemas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSize, ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { CheckboxInput, DateInput, SelectInput, TextInput } from 'ui/Inputs';
import { InputSize, OptionItemInterface } from 'ui/Inputs/SelectInput';
import { Label } from 'ui/Label';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import useModal from 'ui/Modals/UseModal';
import { transformEmptyNumber, transformEmptyToUndefined, zodInputIsRequired } from 'utilities/zod';
import { z } from 'zod';
import HorseBasicDetailsModal from './HorseBasicDetailsModal';
import TogggleInput from 'ui/Inputs/ToggleInput';
import ContactInputSelect from 'components/Contacts/ContactInputSelect';
import { colorList } from 'utilities/string.utility';
import { gender, genderList } from 'utilities/Horse';
import Fieldset from 'ui/Fieldset';
import { WrappedComboboxProps } from 'ui/Inputs/SelectList';
import { BreedingBadge, CareBadge, SportBadge } from './HorseUsageBadges';
import { ApiPromises } from 'utilities/ApiPromises';
import usePermissions from 'hooks/UsePermissions';
import { activeContacts } from 'utilities/ApiRequests';
import useApiPromises from 'api/hooks/useApiPromises';
import { useAccount } from 'context/AccountContext';

interface HorseCreate extends HorseDetail {
  move_date: HorseLocationMove['date'];
  move_destination_uid: HorseLocationMove['destination_uid'];
  move_arrival_is_import: HorseLocationMove['arrival_is_import'];
}

interface FoundHorse {
  details: HorseDetail;
  selected: boolean;
}

interface Props {
  open: boolean;
  onRequestCloseModal: () => void;
  onCreated: (newHorse: HorseDetail) => Promise<void> | void;
  defaultName?: string;
}

function CreateHorseModal({ open, onRequestCloseModal, onCreated, defaultName }: Props): JSX.Element {
  const [foundHorse, setFoundHorse] = useState<FoundHorse>();
  const [submitting, setSubmitting] = useState<boolean>();
  const [groups, setGroups] = useState<HorseGroup[]>([]);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [stables, setStables] = useState<Stable[]>([]);
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const { errorString, loading } = useApiPromises({ apiPromises, catchErrors: true });

  const { selectedOrganizationUid, generateCacheKey } = useOrganization();
  const { accountDetails } = useAccount();
  const { t } = useTranslation();
  const { closeModal: closeBasicHorseModal, modalIsVisible: basicHorseModalIsVisible, showModal: showBasicHorseModal } = useModal();
  const { hasPermission } = usePermissions();

  // convert the color list to a selectList
  const colors = useMemo((): OptionItemInterface[] => colorList(t).map<OptionItemInterface>(c => ({ id: c.value, name: c.label })), [t]);
  const genders = useMemo((): OptionItemInterface[] => genderList(t).map<OptionItemInterface>(c => ({ id: c.value, name: c.label })), [t]);

  // Form validation
  const schema = useMemo(() => {
    const fields: { [k in keyof HorseDetail]?: true } = {
      stable_uid: true,
      group_uid: true,
    };

    // include the fields that are only needed when we create a new horse
    if (!foundHorse?.selected) {
      fields.name = true;
      fields.color = true;
      fields.sex = true;
      fields.date_of_birth = true;
      fields.UELN = true;
      fields.chip_nr = true;
      fields.withers_height = true;
      fields.use_in_breeding = true;
      fields.use_in_care = true;
      fields.use_in_sport = true;
    }

    const _schema = schemas.HorseDetail.pick(fields).extend({
      move_date: z.string(),
      move_destination_uid: z.string().nullable(),
      move_arrival_is_import: z.boolean().default(false),
      stable_uid: z.string(),
    });

    // change the schema when we create a new horse
    if (!foundHorse?.selected) {
      return _schema.extend({
        // We should have a nullable type for both color and sex
        // as we are sure about the value, it is a number, we could add also a nullable validator
        color: z.number().nullable(),
        sex: z.number().nullable(),
      });
    }

    return _schema;
  }, [foundHorse]);

  const defaultValues = {
    move_date: new Date().toISOString().substring(0, 10),
    move_arrival_is_import: false,
    use_in_breeding: true,
    use_in_care: true,
    use_in_sport: true,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
    control,
  } = useForm<HorseCreate>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  // watch specific form values.
  // needed for checking if there is an inactive horse with the same values
  const nameFormValue = watch('name');
  const uelnFormValue = watch('UELN');
  const chipNrFormValue = watch('chip_nr');
  const destinationUidFormValue = watch('move_destination_uid');
  const stableUidFormValue = watch('stable_uid');

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  // Set the destinations variable
  const destinationContact = contacts?.find(contact => contact.uid === destinationUidFormValue);

  // Visibility fields
  // See https://gitlab.qubis.nl/equinem/equinemcore/-/wikis/Horse/Move-field-information
  const fieldIsImportIsVisible = !destinationContact;

  // build the option list for the select
  const groupOptions: OptionItemInterface[] = groups.map(group => ({
    id: group.uid,
    name: group.name ?? group.uid,
  }));

  // build the option list for the select
  const stableOptions: OptionItemInterface[] = stables.map(stable => ({
    id: stable.uid,
    name: stable.location.business_name,
  }));

  /**
   * Split into 2 groups
   * 1. My locations
   * 2. other contacts
   */
  const filteredContacts = useMemo((): WrappedComboboxProps<Contact>[] => {
    return [
      {
        heading: t('my-locations', 'My locations'),
        items: (contacts ?? []).filter(contact => contact.external_location || contact.stable_location_uid),
        notFoundLabel: t('no-locations-found', 'No locations found'),
        icon: <MapPinArea />,
      },
      {
        heading: t('contacts', 'Contacts'),
        items: (contacts ?? []).filter(contact => !contact.external_location && !contact.stable_location_uid),
        notFoundLabel: t('no-contacts-found', 'No contacts found'),
      },
    ];
  }, [contacts, t]);

  /**
   * Submit handler
   */
  const onSubmit = async (data: HorseCreate) => {
    if (!selectedOrganizationUid) return;

    setSubmitting(true);

    try {
      // save the newHorse so we can use it in the onCreated event
      let newHorse: HorseDetail;

      // there are 2 situations
      // 1) we have found a horse and we should set the location and group
      // 2) we have not found a horse and we should create a new one
      if (foundHorse?.selected) {
        // TODO The api is working to merge the 2 requests into 1
        // When ready, use this call instead. Most likely this will be the HorsesService.horsesMoveCreate call

        // 1) we should set the horse active and set the location
        const setLocationPromise = HorsesService.horsesMoveCreate({
          organisationUid: selectedOrganizationUid,
          uid: foundHorse.details.uid,
          requestBody: {
            set_inactive: false, // reactivate the horse
            date: data.move_date,
            destination_uid: data.move_destination_uid,
            arrival_is_import: data.move_arrival_is_import,
          },
        });
        await setLocationPromise;

        // 2) set the other data for the horse
        const setGroupPromise = HorsesService.horsesPartialUpdate({
          organisationUid: selectedOrganizationUid,
          uid: foundHorse.details.uid,
          requestBody: {
            group_uid: data.group_uid,
            sex: data.sex,
            color: data.color,
            date_of_birth: data.date_of_birth,
            use_in_breeding: data.use_in_breeding,
            use_in_care: data.use_in_care,
            use_in_sport: data.use_in_sport,
          },
        });
        newHorse = await setGroupPromise;
      } else {
        const createHorsePromise = HorsesService.horsesCreate({
          organisationUid: selectedOrganizationUid,
          requestBody: {
            stable_uid: data.stable_uid,
            name: data.name,
            group_uid: data.group_uid,
            UELN: data.UELN,
            chip_nr: data.chip_nr,
            sex: data.sex,
            color: data.color,
            date_of_birth: data.date_of_birth,
            withers_height: data.withers_height,
            use_in_breeding: data.use_in_breeding,
            use_in_care: data.use_in_care,
            use_in_sport: data.use_in_sport,
            register_move: {
              date: data.move_date,
              destination_uid: data.move_destination_uid,
              arrival_is_import: data.move_arrival_is_import,
            },
          } as HorseDetail,
        });

        newHorse = await createHorsePromise;
      }

      // fire the onCreated event
      await onCreated(newHorse);

      // close the modal
      onRequestCloseModal();
    } catch (error: unknown) {
      setApiError(new ApiErrorParser(error));
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * event that will be fired after the modal has been closed
   */
  const onClosed = () => {
    setApiError(undefined);
    setFoundHorse(undefined);
    reset(defaultValues);
  };

  /**
   * Load data from the api/cache
   */
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();

    if (!selectedOrganizationUid) {
      return promises;
    }

    // Load all contacts, including the removed once.
    // fall back on the horseContactsList endpoint if the user does not have the permission to view all contacts
    if (hasPermission(ModulePermissionsEnum.VIEW_CONTACTS)) {
      promises.appendListObj<Contact>('contacts', setContacts, activeContacts(selectedOrganizationUid, generateCacheKey));
    } else {
      promises.appendList<Contact>(
        'contacts',
        () =>
          HorsesService.horsesContactsList({
            organisationUid: selectedOrganizationUid,
          }),
        setContacts,
        generateCacheKey('horse-contacts'),
      );
    }

    promises.appendList<HorseGroup>(
      'horse-groups',
      () =>
        HorsegroupsService.horsegroupsList({
          organisationUid: selectedOrganizationUid,
        }),
      setGroups,
      generateCacheKey('horse-groups'),
    );

    promises.appendList<Stable>(
      'stables',
      () =>
        StablesService.stablesList({
          organisationUid: selectedOrganizationUid,
        }),
      setStables,
      generateCacheKey('stables'),
    );

    setApiPromises(promises);
    return promises;
  }, [generateCacheKey, hasPermission, selectedOrganizationUid]);

  /**
   * Toggle a foundHorse as selected or not
   */
  const toggleSelectedHorse = (selected: boolean) => {
    setFoundHorse(prevState => (prevState ? { ...prevState, selected } : undefined));
  };

  /**
   * Initial load when the modal openend
   */
  useEffect(() => {
    if (!open) return;
    if (!selectedOrganizationUid) return;

    const promise = loadApiData();
    return () => promise.cancel();
  }, [loadApiData, open, selectedOrganizationUid]);

  /**
   * in case we just have one value for Stables, Locations or Groups
   * we can use that first value and hide the form field.
   */
  useEffect(() => {
    if (stables.length === 1) {
      setValue('stable_uid', stables[0].uid);
    }
    // one exception; for the location, if there are more than 1
    // we select the first stable
    if (contacts.length === 1) {
      setValue('move_destination_uid', contacts[0].uid);
    } else {
      // check if the user has a preferred stable
      // if not fallback to the first stable
      let stable = contacts.find(contact => contact.stable_location_uid === null);
      if (accountDetails?.preferred_stable) {
        stable = contacts.find(contact => accountDetails?.preferred_stable === contact.stable_location_uid);
      }

      if (stable) {
        setValue('move_destination_uid', stable.uid);
        setValue('stable_uid', stable.stable_location_uid);
      }
    }
    if (groups.length === 1) {
      setValue('group_uid', groups[0].uid);
    }
  }, [groups, contacts, setValue, stables, accountDetails?.preferred_stable]);

  /**
   * If we change the stable_uid, we should also set the move_destination_uid as the stable_uid
   */
  useEffect(() => {
    // stableUidFormValue
    if (!stableUidFormValue) return;
    const stable = contacts.find(contact => contact.stable_location_uid === stableUidFormValue);

    if (stable) {
      setValue('move_destination_uid', stable.uid);
    }
  }, [contacts, setValue, stableUidFormValue, stables]);

  /**
   * check if the user either has given a name && ueln OR name && chipNr
   * We debounce  so we don't make api calls on every keystroke
   */
  useEffect(() => {
    if (!selectedOrganizationUid) return;

    // use a timeout so we can debounced our keystroke
    const timer = setTimeout(() => {
      let params:
        | {
            uelnIexact?: string;
            chipNrIexact?: string;
            nameIcontains?: string;
          }
        | undefined = undefined;

      // check if we need to set name and UELN or name and chip_nr
      if (nameFormValue && uelnFormValue) {
        params = {
          nameIcontains: nameFormValue,
          uelnIexact: uelnFormValue,
        };
      } else if (nameFormValue && chipNrFormValue) {
        params = {
          nameIcontains: nameFormValue,
          chipNrIexact: chipNrFormValue,
        };
      }

      // if we have set the params for the request, check if the horse can be found
      if (params) {
        const promise = HorsesService.horsesList({
          organisationUid: selectedOrganizationUid,
          hidden: true,
          onUnknownLocation: false,
          ...params,
        });
        promise
          .then(res => {
            if (res.results?.length) {
              const found = res.results[0];
              HorsesService.horsesRetrieve({ organisationUid: selectedOrganizationUid, uid: found.uid })
                .then(details => {
                  setFoundHorse({ details, selected: false });
                })
                .catch(error => console.error(error));
            } else {
              setFoundHorse(undefined);
            }
          })
          .catch(error => console.error(error));
      } else {
        setFoundHorse(undefined);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [nameFormValue, uelnFormValue, chipNrFormValue, selectedOrganizationUid]);

  /**
   * reset the move_arrival_is_import value once the destinationContact has been set
   */
  useEffect(() => {
    if (destinationContact) {
      setValue('move_arrival_is_import', undefined);
    }
  }, [destinationContact, setValue]);

  /**
   * set the defaultName value to the name input
   */
  useEffect(() => {
    setValue('name', defaultName ?? '');
  }, [defaultName, setValue]);

  /**
   * Set the preferred stable if the user has one
   */
  useEffect(() => {
    if (accountDetails?.preferred_stable) {
      setValue('stable_uid', accountDetails?.preferred_stable);
    }
  }, [accountDetails?.preferred_stable, setValue]);

  /**
   * Set the group to the first default_id
   */
  useEffect(() => {
    const defaultGroup = groups.find(group => group.default_id === 1);
    if (defaultGroup) {
      setValue('group_uid', defaultGroup?.uid);
    }
  }, [groups, setValue]);

  return (
    <>
      <PageModal
        open={open}
        onClosed={onClosed}
        parentElement='form'
        parentProps={{ id: 'addHorseForm', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      >
        <PageModalTitle title={t('add-horse', 'Add horse')} onClose={onRequestCloseModal} />
        <PageModalContent>
          {/* api load errors */}
          {errorString && <ErrorSection errors={errorString} />}

          {/* Form errors */}
          <ErrorSection className='mb-4' errors={nonFieldErrors} />

          <div className='space-y-4'>
            <div className='grid md:grid-cols-2 gap-4'>
              <div className='flex flex-col gap-4'>
                {!foundHorse?.selected && (
                  <>
                    <TextInput
                      error={fieldError('name')}
                      required={zodInputIsRequired<HorseDetail>(schema, 'name')}
                      label={t('name', 'Name')}
                      {...register('name', { setValueAs: transformEmptyToUndefined() })}
                    />
                    <TextInput
                      error={fieldError('UELN')}
                      required={zodInputIsRequired<HorseDetail>(schema, 'UELN')}
                      label={t('horse_ueln', 'UELN')}
                      {...register('UELN', { setValueAs: transformEmptyToUndefined() })}
                    />
                    <TextInput
                      // Chrome does not respect the autocomplete="off" attribute on the input field.
                      // So in this case Chrome think that chip number (translated in NL to chipnummer) is a cc field (match to trigger the cc autofill is the label name "*nummer")
                      // To prevent Chrome from autocomplete as CC we e use the "bday-day" autocomplete. bday-day is a numeric field (chip number is also numeric)
                      // @see https://issues.chromium.org/issues/41163264#comment165
                      // @see https://issues.chromium.org/issues/339033226
                      // @see https://gitlab.qubis.nl/equinem/equiapp/-/issues/202
                      autoComplete='bday-day'
                      error={fieldError('chip_nr')}
                      required={zodInputIsRequired<HorseDetail>(schema, 'chip_nr')}
                      label={t('horse_chip_nr', 'Chip number')}
                      {...register('chip_nr', { setValueAs: transformEmptyToUndefined() })}
                    />
                    {!foundHorse?.selected && (
                      <SelectInput
                        error={fieldError('sex')}
                        required={zodInputIsRequired<HorseDetail>(schema, 'sex')}
                        options={genders}
                        nullable={true}
                        nullableValue=''
                        label={t('gender', 'Gender')}
                        {...register('sex', { setValueAs: transformEmptyNumber(null) })}
                      />
                    )}
                    <SelectInput
                      className={classNames({
                        hidden: groups.length === 1,
                      })}
                      loading={loading}
                      error={fieldError('group_uid')}
                      nullable={true}
                      nullableValue=''
                      required={zodInputIsRequired<HorseDetail>(schema, 'group_uid')}
                      options={groupOptions}
                      label={t('group', 'Group')}
                      {...register('group_uid', { setValueAs: transformEmptyToUndefined() })}
                    />
                  </>
                )}
                {foundHorse && (
                  <div>
                    {!foundHorse.selected && <Label>{t('existing_horse', 'Existing Horse')}</Label>}
                    {foundHorse.selected && <Label>{t('horse', 'Horse')}</Label>}

                    <div
                      className={classNames('mt-1 border rounded-md p-3 text-gray-500 font-medium text-sm relative', {
                        'border-indigo-500 bg-indigo-50': !foundHorse.selected,
                        'border-green-500 bg-green-50': foundHorse.selected,
                      })}
                    >
                      {foundHorse.selected && (
                        <button type='button' className='absolute right-2 top-2' onClick={() => toggleSelectedHorse(false)}>
                          <X size={20} />
                        </button>
                      )}

                      <div className='space-y-3'>
                        {!foundHorse.selected && (
                          <>
                            <p>{t('existing_horse_title', 'We have found an inactive horse with the same identification.')}</p>
                            <p>{t('existing_horse_sub_title', 'Do you want to use existing information?')}</p>
                          </>
                        )}

                        {foundHorse.selected && <p>{t('existing_horse_selected_title', 'You have selected the following horse')}</p>}

                        <div className='flex'>
                          <div className='flex-1 flex gap-3'>
                            <div className='w-16 h-16 rounded-lg overflow-hidden relative bg-gray-400 flex items-center justify-center shrink-0'>
                              {foundHorse.details.avatar_file && <img className='w-full h-full' src={foundHorse.details.avatar_file} />}
                              {!foundHorse.details.avatar_file && <HorseIcon width={35} height={35} className='text-gray-50' />}
                            </div>
                            <div className='flex flex-col'>
                              <span className='text-gray-700 font-semibold'>
                                {foundHorse.details.display_name}{' '}
                                {foundHorse.details.sex ? <span className='font-light'>({gender(foundHorse.details.sex, t)})</span> : ''}
                              </span>
                              <span>
                                {t('EULN', 'EULN')}
                                {':'} {foundHorse.details.UELN}
                              </span>
                              <span>
                                {t('chip_nr', 'Chip Nr')}
                                {':'} {foundHorse.details.chip_nr}
                              </span>
                            </div>
                          </div>
                          {!foundHorse.selected && (
                            <div className='flex space-x-2'>
                              <Button
                                size={ButtonSize.XSmall}
                                onClick={() => toggleSelectedHorse(true)}
                                variant={ButtonVariant.Primary}
                                type='button'
                              >
                                {t('use', 'Use')}
                              </Button>
                              <Button variant={ButtonVariant.Default} onClick={showBasicHorseModal} size={ButtonSize.XSmall} type='button'>
                                {t('view', 'View')}
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className='flex flex-col gap-4'>
                <DateInput
                  control={control}
                  error={fieldError('move_date')}
                  required={true}
                  label={t('date_of_arrival', 'Date of arrival')}
                  name='move_date'
                />

                <SelectInput
                  className={classNames({
                    hidden: stables.length === 1,
                  })}
                  loading={loading}
                  error={fieldError('stable_uid')}
                  required={zodInputIsRequired<HorseDetail>(schema, 'stable_uid')}
                  nullable={true}
                  nullableValue=''
                  options={stableOptions}
                  label={t('stable', 'Stable')}
                  {...register('stable_uid', { setValueAs: transformEmptyToUndefined() })}
                />

                <ContactInputSelect
                  className={classNames({
                    hidden: contacts.length === 1,
                  })}
                  loading={loading}
                  name='move_destination_uid'
                  control={control}
                  contacts={filteredContacts}
                  onCreated={newContact => {
                    setContacts(prevState => [...prevState, newContact]); // Update the list of contacts.
                    setValue('move_destination_uid', newContact.uid);
                  }}
                  required={true}
                  label={t('location', 'Location')}
                  error={fieldError('move_destination_uid')}
                />

                <TogggleInput
                  className={classNames({
                    hidden: !fieldIsImportIsVisible,
                  })}
                  name='move_arrival_is_import'
                  control={control}
                  error={fieldError('move_arrival_is_import')}
                  label={t('is-imported', 'Is imported')}
                  hint={t('is-imported-description', 'Is this horse imported from a different country?')}
                />

                <div>
                  <p className='block text-sm font-medium leading-4 text-gray-600 mb-2'>{t('horse-usage', 'Usage')}</p>
                  <CheckboxInput
                    size={InputSize.XSmall}
                    {...register('use_in_care')}
                    error={fieldError('use_in_care')}
                    labelElement={
                      <div className='flex items-center gap-1'>
                        {t('use-for', 'Use for')} <CareBadge />
                      </div>
                    }
                  />
                  <CheckboxInput
                    size={InputSize.XSmall}
                    {...register('use_in_sport')}
                    error={fieldError('use_in_sport')}
                    labelElement={
                      <div className='flex items-center gap-1'>
                        {t('use-for', 'Use for')} <SportBadge />
                      </div>
                    }
                  />
                  <CheckboxInput
                    size={InputSize.XSmall}
                    {...register('use_in_breeding')}
                    error={fieldError('use_in_breeding')}
                    labelElement={
                      <div className='flex items-center gap-1'>
                        {t('use-for', 'Use for')} <BreedingBadge />
                      </div>
                    }
                  />
                </div>
              </div>
            </div>

            {!foundHorse?.selected && (
              <Fieldset
                canToggle={true}
                legend={t('additional-horse-details', 'Additional horse details')}
                classNameWrapper='!mt-10'
                className='space-y-4'
              >
                {isOpen => (
                  <>
                    <SelectInput
                      tabIndex={isOpen ? undefined : -1} // enable tabIndex for our fieldset when its open
                      error={fieldError('color')}
                      required={zodInputIsRequired<HorseDetail>(schema, 'color')}
                      options={colors}
                      nullable={true}
                      nullableValue=''
                      label={t('color', 'Color')}
                      {...register('color', { setValueAs: transformEmptyNumber(null) })}
                    />

                    <TextInput
                      tabIndex={isOpen ? undefined : -1} // enable tabIndex for our fieldset when its open
                      postText='cm'
                      error={fieldError('withers_height')}
                      required={zodInputIsRequired<HorseDetail>(schema, 'withers_height')}
                      label={t('withers-height', 'Withers height')}
                      {...register('withers_height', { setValueAs: transformEmptyNumber(null) })}
                    />

                    <DateInput
                      tabIndex={isOpen ? undefined : -1} // enable tabIndex for our fieldset when its open
                      control={control}
                      error={fieldError('date_of_birth')}
                      required={zodInputIsRequired<HorseDetail>(schema, 'date_of_birth')}
                      label={t('date_of_birth', 'Date of birth')}
                      {...register('date_of_birth', { setValueAs: transformEmptyToUndefined() })}
                    />
                  </>
                )}
              </Fieldset>
            )}
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              disabled: loading || errorString !== undefined,
              loading: submitting,
              variant: ButtonVariant.Primary,
              text: t('save', 'Save'),
              type: 'submit',
              formId: 'addHorseForm',
            },
          ]}
        />
      </PageModal>

      {foundHorse && (
        <HorseBasicDetailsModal open={basicHorseModalIsVisible} closeModal={closeBasicHorseModal} horse={foundHorse.details} />
      )}
    </>
  );
}

export default CreateHorseModal;
