/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JWT } from '../models/JWT';
import type { Plan } from '../models/Plan';
import type { SocialLogin } from '../models/SocialLogin';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ApiService {
  /**
   * For example (for testing):
   * https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?response_type=code&client_id=cf028e96-8736-4aaa-bb45-1977d4e24634&redirect_uri=https://app.equinem.com/microsoft/login/callback&scope=User.ReadBasic.All+openid+offline_access&code_challenge_method:S256&code_challenge=nA04yKkP7OWo5OimF8PT-cELJZ5TmjC4W_85JYY4aCQ
   *
   *
   * Code challenge can be generated here: https://example-app.com/pkce
   * @returns JWT
   * @throws ApiError
   */
  public static apiV5OauthUserMicrosoftLoginCreate({
    requestBody,
  }: {
    requestBody?: SocialLogin,
  }): CancelablePromise<JWT> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/oauthUser/microsoft/login',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns Plan
   * @throws ApiError
   */
  public static apiV5PlansList({
    isGeneric,
    module,
    moduleIn,
    o,
    serviceType,
    serviceTypeIn,
  }: {
    isGeneric?: boolean,
    /**
     * Required when the plan should restrict access to active service contracts.
     *
     * * `HORSES` - Horses
     * * `BREEDING` - Breeding
     * * `SHOP` - Shop
     * * `ONLINE_PAYMENTS` - Online payments
     * * `FINANCIAL` - Financial
     * * `CONTACTS` - Contacts
     * * `ACTIVITIES` - Activities
     * * `FEED` - Feed
     * * `USER_ACCOUNTS` - User accounts
     * * `CARE_AND_BREEDING` - Care and breeding
     * * `TRAINING_AND_COMPETITION` - Training and competition
     * * `RVO_REPORTS` - RvO reports
     */
    module?: 'ACTIVITIES' | 'BREEDING' | 'CARE_AND_BREEDING' | 'CONTACTS' | 'FEED' | 'FINANCIAL' | 'HORSES' | 'ONLINE_PAYMENTS' | 'RVO_REPORTS' | 'SHOP' | 'TRAINING_AND_COMPETITION' | 'USER_ACCOUNTS',
    /**
     * Multiple values may be separated by commas.
     */
    moduleIn?: Array<string>,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    /**
     * * `FIXED_SERVICE` - fixed service
     * * `TIERED_SERVICE` - tiered service
     * * `METERED_SERVICE` - metered service
     */
    serviceType?: 'FIXED_SERVICE' | 'METERED_SERVICE' | 'TIERED_SERVICE',
    /**
     * Multiple values may be separated by commas.
     */
    serviceTypeIn?: Array<string>,
  }): CancelablePromise<Array<Plan>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/plans',
      query: {
        'is_generic': isGeneric,
        'module': module,
        'module__in': moduleIn,
        'o': o,
        'service_type': serviceType,
        'service_type__in': serviceTypeIn,
      },
    });
  }
}
