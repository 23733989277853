import React, { createContext, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  activityTypeColor,
  Assignee,
  BluePrint,
  CalendarActivity,
  CalendarActivityType,
  DragActivity,
  GroupBy,
  Reshaping,
  SelectedActivity,
  SelectedActivityState,
  TimeScale,
  ViewType,
  WorkingHours,
} from 'utilities/Planning';
import { ActivityType, Contact, DailyNote, DayPartStartTime, Horse, HorseGroup, RealActivities, Role, Stable } from 'openapi';
import { ApiPromises } from 'utilities/ApiPromises';
import { useAccount } from './AccountContext';
import { addDays, isSameDay } from 'date-fns';
import { GroupByApplied } from './Calendar';
import { FALLBACK_ACTIVITY_PRIMARY_COLOR, FALLBACK_ACTIVITY_SECONDARY_COLOR } from 'ui/Const';

interface Props {
  children: React.ReactNode;
  viewType?: ViewType;
  timeScale?: TimeScale;
  groupBy?: GroupBy;
}

type PlanningContextType = {
  viewType: ViewType;
  timeScale: TimeScale;
  groupBy: GroupBy;
  apiPromises: ApiPromises | undefined;
  activities: RealActivities[] | undefined;
  dailyNotes: DailyNote[] | undefined;
  dayParts: DayPartStartTime[] | undefined;
  horses: Horse[] | undefined;
  contacts: Contact[] | undefined;
  activityTypes: ActivityType[] | undefined;
  groups: HorseGroup[] | undefined;
  stables: Stable[] | undefined;
  roles: Role[] | undefined;
  lastUsedActivityTypeUid: string | undefined;
  historicOffset: { current: number; previous?: number };
  setViewType: (view: ViewType) => void;
  setTimeScale: (time: TimeScale) => void;
  setGroupBy: (group: GroupBy) => void;
  setApiPromises: (api: ApiPromises) => void;
  setActivities: Dispatch<SetStateAction<RealActivities[] | undefined>> | Dispatch<SetStateAction<RealActivities[]>>;
  setDailyNotes: Dispatch<SetStateAction<DailyNote[] | undefined>> | Dispatch<SetStateAction<DailyNote[]>>;
  setDayParts: Dispatch<SetStateAction<DayPartStartTime[] | undefined>> | Dispatch<SetStateAction<DayPartStartTime[]>>;
  setHorses: Dispatch<SetStateAction<Horse[] | undefined>> | Dispatch<SetStateAction<Horse[]>>;
  setContacts: Dispatch<SetStateAction<Contact[] | undefined>> | Dispatch<SetStateAction<Contact[]>>;
  setActivityTypes: Dispatch<SetStateAction<ActivityType[] | undefined>> | Dispatch<SetStateAction<ActivityType[]>>;
  setGroups: Dispatch<SetStateAction<HorseGroup[] | undefined>> | Dispatch<SetStateAction<HorseGroup[]>>;
  setStables: Dispatch<SetStateAction<Stable[] | undefined>> | Dispatch<SetStateAction<Stable[]>>;
  setRoles: Dispatch<SetStateAction<Role[] | undefined>> | Dispatch<SetStateAction<Role[]>>;
  setLastUsedActivityTypeUid: (activityTypeUid: string) => void;
  setHistoricOffset: (offset: { current: number; previous?: number }) => void;
  processedActivities: CalendarActivity[] | undefined;
  bluePrint: BluePrint | undefined;
  reshaping: Reshaping | undefined;
  requestBluePrint: (bluePrint?: BluePrint) => void;
  requestReshaping: (reshaping?: Reshaping) => void;

  /**
   * Set a selected activity
   *
   * @param groupByUid In the Staff planning, the same activity can be displayed more than once when we assign multiple
   * contacts to it. Therefor we can specify for which contact the activity is selected.
   */
  setSelectedActivity: (activity: CalendarActivity, state: SelectedActivityState, groupByUid?: string) => void;
  clearSelectedActivity: () => void;
  selectedActivity: SelectedActivity | undefined;

  setDragActivity: (activity?: DragActivity) => void;
  dragActivity: DragActivity | undefined;

  // Users can click on a specific horse/contact. It will be expanded into a 24h time view.
  setSelectedGroupBy: (appliedGroupBy?: GroupByApplied) => void;
  selectedGroupBy: GroupByApplied | undefined;

  setModalError: (errorMessage: string | undefined) => void;
  modalError: string | undefined;

  // The working hours so we can hide the nightly hours in our timescale view.
  workingHours: WorkingHours;
  hideNonWorkingHours: boolean;
  setHideNonWorkingHours: (hide: boolean) => void;
};

export const PlanningContext = createContext<PlanningContextType>({
  viewType: ViewType.Week,
  timeScale: TimeScale.DayParts,
  groupBy: GroupBy.Horse,
  apiPromises: undefined,
  activities: undefined,
  dailyNotes: undefined,
  dayParts: undefined,
  horses: undefined,
  contacts: undefined,
  activityTypes: undefined,
  groups: undefined,
  stables: undefined,
  roles: undefined,
  lastUsedActivityTypeUid: undefined,
  historicOffset: { current: 0 },
  processedActivities: undefined,
  bluePrint: undefined,
  reshaping: undefined,
  selectedActivity: undefined,
  selectedGroupBy: undefined,
  dragActivity: undefined,
  modalError: undefined,
  workingHours: { from: [0, 0], to: [0, 0] },
  hideNonWorkingHours: false,
  setViewType: () => console.warn('no PlanningContext provider'),
  setTimeScale: () => console.warn('no PlanningContext provider'),
  setGroupBy: () => console.warn('no PlanningContext provider'),
  setApiPromises: () => console.warn('no PlanningContext provider'),
  setActivities: () => console.warn('no PlanningContext provider'),
  setDailyNotes: () => console.warn('no PlanningContext provider'),
  setDayParts: () => console.warn('no PlanningContext provider'),
  setHorses: () => console.warn('no PlanningContext provider'),
  setContacts: () => console.warn('no PlanningContext provider'),
  setActivityTypes: () => console.warn('no PlanningContext provider'),
  setGroups: () => console.warn('no PlanningContext provider'),
  setStables: () => console.warn('no PlanningContext provider'),
  setRoles: () => console.warn('no PlanningContext provider'),
  setLastUsedActivityTypeUid: () => console.warn('no PlanningContext provider'),
  setHistoricOffset: () => console.warn('no PlanningContext provider'),
  requestBluePrint: () => console.warn('no PlanningContext provider'),
  requestReshaping: () => console.warn('no PlanningContext provider'),
  setSelectedActivity: () => console.warn('no PlanningContext provider'),
  clearSelectedActivity: () => console.warn('no PlanningContext provider'),
  setSelectedGroupBy: () => console.warn('no PlanningContext provider'),
  setDragActivity: () => console.warn('no PlanningContext provider'),
  setModalError: () => console.warn('no PlanningContext provider'),
  setHideNonWorkingHours: () => console.warn('no PlanningContext provider'),
});

export function PlanningProvider({
  children,
  viewType: initialViewType,
  timeScale: initialTimeScale,
  groupBy: initialGroupBy,
}: Props): JSX.Element {
  const { formatTime, formatDateIntl } = useAccount();

  const [viewType, setViewType] = useState<ViewType>(initialViewType ?? ViewType.Week);
  const [timeScale, setTimeScale] = useState<TimeScale>(initialTimeScale ?? TimeScale.TimeScale);
  const [groupBy, setGroupBy] = useState<GroupBy>(initialGroupBy ?? GroupBy.None);
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const [activities, setActivities] = useState<RealActivities[] | undefined>();
  const [dailyNotes, setDailyNotes] = useState<DailyNote[] | undefined>();
  const [dayParts, setDayParts] = useState<DayPartStartTime[]>();
  const [horses, setHorses] = useState<Horse[]>();
  const [contacts, setContacts] = useState<Contact[]>();
  const [activityTypes, setActivityTypes] = useState<ActivityType[]>();
  const [groups, setGroups] = useState<HorseGroup[]>();
  const [stables, setStables] = useState<Stable[]>();
  const [roles, setRoles] = useState<Role[]>();
  // We keep track of which activity type is last used so we can select it by default when quickly adding new activities.
  const [lastUsedActivityTypeUid, setLastUsedActivityTypeUid] = useState<string | undefined>();

  // The view offset (0 is today). It also keeps track of the previous offset.
  const [historicOffset, setHistoricOffset] = useState<{ current: number; previous?: number }>({ current: 0 });

  // When a user clicks and drags in calendar view a blueprint is constructed. This is used to show in the gui and create an Activity from.
  const [bluePrint, setBluePrint] = useState<BluePrint | undefined>();

  // This object is set when we're making an acivity/blueprint smaller or larger based on dragging
  const [reshaping, setReshaping] = useState<Reshaping | undefined>();

  // The selected activity in the calendar
  const [selectedActivity, setSelectedActivity] = useState<SelectedActivity | undefined>();

  // This activity is set while dragging.
  const [dragActivity, setDragActivity] = useState<DragActivity | undefined>();

  // A contact/horse/etc which is selected in the planning (by clicking on it). The calender will show a detailed 24h view of this contact/horse/etc.
  const [selectedGroupBy, setSelectedGroupBy] = useState<GroupByApplied | undefined>();

  // Use this to display an error message to the user in an action modal.
  const [modalError, setModalError] = useState<string | undefined>();

  // The working hours so we can hide the nightly hours in our timescale view.
  // Default is set to 6AM to 8PM for now.
  const [workingHours] = useState<WorkingHours>({ from: [6, 0], to: [20, 0] });
  const [hideNonWorkingHours, setHideNonWorkingHours] = useState<boolean>(true);

  // Transform the RealActivities and DailyNotes into usable CalendarActivities the we can use in the frontend.
  const processedActivities = useMemo((): CalendarActivity[] | undefined => {
    const dayPartStartDate = (day: Date, dayPart: DayPartStartTime): Date => {
      const timeParts = dayPart.start_time.split(':');
      return new Date(day.getFullYear(), day.getMonth(), day.getDate(), Number(timeParts[0]), Number(timeParts[1]));
    };

    const getDateTimeFromDayPart = (day: Date, part: number, pos: 'start' | 'end'): Date => {
      const dayPart: DayPartStartTime | undefined = dayParts ? dayParts[part] : undefined;
      if (pos === 'start') {
        const dayStart = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 0, 0);
        return dayPart ? dayPartStartDate(day, dayPart) : dayStart;
      } else {
        const dayPartNext: DayPartStartTime | undefined = dayParts ? dayParts[part + 1] : undefined;
        const dayEnd = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 23, 59);
        return dayPartNext ? dayPartStartDate(day, dayPartNext) : dayEnd;
      }
    };

    if (!activities || !horses || !activityTypes || !dailyNotes) {
      return undefined;
    }

    // Transform the RealActivities into usable CalendarActivities the we can use in the frontend.
    const processedActivities: CalendarActivity[] = activities.map(activity => {
      const dayPartIndex = activity.daypart ? activity.daypart - 1 : 0;
      let startTime: Date | undefined = undefined;
      let endTime: Date | undefined = undefined;
      let startEndText = '';
      if (activity.start.datetime) {
        startTime = new Date(activity.start.datetime ?? '');
        endTime = new Date(activity.end.datetime ?? '');
        startEndText = `${formatTime(startTime)} - ${formatTime(endTime)}`;
      } else {
        const date = new Date(activity.start.date ?? '');
        startTime = getDateTimeFromDayPart(date, dayPartIndex, 'start');
        endTime = getDateTimeFromDayPart(date, dayPartIndex, 'end');
        const dayPart = dayParts?.[dayPartIndex];
        startEndText = dayPart?.name ?? 'Unknown day part';
      }

      const assignedTo = (activity.activitycontactrole_set ?? []).map((contactRole): Assignee => {
        return {
          contact: contacts?.find(contact => contact.uid === contactRole.contact_uid),
          role: roles?.find(role => role.uid === contactRole.role_uid),
          primary: contactRole.primary ?? false,
        };
      });
      const horse = activity.horse_uid ? horses.find(horse => horse.uid === activity.horse_uid) : undefined;
      const activityType = activityTypes.find(type => type.uid === activity.activity_type_uid);
      return {
        uid: activity.uid,
        horseUid: activity.horse_uid,
        horse: horse,
        stableUid: horse?.stable_uid ?? undefined,
        startTime,
        endTime,
        activityType: activityType,
        // For dayparts we work with the index right away. So dayPart - 1.
        dayPart: dayPartIndex,
        startEndText,
        done: activity.done_on !== undefined && activity.done_on !== null,
        doneOn: activity.done_on ? new Date(activity.done_on) : undefined,
        assignedTo: assignedTo,
        order: activity.ordering ?? -1,
        activityContactRoles: activity.activitycontactrole_set ?? [],
        type: CalendarActivityType.Activity,
        primaryColor: activityType ? activityTypeColor(activityType, true) : FALLBACK_ACTIVITY_PRIMARY_COLOR,
        secondaryColor: activityType ? activityTypeColor(activityType, false) : FALLBACK_ACTIVITY_SECONDARY_COLOR,
        isAllDayEvent: activity.all_day_event ?? false,
        executable: true,
        extraInfo: activity.extra_info,
        pregnancyCheckTerm: activity.pregnancy_check_term,
        pregnancyCheckUid: activity.pregnancycheck_uid,
      };
    });

    // Transform the DailyNotes into usable CalendarActivities the we can use in the frontend.
    const processedDailyNotes: CalendarActivity[] = dailyNotes.map(dailyNote => {
      const startTime: Date | undefined = new Date((dailyNote.start.date ?? '') + 'T00:00:00');
      const endTime: Date | undefined = addDays((dailyNote.end.date ?? '') + 'T23:59:00', -1);
      let startEndText = '';
      if (isSameDay(startTime, endTime)) {
        startEndText = formatDateIntl(startTime, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });
      } else {
        startEndText = `${formatDateIntl(startTime, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })} - ${formatDateIntl(endTime, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })}`;
      }
      return {
        uid: dailyNote.uid,
        stableUid: dailyNote.stable_uid ?? undefined,
        startTime,
        endTime,
        startEndText,
        done: dailyNote.done_on !== undefined && dailyNote.done_on !== null,
        doneOn: dailyNote.done_on ? new Date(dailyNote.done_on) : undefined,
        order: dailyNote.order ?? 0,
        assignedTo: [],
        dayPart: 0,
        type: dailyNote.executable ? CalendarActivityType.Task : CalendarActivityType.Message,
        primaryColor: dailyNote.color ?? FALLBACK_ACTIVITY_PRIMARY_COLOR,
        secondaryColor: dailyNote.color ?? FALLBACK_ACTIVITY_SECONDARY_COLOR,
        title: dailyNote.title ?? undefined,
        text: dailyNote.text ?? undefined,
        isAllDayEvent: dailyNote.all_day_event ?? true,
        executable: dailyNote.executable ?? false,
      };
    });

    return processedActivities.concat(processedDailyNotes);
  }, [activities, horses, activityTypes, dayParts, formatTime, formatDateIntl, contacts, roles, dailyNotes]);

  const setSelectedActivityObject = (activity: CalendarActivity, state: SelectedActivityState, groupByUid?: string) => {
    setSelectedActivity({ activity, selectedActivityState: state, groupByUid });
  };

  const clearSelectedActivity = () => {
    setSelectedActivity(undefined);
  };

  const requestBluePrint = (bluePrint?: BluePrint) => {
    setBluePrint(bluePrint);
  };

  const requestReshaping = (reshaping?: Reshaping) => {
    if (selectedActivity && reshaping) {
      setSelectedActivity(undefined);
    }
    setReshaping(reshaping);
  };

  useEffect(() => {
    if (dayParts) {
      if (timeScale === TimeScale.DayParts && dayParts.length <= 1) {
        setTimeScale(TimeScale.FullDay);
      }
    }
  }, [dayParts, timeScale]);

  return (
    <PlanningContext.Provider
      value={{
        viewType,
        timeScale,
        groupBy,
        apiPromises,
        activities,
        dailyNotes,
        dayParts,
        horses,
        contacts,
        activityTypes,
        groups,
        stables,
        roles,
        setLastUsedActivityTypeUid,
        historicOffset,
        setViewType,
        setTimeScale,
        setGroupBy,
        setApiPromises,
        setActivities,
        setDailyNotes,
        setDayParts,
        setHorses,
        setContacts,
        setActivityTypes,
        setGroups,
        setStables,
        setRoles,
        lastUsedActivityTypeUid,
        setHistoricOffset,
        processedActivities,
        bluePrint,
        requestBluePrint,
        reshaping,
        requestReshaping,
        selectedActivity,
        setSelectedActivity: setSelectedActivityObject,
        clearSelectedActivity,
        dragActivity,
        setDragActivity,
        selectedGroupBy,
        setSelectedGroupBy,
        modalError,
        setModalError,
        workingHours,
        setHideNonWorkingHours,
        hideNonWorkingHours,
      }}
    >
      {children}
    </PlanningContext.Provider>
  );
}
