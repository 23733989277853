import { CaretRight, FirstAidKit, Plus } from '@phosphor-icons/react';
import { AppliedListFilter, FilterOption, ListFilterType } from 'components/Common/ListFilter';
import FilterWrapper from 'components/Common/ListFilter/FilterWrapper';
import useListFilter from 'components/Common/ListFilter/useListFilter';
import HorseSelectButton from 'components/Horses/HorseSelectButton';
import { useOrganization } from 'context/OrganizationContext';
import { Horse, PregnancyCheck, PregnancycheckService } from 'openapi';
import { SexEnum } from 'openapi/models/SexEnum';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  defaultApiPageSize,
  table,
  tableHiddenColumnMd,
  tableHiddenHeaderSm,
  tableTbody,
  tableTbodyTr,
  tableTheadTd,
  tableTheadTdSticky,
} from 'ui/Const';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import { PageAction } from 'context/PageContext';
import { ButtonVariant } from 'ui/Button';
import Page from 'ui/Layout/Page';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'context/AccountContext';
import { Tile } from 'ui/Layout/Tile';
import { ApiPromises } from 'utilities/ApiPromises';
import { organizationHorses } from 'utilities/ApiRequests';
import { pregnancyCheckTermEnumToString, softnessEnumToString, uterusEnumToString } from 'utilities/Horse';
import { SavePregnancyCheck } from 'components/Breeding/SavePregnancyCheck';

export default function PregnancyCheckPage(): JSX.Element {
  const { t } = useTranslation();
  const [horses, setHorses] = useState<Horse[]>();
  const [addPregnancyCheckModalOpen, setAddPregnancyCheckModalOpen] = useState<boolean>(false);
  const [pregnancyChecks, setPregnancyChecks] = useState<PregnancyCheck[]>();
  const { selectedOrganization, generateCacheKey } = useOrganization();
  const navigate = useNavigate();
  const { formatDate } = useAccount();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  // Filter all horses by type Stallion.
  const mares = useMemo(() => {
    if (!horses) return [];
    return horses.filter(horse => horse.sex === SexEnum._2);
  }, [horses]);

  // A list of types we can filter by.
  const filterTypes = useMemo((): ListFilterType[] | undefined => {
    if (!mares) {
      return undefined;
    }

    // A list of available mares to choose from
    const mareFilterOps: FilterOption[] = mares.map(mare => {
      return { id: mare.uid, name: mare.name };
    });

    const mareFilter: ListFilterType = {
      id: 'mare',
      name: t('mare', 'Mare'),
      options: mareFilterOps, // We don't provide a list of stallions here, we hav a special dropdown for this.
    };

    return [mareFilter];
  }, [t, mares]);

  const { filters, setFilters } = useListFilter(filterTypes ?? []);

  // Get the selected mare based on the filter (url search query).
  const selectedMareUid = useMemo((): string | undefined => {
    const foundFilter = filters.find(filter => filter.type.id === 'mare');
    if (!foundFilter) {
      return undefined;
    }
    if (foundFilter.options.length !== 1) {
      return undefined;
    }

    return foundFilter.options[0].id;
  }, [filters]);

  // Get the selected mare based on the filter (url search query).
  const selectedMare = useMemo((): Horse | undefined => {
    if (!selectedMareUid) return undefined;
    return mares.find(mare => mare.uid === selectedMareUid);
  }, [mares, selectedMareUid]);

  // Select a stallion
  const selectMare = useCallback(
    (horse?: Horse) => {
      if (!filterTypes) {
        return;
      }
      const foundFilterType = filterTypes.find(f => f.id === 'mare');
      if (!foundFilterType) {
        // should never happen.
        console.error('Filter type not found');
        return;
      }

      // The selection is cleared.
      if (!horse) {
        setFilters([]);
        return;
      }

      const stallionFilter: AppliedListFilter = {
        type: foundFilterType,
        options: [{ id: horse.uid, name: horse.name }],
      };
      setFilters([stallionFilter]);
    },
    [filterTypes, setFilters],
  );

  const pageActions = useMemo((): PageAction[] => {
    return [
      {
        text: t('new-pregnancy-check', 'New pregnancy check'),
        isMobileAddAction: true,
        icon: <Plus />,
        buttonVariant: ButtonVariant.Primary,
        onClick: () => setAddPregnancyCheckModalOpen(true),
      },
    ];
  }, [t]);

  const formatMare = (pregnancyCheck: PregnancyCheck): string => {
    const found = (horses ?? []).find(mare => mare.uid === pregnancyCheck.mare_uid);
    if (!found) {
      return '-';
    }
    return found.name;
  };

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();

    if (!selectedOrganization) {
      return promises;
    }
    promises.setPaginated<PregnancyCheck>(
      'pregnancy-checks',
      apiPageNumber =>
        PregnancycheckService.pregnancycheckList({
          mareOrganisationUid: selectedOrganization.uid,
          page: apiPageNumber,
          pageSize: defaultApiPageSize,
          mareUid: selectedMareUid,
          o: '-date,id',
        }),
      setPregnancyChecks,
      selectedMareUid === undefined, // Only count as empty when we don't filter.
    );
    promises.appendListObj<Horse>('horses', setHorses, organizationHorses(selectedOrganization.uid, generateCacheKey));
    setApiPromises(promises);
    return promises;
  }, [selectedOrganization, generateCacheKey, selectedMareUid]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization, selectedMareUid]); //eslint-disable-line

  return (
    <>
      <Page title={t('pregnancy-checks', 'Pregnancy checks')} loading={apiPromises} actions={pageActions}>
        <PullScrollWrapper apiPromises={apiPromises}>
          <Tile noBoxOnMobile={true} overflowContent={true}>
            {filterTypes && mares && (
              <FilterWrapper>
                <HorseSelectButton selectedHorse={selectedMare} horses={mares} horseSelected={selectMare} />
              </FilterWrapper>
            )}
            <table className={table}>
              <thead>
                <tr className={tableHiddenHeaderSm}>
                  <td className={classNames('w-10', tableTheadTdSticky)} />
                  <td className={tableTheadTdSticky}>{t('date', 'Date')}</td>
                  {!selectedMareUid && <td className={tableTheadTdSticky}>{t('mare', 'Mare')}</td>}
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('pregnancy-check-term-short', 'Check term')}</td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('uterus', 'Uterus')}</td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('cervix', 'Cervix')}</td>
                  <td className='w-10 md:hidden' />
                </tr>
              </thead>
              <tbody className={tableTbody}>
                {(pregnancyChecks ?? []).map(pregnancyCheck => {
                  return (
                    <tr className={tableTbodyTr} key={pregnancyCheck.uid} onClick={() => navigate(pregnancyCheck.uid)}>
                      <td className='text-center w-10'>
                        <FirstAidKit size={24} weight='light' className='inline' />
                      </td>
                      <td>{pregnancyCheck.date && formatDate(new Date(Date.parse(pregnancyCheck.date)))}</td>

                      {!selectedMareUid && <td>{formatMare(pregnancyCheck)}</td>}
                      <td className={tableHiddenColumnMd}>
                        {pregnancyCheck.pregnancy_check_term ? pregnancyCheckTermEnumToString(pregnancyCheck.pregnancy_check_term) : '-'}
                      </td>
                      <td className={tableHiddenColumnMd}>{uterusEnumToString(pregnancyCheck.uterus)}</td>
                      <td className={tableHiddenColumnMd}>{softnessEnumToString(pregnancyCheck.cervix)}</td>
                      <td className={classNames('md:hidden')}>
                        <CaretRight size={22} weight='light' className='inline' />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Tile>
        </PullScrollWrapper>
      </Page>
      <SavePregnancyCheck
        mares={mares}
        selectedMare={selectedMare}
        open={addPregnancyCheckModalOpen}
        onRequestClose={(reload: boolean) => {
          if (reload) {
            loadApiData();
          }
          setAddPregnancyCheckModalOpen(false);
        }}
      />
    </>
  );
}
