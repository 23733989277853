import { CancelablePromise, Contact, InvoiceDetail, InvoicesService, NestedInvoiceItem } from 'openapi';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOrganization } from '../../context/OrganizationContext';
import { ButtonVariant } from '../../ui/Button';
import { DateInput, SelectInput, TextInput } from '../../ui/Inputs';
import { PageModal } from '../../ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from '../../ui/Modals/PageModal';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import useFormError from 'api/hooks/useFormError';
import ApiErrorParser from 'api/ApiErrorParser';
import { transformEmptyToUndefined } from 'utilities/zod';
import { ErrorSection } from 'ui/Error';
import ContactInputSelect from 'components/Contacts/ContactInputSelect';
import { schemas } from 'openapi/zod-schemas';
import { formatDate, today } from 'utilities/date.utilities';
import { differenceInDays, set, addDays } from 'date-fns';
import { Alert } from 'ui/Alert';
import { Severity } from 'utilities/severity';
import { getCurrencyOptionList } from 'utilities/Currencies';

interface Props {
  contact?: Contact;
  invoice?: InvoiceDetail;
  open: boolean;

  // The invoice is the created or updated invoice
  onClose: (reload: boolean, invoice?: InvoiceDetail) => void;
}

interface InvoiceForm {
  customer_uid: string; // contact uid
  date: string;
  due_date: string;
  invoice_no: string | undefined;
  currency: string;
}

const schema = schemas.InvoiceDetail.pick({
  customer_uid: true,
  date: true,
  invoice_no: true,
}).extend({
  due_date: z.string(),
  currency: z.string(),
});

// get the formatted currency options
const currencyOptions = getCurrencyOptionList();

function EditInvoice({ invoice, contact, open, onClose }: Props): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganization, selectedOrganizationDetails } = useOrganization();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<InvoiceForm>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const onSubmit = async (data: InvoiceForm) => {
    if (!selectedOrganization) {
      console.error('Cannot load invoice without a selected organization');
      return;
    }

    // As the due date is a string in the format YYYY/MM/DD,
    // we need to convert it to a date and set the current hours, minutes, and seconds to 0
    // we are also doing this for the current data so we are calculate the exact same date but only have a difference in days
    // As the differenceInDays() return the difference, we should add 1 to the result to get the correct due interval
    const startDate = set(new Date(), { hours: 0, minutes: 0, seconds: 0 });
    const expDate = set(data.due_date, { hours: 0, minutes: 0, seconds: 0 });
    const firstDueInterval = differenceInDays(expDate, startDate) + 1;

    // construct the request body
    const requestBody = {
      customer_uid: data.customer_uid,
      date: data.date,
      first_due_interval: firstDueInterval,
      invoice_no: data.invoice_no,
    };

    let updatedItems: NestedInvoiceItem[] | undefined = undefined;

    if (data.currency !== invoice?.currency) {
      updatedItems = invoice?.items?.map(item => ({ ...item, uid: undefined, unit_price_currency: data.currency })) ?? [];
    }

    console.log(updatedItems);

    let promise: CancelablePromise<InvoiceDetail>;

    if (invoice && invoice.uid) {
      // Edit current invoice
      promise = InvoicesService.invoicesPartialUpdate({
        organisationUid: selectedOrganization.uid ?? '',
        uid: invoice.uid,
        requestBody: { ...requestBody, items: updatedItems },
      });
    } else {
      // Create a new invoice
      promise = InvoicesService.invoicesCreate({
        organisationUid: selectedOrganization.uid ?? '',
        requestBody: requestBody as InvoiceDetail,
      });
    }

    try {
      const result = await promise;
      onClose(true, result);
    } catch (e) {
      setApiError(new ApiErrorParser<InvoiceDetail>(e));
    }
  };

  /**
   * Update the form values when the invoice
   */
  useEffect(() => {
    setValue('invoice_no', invoice?.invoice_no ?? '');
    setValue('date', invoice?.date ?? today());
    setValue('currency', invoice?.currency ?? selectedOrganizationDetails?.currency ?? '');
    // set the due date based on the first due interval from either the invoice or the organization
    // if that all failes, set the due date to 14 days from today
    setValue(
      'due_date',
      formatDate(addDays(new Date(), invoice?.first_due_interval ?? selectedOrganizationDetails?.invoice_first_due_interval ?? 14)),
    );
  }, [invoice, selectedOrganizationDetails?.currency, selectedOrganizationDetails?.invoice_first_due_interval, setValue]);

  useEffect(() => {
    if (contact) {
      setValue('customer_uid', contact.uid);
    }
  }, [contact, setValue]);

  return (
    <PageModal
      open={open}
      parentElement='form'
      parentProps={{ id: 'editInvoice', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      onClosed={() => {
        setApiError(undefined);
      }}
    >
      <PageModalTitle
        title={t('invoice-details', 'Invoice details')}
        onClose={() => {
          onClose(false);
        }}
      />
      <PageModalContent>
        <ErrorSection className='mb-4' errors={nonFieldErrors} />
        {invoice?.draft === false && (
          <Alert
            severity={Severity.Info}
            message={t('invoice-edit-finialized-warning', 'Because this invoice is finialized, you can only change the contact.')}
          />
        )}
        <div className='flex flex-col gap-4 mt-4'>
          <ContactInputSelect
            name='customer_uid'
            control={control}
            contacts={null}
            onCreated={contact => setValue('customer_uid', contact.uid)}
            required={true}
            error={fieldError('customer_uid')}
            label={t('contact-person', 'Contact')}
          />

          <DateInput
            control={control}
            error={fieldError('date')}
            required={true}
            label={t('invoice-date.label', 'Invoice date')}
            name='date'
            disabled={invoice?.draft === false}
          />

          <DateInput
            control={control}
            error={fieldError('due_date')}
            required={true}
            label={t('due-date', 'Due date')}
            name='due_date'
            disabled={invoice?.draft === false}
          />

          <TextInput
            required={false}
            label={t('invoice-number.label', 'Invoice number')}
            hint={t('invoice-number.hint', 'Leave empty to auto generate an invoice number.')}
            error={fieldError('invoice_no')}
            disabled={invoice?.draft === false}
            {...register('invoice_no', { setValueAs: transformEmptyToUndefined() })}
          />

          <SelectInput
            error={fieldError('currency')}
            required={true}
            options={currencyOptions}
            nullable={true}
            nullableValue=''
            label={t('currency', 'Currency')}
            {...register('currency')}
          />
        </div>
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            variant: ButtonVariant.Primary,
            text: t('save', 'Save'),
            type: 'submit',
            formId: 'editInvoice',
          },
        ]}
      />
    </PageModal>
  );
}
export default EditInvoice;
