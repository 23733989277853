import { CaretRight, Chat, Plus, ShoppingCart } from '@phosphor-icons/react';
import SaveSemenOrder from 'components/Breeding/SaveSemenOrder';
import { ListFilterType } from 'components/Common/ListFilter';
import { useOrganization } from 'context/OrganizationContext';
import { PageAction } from 'context/PageContext';
import {
  CategoriesService,
  Category,
  Contact,
  ContactsService,
  Horse,
  OrdersService,
  Product,
  ProductsService,
  SupplierOrder,
} from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import {
  table,
  tableHiddenColumnMd,
  tableHiddenHeaderSm,
  tableTbody,
  tableTbodyTr,
  tableTheadTd,
  defaultApiPageSize,
  tableTheadTdSticky,
} from 'ui/Const';
import Page from 'ui/Layout/Page';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import { useNavigate } from 'react-router-dom';
import FilterWrapper from 'components/Common/ListFilter/FilterWrapper';
import ListFilterButton from 'components/Common/ListFilter/ListFilterButton';
import SearchContactOrOther from 'components/Common/SearchContactOrOther';
import { contactName } from 'utilities/Contact';
import OrderListPaymentStatus from './OrderListPaymentStatus';
import { deliveryAddress, deliveryName, shippingServiceType } from 'components/Breeding/Helpers';
import Badge from 'ui/Badge';
import { BadgeSize } from 'ui/Badge/Badge';
import { Tile } from 'ui/Layout/Tile';
import { ApiPromises } from 'utilities/ApiPromises';
import { activeOrganizationHorses } from 'utilities/ApiRequests';
import Spinner, { SpinnerSize } from 'ui/Loading/Spinner';
import useApiPromises from 'api/hooks/useApiPromises';
import { useAccount } from 'context/AccountContext';
import classNames from 'classnames';
import useListFilter from 'components/Common/ListFilter/useListFilter';
import { set, subDays } from 'date-fns';

export default function OrderListPage(): JSX.Element {
  const { t } = useTranslation();
  const [horses, setHorses] = useState<Horse[]>();
  const [categories, setCategories] = useState<Category[]>();
  const { selectedOrganization, generateCacheKey: getCacheId } = useOrganization();
  const [contacts, setContacts] = useState<Contact[]>();
  const [addSemenOrderModalOpen, setAddSemenOrderModalOpen] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>();
  const [orders, setOrders] = useState<SupplierOrder[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const { loading: loadingApiPromises } = useApiPromises({ apiPromises });
  const navigate = useNavigate();
  const { formatDate } = useAccount();

  // Search results.
  const [findContactId, setFindContactId] = useState<string | undefined>();
  const [findOrderNo, setFindOrderNo] = useState<string | undefined>();

  // A list of types we can filter by.
  const filterTypes = useMemo((): ListFilterType[] => {
    const pickedFilter: ListFilterType = {
      id: 'picked',
      name: t('is-picked', 'Is picked'),
      type: 'radio',
      options: [
        { id: 'yes', name: t('yes', 'Yes') },
        { id: 'no', name: t('no', 'No') },
      ],
    };

    const dateFilter: ListFilterType = {
      id: 'order-created',
      name: t('order-created', 'Order created'),
      type: 'radio',
      options: [
        { id: 'today', name: t('today', 'Today') },
        { id: 'yesterday', name: t('yesterday', 'Yesterday') },
        { id: 'last-7-days', name: t('last-7-days', 'Last 7 days') },
      ],
    };

    return [pickedFilter, dateFilter];
  }, [t]);

  const { filters } = useListFilter(filterTypes);

  const pageActions = useMemo((): PageAction[] => {
    return [
      {
        text: t('add-semen-order', 'New semen order'),
        icon: <Plus />,
        isMobileAddAction: true,
        buttonVariant: ButtonVariant.Primary,
        onClick: () => {
          setAddSemenOrderModalOpen(true);
        },
      },
    ];
  }, [t]);

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();
    if (!selectedOrganization) {
      return promises;
    }

    // apply the date filter
    const dateFilter = filters.find(f => f.type.id === 'order-created');
    let createdOnAfter: Date | undefined = undefined;
    let createdOnBefore: Date | undefined = undefined;
    if (dateFilter) {
      // default to today
      createdOnAfter = set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
      if (dateFilter?.options[0].id === 'yesterday') {
        // set a date range from yesterday to today
        createdOnAfter = subDays(createdOnAfter, 1);
        createdOnBefore = set(createdOnAfter, { hours: 23, minutes: 59 });
      } else if (dateFilter?.options[0].id === 'last-7-days') {
        // go back 7 days
        createdOnAfter = subDays(createdOnAfter, 7);
      }
    }

    const pickedFilter = filters.find(f => f.type.id === 'picked');
    const isPicked = pickedFilter?.options[0].id === 'yes' ? true : pickedFilter?.options[0].id === 'no' ? false : undefined;

    promises.setPaginated<SupplierOrder>(
      'orders',
      apiPageNumber => {
        return OrdersService.ordersSuppliedList({
          supplierUid: selectedOrganization.uid,
          uid: findOrderNo,
          page: apiPageNumber,
          pageSize: defaultApiPageSize,
          customerUid: findContactId,
          createdOnAfter: createdOnAfter?.toISOString(),
          createdOnBefore: createdOnBefore?.toISOString(),
          isPicked,
          o: '-created_on,-id',
        });
      },
      setOrders,
      !findOrderNo && !findContactId && createdOnAfter === undefined && isPicked === undefined,
    );

    // TODO for performance we should only load the promises below once we visit the page
    // See https://gitlab.qubis.nl/equinem/equiapp/-/issues/251

    promises.appendListObj<Horse>('horses', setHorses, activeOrganizationHorses(selectedOrganization.uid, getCacheId));

    // Load all contacts, including the removed ones.
    promises.appendList<Contact>(
      'contacts',
      () =>
        ContactsService.contactsList({
          organisationUid: selectedOrganization.uid,
        }),
      setContacts,
      getCacheId('contacts'),
    );

    promises.appendList<Product>(
      'products',
      () =>
        ProductsService.productsList({
          organisationUid: selectedOrganization.uid,
        }),
      setProducts,
    );

    promises.appendList<Category>(
      'categories',
      () =>
        CategoriesService.categoriesList({
          organisationUid: selectedOrganization.uid,
        }),
      setCategories,
    );

    setApiPromises(promises);
    return promises;
  }, [selectedOrganization, filters, findOrderNo, findContactId, getCacheId]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization, findContactId, findOrderNo, filters]); //eslint-disable-line

  return (
    <>
      <Page title={t('semen-orders', 'Semen orders')} actions={pageActions} loading={apiPromises}>
        <PullScrollWrapper apiPromises={apiPromises}>
          <Tile noBoxOnMobile={true}>
            {filterTypes && contacts && (
              <FilterWrapper listFilterTypes={filterTypes}>
                <ListFilterButton listFilterTypes={filterTypes} />
                <SearchContactOrOther
                  onClear={() => {
                    setFindContactId(undefined);
                    setFindOrderNo(undefined);
                  }}
                  onContactSearch={contact => {
                    setFindContactId(contact.uid);
                    setFindOrderNo(undefined);
                  }}
                  onOtherSearch={search => {
                    setFindOrderNo(search);
                    setFindContactId(undefined);
                  }}
                  contacts={contacts}
                  placeholderText={t('search-by-name-contact-orderno', 'Search by contact or order-number...')}
                />
                {loadingApiPromises && <Spinner size={SpinnerSize.Small} />}
              </FilterWrapper>
            )}
            <table className={table}>
              <thead>
                <tr className={tableHiddenHeaderSm}>
                  <td className={classNames('w-10', tableTheadTdSticky, tableHiddenColumnMd)} />
                  <td className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnMd)}>{t('order-date', 'Date')}</td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnMd)}>{t('order-no', 'Order No.')}</td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnMd)}>{t('customer', 'Customer')}</td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnMd)}>
                    {t('order-delivery-address', 'Delivery address')}
                  </td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnMd)}>
                    {t('requested-shipping-shipping-date', 'Shipping date (requested)')}
                  </td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnMd)}>{t('payment', 'Payment')}</td>
                  <td className='w-10 md:hidden' />
                </tr>
              </thead>
              <tbody className={tableTbody}>
                {(orders ?? []).map(order => {
                  const customer = contacts?.find(cont => cont.uid === order.customer_uid);
                  return (
                    <tr className={tableTbodyTr} key={order.uid} onClick={() => navigate(order.uid)}>
                      <td className={classNames('text-center w-10', tableHiddenColumnMd)}>
                        <ShoppingCart size={24} weight='light' className='inline' />
                      </td>
                      <td className={tableHiddenColumnMd}>{order.created_on && formatDate(new Date(Date.parse(order.created_on)))}</td>
                      <td className={tableHiddenColumnMd}>{order.uid}</td>
                      <td className={tableHiddenColumnMd}>
                        <div className='flex gap-x-1 items-center'>
                          <p>{customer ? contactName(customer) : t('unknown', 'Unknown')}</p>
                          {order.customer_note && (
                            <span title={t('customer-note-available', 'There is a customer note')} className='-translate-y-2'>
                              <Chat />
                            </span>
                          )}
                        </div>
                      </td>
                      <td className={tableHiddenColumnMd}>
                        {deliveryName(order)} - {deliveryAddress(order)}
                      </td>
                      <td className={tableHiddenColumnMd}>
                        <span className='mr-1'>{order.shipping_date && formatDate(new Date(Date.parse(order.shipping_date)))}</span>
                        <Badge size={BadgeSize.Normal}>{shippingServiceType(t, products ?? [], categories ?? [], order)}</Badge>
                      </td>
                      <td className={tableHiddenColumnMd}>
                        <OrderListPaymentStatus order={order} />
                      </td>
                      <td className={classNames('w-10 text-center md:hidden', tableHiddenColumnMd)}>
                        <CaretRight size={22} weight='light' className='inline' />
                      </td>

                      <td className='md:hidden'>
                        <div className='flex items-center px-2 gap-2'>
                          <div className='grow flex flex-col'>
                            <span className='line-clamp-1'>{customer ? contactName(customer) : t('unknown', 'Unknown')}</span>
                            <span className='text-xs'>
                              {order.created_on && formatDate(new Date(Date.parse(order.created_on)))} - {'#'}
                              {order.uid}
                            </span>
                          </div>

                          <div className='flex flex-col items-end gap-0.5'>
                            <OrderListPaymentStatus order={order} />
                          </div>
                          <CaretRight size={22} weight='light' className='inline' />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Tile>
        </PullScrollWrapper>
      </Page>
      {products && horses && contacts && categories && (
        <SaveSemenOrder
          categories={categories}
          products={products}
          horses={horses}
          contacts={contacts}
          open={addSemenOrderModalOpen}
          onContactUpdate={() => {
            const promises = new ApiPromises();
            // Load all contacts, including the removed ones.
            promises.appendList<Contact>(
              'contacts',
              () =>
                ContactsService.contactsList({
                  organisationUid: selectedOrganization?.uid ?? '',
                }),
              setContacts,
              getCacheId('contacts'),
            );
            setApiPromises(promises);
          }}
          onRequestClose={reload => {
            if (reload) {
              loadApiData();
            }
            setAddSemenOrderModalOpen(false);
          }}
        />
      )}
    </>
  );
}
