import { Popover, Transition } from '@headlessui/react';
import { CaretDown, Check, IconContext } from '@phosphor-icons/react';
import React, { Fragment, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonInternal, ButtonSize, ButtonVariant } from 'ui/Button';
import { ActionModal } from 'ui/Modals';
import { TextInput } from 'ui/Inputs';
import { table, tableTbody, tableTbodyTr } from 'ui/Const';
import classNames from 'classnames';

interface Props<T extends object> {
  options: T[];
  onSelected: (option?: T) => void;
  onFilter: (current: T, searchValue: string) => boolean;
  display: (option: T) => string;
  selectedOption?: T;
  title?: string;
  buttonText?: string;
  // this is the field that we are used for the selected value
  // and for the key param
  idField: keyof T;
  icon: ReactNode;
  loading?: boolean;
  // this flag make sure that the text is not too long on mobile
  reduceSpaceOnMobile?: boolean;
}

/**
 * Generates a filter button that shows a selection of the given list
 */
export default function FilterSelectButton<T extends object>({
  options,
  onSelected,
  display,
  selectedOption,
  title: givenTitle,
  buttonText: givenButtonText,
  idField,
  onFilter,
  icon,
  loading,
  reduceSpaceOnMobile,
}: Props<T>): JSX.Element {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const title = useMemo(() => {
    return givenTitle ?? t('select-option', 'Select an options');
  }, [givenTitle, t]);

  const buttonText = useMemo(() => {
    const text = givenButtonText ?? t('choose-options', 'Choose an option');
    return `<${text}>`;
  }, [givenButtonText, t]);

  return (
    <>
      <div className='block md:hidden'>
        <Button
          loading={loading}
          disabled={loading}
          size={ButtonSize.Normal}
          onClick={() => setIsOpen(true)}
          variant={ButtonVariant.Default}
          icon={icon}
        >
          {selectedOption && (
            <span
              className={classNames({
                'hidden md:inline': reduceSpaceOnMobile,
              })}
            >
              {display(selectedOption)}
            </span>
          )}
          {!selectedOption && (
            <span
              className={classNames('text-neutral-500', {
                'hidden md:inline': reduceSpaceOnMobile,
              })}
            >
              {buttonText}
            </span>
          )}
          <CaretDown className='ml-2 inline' />
        </Button>
        <ActionModal actions={[]} title={title} open={isOpen} onClose={() => setIsOpen(false)}>
          <SelectList<T>
            options={options}
            display={display}
            idField={idField}
            onFilter={onFilter}
            selectedOption={selectedOption}
            onSelected={item => {
              setIsOpen(false);
              onSelected(item);
            }}
            icon={icon}
          />
        </ActionModal>
      </div>

      <div className='hidden md:block'>
        <Popover>
          {({ close }) => (
            <>
              <Popover.Button disabled={loading} className={'!outline-none'}>
                <ButtonInternal
                  loading={loading}
                  disabled={loading}
                  compress={true}
                  size={ButtonSize.Normal}
                  variant={ButtonVariant.Default}
                  icon={icon}
                >
                  <div className='flex items-center'>
                    {selectedOption && <span>{display(selectedOption)}</span>}
                    {!selectedOption && <span className='text-neutral-500'>{buttonText}</span>}
                    <CaretDown className='ml-2 inline' />
                  </div>
                </ButtonInternal>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter='transition-opacity ease-out duration-200'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='transition-opacity ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Popover.Panel className='absolute z-50 bg-white border rounded-lg mt-1 shadow-lg'>
                  <div className='h-96 w-96 overflow-scroll'>
                    <SelectList<T>
                      options={options}
                      display={display}
                      idField={idField}
                      onFilter={onFilter}
                      selectedOption={selectedOption}
                      onSelected={item => {
                        onSelected(item);
                        close();
                      }}
                      icon={icon}
                    />
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </>
  );
}

interface SelectListProps<T extends object> {
  options: T[];
  onSelected: (option?: T) => void;
  selectedOption?: T;
  onFilter: (current: T, searchValue: string) => boolean;
  display: (option: T) => string;
  // this is the field that we are used for the selected value
  // and for the key param
  idField: keyof T;
  icon: ReactNode;
}

function SelectList<T extends object>({
  options,
  onSelected,
  selectedOption,
  onFilter,
  idField,
  display,
  icon,
}: SelectListProps<T>): JSX.Element {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>('');

  const searchResults = useMemo(() => {
    return options.filter(option => onFilter(option, searchValue));
  }, [onFilter, options, searchValue]);

  return (
    <div>
      <div className='hidden md:flex bg-neutral-50 p-4 rounded-b-lg gap-2'>
        <div className='grow'>
          <TextInput autoFocus={true} onChange={event => setSearchValue(event.target.value)} placeholder={t('search', 'Search') + '...'} />
        </div>
        {selectedOption && <Button onClick={() => onSelected(undefined)}>{t('clear', 'Clear')}</Button>}
      </div>
      <div className='block md:hidden text-right'>
        {selectedOption && (
          <Button onClick={() => onSelected(undefined)} size={ButtonSize.Small}>
            {t('clear', 'Clear')}
          </Button>
        )}
      </div>

      <table className={table}>
        <tbody className={tableTbody}>
          {searchResults.map(item => {
            const isSelected = selectedOption && item[idField] === selectedOption[idField];
            return (
              <tr className={tableTbodyTr} key={String(item[idField])} onClick={() => onSelected(item)}>
                <td className='text-center w-10'>
                  <IconContext.Provider
                    value={{
                      size: 22,
                      weight: 'light',
                      className: 'inline',
                    }}
                  >
                    {icon}
                  </IconContext.Provider>
                </td>
                <td>
                  <div
                    className={classNames('flex gap-x-2 items-center', {
                      'font-medium': isSelected,
                    })}
                  >
                    {display(item)} {isSelected && <Check />}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
