import { useOrganization } from 'context/OrganizationContext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ContactDetail, ContactsService, ModulePermissionsEnum } from 'openapi';
import { useNavigate, useParams } from 'react-router-dom';
import useModal from 'ui/Modals/UseModal';
import { AppRoutes } from 'AppRoutes';
import ContactDetails from 'components/Contacts/ContactDetails';
import Page, { PageMaxWidth } from 'ui/Layout/Page';
import { getFullName } from 'utilities/string.utility';
import { PageAction } from 'context/PageContext';
import UpdateAddressModal from 'components/Contacts/Modals/UpdateAddressModal';
import UpdateGeneralModal from 'components/Contacts/Modals/UpdateGeneralModal';
import UpdateBusinessModal from 'components/Contacts/Modals/UpdateBusinessModal';
import { Trash, UserPlus } from '@phosphor-icons/react';
import { ButtonVariant } from 'ui/Button';
import DeleteContactModal from 'components/Contacts/Modals/DeleteContactModal';
import { ContactType, getContactType } from 'utilities/Contact';
import { useTranslation } from 'react-i18next';
import InviteAccountForExistingContactModal from 'components/Contacts/Modals/InviteAccountForExistingContactModal';
import { ApiPromises } from 'utilities/ApiPromises';
import usePermissions from 'hooks/UsePermissions';

export default function ContactDetailsPage(): JSX.Element {
  const [contact, setContact] = useState<ContactDetail>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  const { selectedOrganizationUid } = useOrganization();
  const { uid } = useParams();
  const breadCrumbs = useMemo(() => [AppRoutes.ContactList], []);
  const { modalIsVisible: addressModalIsVisible, closeModal: closeAddressModal, showModal: showAddressModal } = useModal();
  const { modalIsVisible: generalModalIsVisible, closeModal: closeGeneralModal, showModal: showGeneralModal } = useModal();
  const { modalIsVisible: businessModalIsVisible, closeModal: closeBusinessModal, showModal: showBusinessModal } = useModal();
  const { modalIsVisible: deleteModalIsVisible, closeModal: closeDeleteModal, showModal: showDeleteModal } = useModal();
  const {
    modalIsVisible: createAccountModalIsVisible,
    closeModal: closeCreateAccountModal,
    showModal: showCreateAccountModal,
  } = useModal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hasPermission, isAdmin } = usePermissions();

  // set the page title based on the contact info
  const pageTitle = useMemo((): string => {
    if (!contact) return '';

    // personal contact
    if (contact && contact.first_name && contact.last_name) {
      return getFullName(contact);
    }

    // business contact
    return contact.business_name ?? 'Details';
  }, [contact]);

  /**
   * Render the page actions
   */
  const pageActions = useMemo((): PageAction[] | undefined => {
    let actions: PageAction[] = [];

    if (hasPermission(ModulePermissionsEnum.MANAGE_CONTACTS)) {
      actions.push({
        icon: <Trash />,
        text: 'Delete',
        buttonVariant: ButtonVariant.Danger,
        onClick: showDeleteModal,
      });
    }

    if (isAdmin) {
      // add the create account action if the contact does not have an account
      if (contact && contact.user_uid === null && contact.invitation === null && ContactType.Contact === getContactType(contact)) {
        actions = [
          {
            icon: <UserPlus />,
            text: 'Create account',
            buttonVariant: ButtonVariant.Primary,
            onClick: showCreateAccountModal,
          },
          ...actions,
        ];
      }
    }
    return actions;
  }, [contact, hasPermission, isAdmin, showCreateAccountModal, showDeleteModal]);

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();
    if (!selectedOrganizationUid || !uid) {
      return promises;
    }

    promises.appendSingle<ContactDetail>(
      'contact',
      () => ContactsService.contactsRetrieve({ organisationUid: selectedOrganizationUid, uid }),
      setContact,
    );

    setApiPromises(promises);
    return promises;
  }, [selectedOrganizationUid, uid]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid]); //eslint-disable-line

  return (
    <Page title={pageTitle} actions={pageActions} breadCrumbs={breadCrumbs} loading={apiPromises} maxWidth={PageMaxWidth.Tile}>
      {contact && (
        <>
          <ContactDetails
            contact={contact}
            onRequestUpdateAddress={showAddressModal}
            onRequestUpdateBusiness={showBusinessModal}
            onRequestUpdateGeneral={showGeneralModal}
          />
          <UpdateAddressModal
            isVisible={addressModalIsVisible}
            onRequestCloseModal={closeAddressModal}
            contact={contact}
            onUpdated={updatedContact => setContact(updatedContact)}
          />
          <UpdateGeneralModal
            isVisible={generalModalIsVisible}
            onRequestCloseModal={closeGeneralModal}
            contact={contact}
            onUpdated={updatedContact => setContact(updatedContact)}
          />
          <UpdateBusinessModal
            isVisible={businessModalIsVisible}
            onRequestCloseModal={closeBusinessModal}
            contact={contact}
            onUpdated={updatedContact => setContact(updatedContact)}
          />
          <DeleteContactModal
            isVisible={deleteModalIsVisible}
            onRequestClose={closeDeleteModal}
            onDeleted={() => navigate(AppRoutes.ContactList.path)}
            contact={contact}
          />
          <InviteAccountForExistingContactModal
            modalTitle={t('create-user', 'Create user')}
            isVisible={createAccountModalIsVisible}
            onRequestCloseModal={closeCreateAccountModal}
            contact={contact}
          />
        </>
      )}
    </Page>
  );
}
